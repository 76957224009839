<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Member-711a83',
}
</script>
<style scoped>
.wrapper {padding: 0 30px;}
</style>
