<template>
  <div class="cart">
    <div class="cartHeade">
      <router-link :to="historyBack" class="textSize20">
        <i class="ic-back"></i>
      </router-link>
      <div class="textSize18">장바구니</div>
      <i></i>
    </div>
    <div class="cartSelect">
      <div class="cartSelectInner spaceBetween">
        <div>
          <label for="allChecked" class="btn-bgGreen-03 textSize12 customStyle">전체선택</label>
          <input @click="checkedAll($event.target.checked)" id="allChecked" v-model="allChecked" type="checkbox" class="hide"/>
        </div>
        <div class="gbtnInit">
          <div>
            <label for="allDelete" class="btn-border textSize12 customStyle">전체삭제</label>
            <input @click="deleteAll" id="allDelete" v-model="allDelete" type="checkbox" class="hide"/>
          </div>
          <div>
            <label for="choiceDelete" class="btn-border textSize12 customStyle">선택삭제</label>
            <input @click="deleteChoice" id="choiceDelete" v-model="choiceDelete" type="checkbox" class="hide"/>
          </div>
        </div>
      </div>
      <ul>
        <li v-for="item in cartData" :key="item.cartNo" class="list">
          <div class="cartSelectInner itemInner">
            <div class="imgInner">
              <router-link
                :style="{'background': productThumb(item.imageUrl)}"
                :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                class="linkArea img"
              >
              <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
            </router-link>
            </div>
            <label @change="selectedItem(item)" :for="item.title" class="innerLabel">
              <div class="cartInfo">
                <input type="checkbox" :name="item.done" :id="item.title" v-model="item.selected" class="form-radio"/>
              </div>
              <div class="cartInfo textSize14">
                <div>
                  <p class="customStyleTitle">{{item.productName}} <span v-if="item.soldOut" class="btn-bgGreen-03 textSize12">품절</span></p>
                  <template v-if="item.options.length > 0 && item.optionType !== 'PRODUCT_ONLY'">
                    <p v-for="input in item.options" :key="input.inputLabel">{{input.optionName}} : {{input.optionValue}}</p>
                  </template>
                  <p>수량 : {{item.orderCnt}}개</p>
                  <p>가격 : {{item.buyAmt}}원</p>
                </div>
              </div>
            </label>
          </div>
          <div class="cartSelectInner optionBtnInner">
            <button @click="deleteItem(item.cartNo)" class="btn-border textSize12 customStyle">삭제</button>
            <button @click="countChange(item.cartNo)" class="btn-border textSize12 customStyle">수량 변경</button>
            <!-- <button @click="optionChange(item.id)" class="btn-border textSize12 customStyle">옵션 변경</button> -->
          </div>

          <!-- 옵션 색상, 사이즈 -->
          <!-- <div v-if="item.optionChange">
            <div class="optionItem">
              <p class="titleSmall">색상</p>
              <select class="pick">
                <option>선택하세요</option>
                <option
                    v-for="(item, idx) in colortypes"
                    :key="idx"
                    :value="item.id"
                    :disabled="item.isdisabled">
                  {{ item.name }}
                </option>
              </select>
              <i class="ic-arrow-down selectDown"></i>
            </div>

            <div class="optionItem">
              <p class="titleSmall">사이즈</p>
              <select class="pick">
                <option>선택하세요</option>
                <option
                    v-for="(item, idx) in salestypes"
                    :key="idx"
                    :value="item.id"
                    :disabled="item.isdisabled">
                  {{ item.name }}
                </option>
              </select>
              <i class="ic-arrow-down selectDown"></i>
            </div>

            <div class="btnInner">
              <button class="btn-bgGray-77 customStyle"><span>옵션변경</span></button>
              <button class="btn-bgGray-DD customStyle"><span>취소</span></button>
            </div>
          </div> -->
          <!-- 수량변경 -->
          <div v-if="item.volumeChange">
            <div class="optionItem">
              <p class="titleSmall">구매수량</p>
              <div class="quantity">
                <input v-model="item.changeOrderCnt" type="number" placeholder="구매수량을 입력해주세요." class="form-control w100" :ref="`${item.cartNo}`"/>
                <button @click="plus(item.cartNo)" class="plus customStyle" type="button" ></button>
                <button @click="minus(item.cartNo)" class="minus customStyle" type="button"></button>
              </div>
            </div>
            <div class="btnInner">
              <button @click="changeQuant(item.productNo, item.cartNo)" class="btn-bgGray-77 customStyle"><span>수량변경</span></button>
              <button @click="closeCount(item.cartNo)" class="btn-bgGray-DD customStyle"><span>취소</span></button>
            </div>
          </div>
        </li>
        <li v-if="cartData.length === 0 && !loading" class="nodata">
          <i class="ic-nodata customStyle"></i>
          <p>장바구니에 담긴 제품이 없습니다</p>
        </li>
      </ul>
    </div>
    <div class="cartGlobalBg">
      <div class="item">
        <p>총 상품금액</p>
        <p class="price">{{ productBuyAmt.standardAmt || 0}} 원</p>
      </div>
      <div class="item">
        <p>총 할인금액</p>
        <p class="price">{{ productBuyAmt.discountAmt || 0}} 원</p>
      </div>
      <div class="item">
        <p>배송비</p>
        <p class="price">{{ productBuyAmt.totalDeliveryAmt || 0}} 원</p>
      </div>
      <div class="item total">
        <p>총 합계금액</p>
        <p class="price">{{ productBuyAmt.totalAmt || 0}} 원</p>
      </div>
      <div class="btn-bgGreen-03 customStyle" @click="btnPurchase">
        <i class="ic-buy textSize18"></i>
        <span class="textSize18">구매하기</span><span class="textSize18 fontWeight">({{ productBuyAmt.totalAmt || 0}} 원)</span>
      </div>
    </div>

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import http from '@/api/shopBy';
import regExp from '@/lib/regExp.js';
import LoadingCont from "@/components/Common/Loading.vue";

// apis호출 메소드 모음.
const apis = {
  cartItems: () => http.get('/cart'),
  cartDelete: (cartNo) => http.delete(`/cart/?${cartNo}`),
  calculate: (cartNo) => http.get(`/cart/calculate?${cartNo}`),
  cartCountChange: (data) => http.put('/cart', data)
}

export default {
  name: 'Cart-7b07f9',
  components: {
    LoadingCont
  },
  computed: {
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    }
  },
  data() {
    return {
      loading: true,
      cartData: [],
      cartDataOther: [],
      checkStateNo: [],
      productBuyAmt: {},
      allChecked: true,
      allDelete: true,
      choiceDelete: true
    }
  },
  created(){
    this.init();
  },
  methods: {
    async init(){
      this.cartData = [];
      this.checkStateNo = [];
      const cartItemData = await apis.cartItems();
      const groups = cartItemData.data.deliveryGroups.find(itme => itme);
      groups?.orderProducts.map((item) => {

        item.orderProductOptions.map((sub) => {
          const _tmp = {
            changeOrderCnt: '',
            optionNo: '',
            imageUrl: '',
            partnerNo: '',
            cartNo: '',
            productNo: '',
            productName: '',
            soldOut: '',
            options: [],
            optionInputs: [],
            orderCnt: '',
            buyAmt: '',
            salePrice: '',
            standardAmt: '',
            volumeChange: false,
            selected: true
          };
          _tmp.changeOrderCnt = sub.orderCnt;
          _tmp.optionNo = sub.optionNo;
          _tmp.imageUrl = sub.imageUrl;
          _tmp.partnerNo = sub.productNo;
          _tmp.cartNo = sub.cartNo;
          _tmp.productNo = item.productNo;
          _tmp.productName = item.productName;
          _tmp.soldOut = sub.soldOut;
          _tmp.optionType = sub.optionType;

          const _tmpOptionName = sub.optionName.split("|");
          const _tmpOptionValue = sub.optionValue.split("|");
          for(let i = 0; i < _tmpOptionName.length; i++){
            _tmp.options.push({
              optionName: _tmpOptionName[i],
              optionValue: _tmpOptionValue[i]
            })
          }
          _tmp.optionNo = sub.optionNo;

          for(let i = 0; i < sub.optionInputs.length; i++){
            _tmp.options.push({
              optionName: sub.optionInputs[i].inputLabel,
              optionValue: sub.optionInputs[i].inputValue
            })
          }
          _tmp.optionInputs = sub.optionInputs;
          // console.log("options", _tmp.options);

          _tmp.orderCnt = sub.orderCnt;
          _tmp.buyAmt = regExp.comma(regExp.numberOnly(sub.price.buyAmt));
          _tmp.salePrice = sub.price.salePrice;
          _tmp.standardAmt = sub.price.standardAmt;

          this.cartData.push(_tmp);
          this.checkStateNo.push(_tmp);

        });


        //
        // item.orderProductOptions.map((sub) => {
        //   sub.optionInputs.map(() => {
        //
        //     this.cartData.push({
        //       changeOrderCnt: 0,
        //       optionNo: sub.optionNo,
        //       imageUrl: sub.imageUrl,
        //       partnerNo: sub.productNo,
        //       cartNo: sub.cartNo,
        //       productNo: item.productNo,
        //       productName: item.productName,
        //       soldOut: sub.soldOut,
        //       optionName: sub.optionName,
        //       optionValue: sub.optionValue,
        //       orderCnt: sub.orderCnt,
        //       buyAmt: regExp.comma(regExp.numberOnly(sub.price.buyAmt)),
        //       salePrice: sub.price.salePrice,
        //       standardAmt: sub.price.standardAmt,
        //       volumeChange:false,
        //       selected:false,
        //     });
        //   });
        // });
      });
      const newCartNo = this.cartData.map(item => item.cartNo);
      const strCartNo = newCartNo.join();
      await this.calculat(strCartNo);
      this.loading = false;
    },
    plus(cartNo){
      this.$refs[cartNo][0].value = Number(this.$refs[cartNo][0].value) + 1;
    },
    minus(cartNo){
      if(this.$refs[cartNo][0].value > 1){
        this.$refs[cartNo][0].value = Number(this.$refs[cartNo][0].value) - 1;
      }
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },

    async changeQuant(productNo, cartNo) {
      this.loading = true;
      const items = this.cartData.find( x => x.productNo === productNo );
      // items.volumeChange = !items.volumeChange
      const _tmp = [{
        "cartNo": 1, "orderCnt": 1, "optionInputs": [{
          "inputLabel": "label", "inputValue": "value"
        }]
      }]

      _tmp[0].cartNo = cartNo;
      _tmp[0].orderCnt = this.$refs[cartNo][0].value;
      _tmp[0].optionInputs = items.optionInputs;
      apis.cartCountChange( _tmp ).then( () => {
        this.init();
      }).catch(err => {
        alert(err.response.data.message);
        this.loading = false
      })
    },
    async calculat(strCartNo){
      const query = Object.entries({cartNo: strCartNo}).map(el => encodeURIComponent(el[0]) + '=' + encodeURIComponent(el[1])).join('&')
      const choiceCalculate = await apis.calculate(query);
      
      this.productBuyAmt = {
        buyAmt: regExp.comma(regExp.numberOnly(choiceCalculate.data.buyAmt)),
        discountAmt: regExp.comma(regExp.numberOnly(choiceCalculate.data.discountAmt)),
        totalDeliveryAmt: regExp.comma(regExp.numberOnly(choiceCalculate.data.totalDeliveryAmt)),
        standardAmt:regExp.comma(regExp.numberOnly(choiceCalculate.data.standardAmt)),
        totalAmt: regExp.comma(regExp.numberOnly(choiceCalculate.data.totalAmt))
      }
    },
    async countChange(cartNo) {
      const items = this.cartData.find(x => x.cartNo === cartNo)
      items.volumeChange = true
    },
    // optionChange(id) {
    //   const items = this.cartData.find(x => x.productNo === productNo)
    //   items.optionChange = !items.optionChange
    // },
    checkedAll(allChecked){
      for (let [idx] of this.cartData.entries()) {
        this.cartData[idx].selected = allChecked;
      }
      const cartDataOther = this.cartData.filter(x => x.selected);
      const newCartNo = cartDataOther.map(item => item.cartNo);
      const strCartNo = newCartNo.join();
      this.checkStateNo = cartDataOther;
      this.calculat(strCartNo);
    },
    selectedItem() {
      const cartDataOther = this.cartData.filter(x => x.selected);
      const newCartNo = cartDataOther.map(item => item.cartNo);
      const strCartNo = newCartNo.join();
      this.checkStateNo = cartDataOther;
      this.calculat(strCartNo);

      if(this.checkStateNo.length >= this.cartData.length) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    async deleteAll(){
      const newCartNo = this.cartData.map(item => String(item.cartNo));
      const strCartNo = newCartNo.join();
      if(!confirm('전체 상품을 삭제 하시겠습니까?')){
        return
      } else {
        // const query = Object.entries({cartNo: strCartNo}).map(el => encodeURIComponent(el[0]) + '=' + encodeURIComponent(el[1])).join('&')
        const query = `cartNo=${strCartNo}`
        await apis.cartDelete(query);
        this.cartData = [];
      }
    },
    async deleteChoice(){
      const newCartNo = this.checkStateNo.map(item => String(item.cartNo));
      const strCartNo = newCartNo.join();
      if(this.checkStateNo.length > 0){
        if(!confirm('선택하신 상품을 삭제 하시겠습니까?')){
          return
        } else {
          // const query = Object.entries({cartNo: strCartNo}).map(el => encodeURIComponent(el[0]) + '=' + encodeURIComponent(el[1])).join('&')
          const query = `cartNo=${strCartNo}`
          apis.cartDelete(query).then( () => {
            this.init();
          }).catch(err => {
            alert(err.response.data.message);
          })
        }
      }
    },
    async deleteItem(strCartNo){
      if(!confirm('해당 상품을 삭제 하시겠습니까?')){
        return
      } else {
        const query = Object.entries({cartNo: strCartNo}).map(el => encodeURIComponent(el[0]) + '=' + encodeURIComponent(el[1])).join('&')
        await apis.cartDelete(query);
        this.init();
      }
    },
    closeCount(cartNo) {
      const items = this.cartData.find(x => x.cartNo === cartNo)
      items.volumeChange = false;
    },

    btnPurchase() {

      const _data = {
        products: [],
        productCoupons: [],
        cartNos: [],
        trackingKey: "",
        channelType: ""
      }
      this.checkStateNo.map(item => {
        const _tmp = {
          productNo: item.productNo,
          optionNo: item.optionNo,
          orderCnt: item.orderCnt,
          optionInputs: [],
        }

        item.optionInputs.map(option => {
          const _option = {
            inputLabel: option.inputLabel,
            inputValue: option.inputValue
          }
          _tmp.optionInputs.push(_option)
        })

        // console.log(item);

        _data.products.push(_tmp)
        _data.cartNos.push(item.cartNo)
      });

      if(this.checkStateNo.length <= 0) {
        alert('구매할 상품을 선택해주세요.');
        return
      }
      http.post('/order-sheets', _data).then(res => {
        this.$router.push({name: 'orderForm', params: {orderSheetNo: res.data.orderSheetNo}})
      }).catch(err => {
        alert(err.response.data.message);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
  .cart {
    .flex{
      display: flex;
      span {
        height: 27px
      }
    }
    .hide {display: none;}
    .cartHeade {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      color: $gray-44;
      padding: 0 30px;
    }
    .cartSelect {
      padding: 0 30px;
      .imgInner .linkArea.img {
        display: block;
        background-size: 100% 100% !important;
      }
      .customStyleTitle {
        color: #444444;
        font-weight: 700;
        width: 180px;
      }
      .cartSelectInner {
        display: flex;
        .imgInner {width: 120px;}
        .customStyle{height: 27px;}
      }
      .innerLabel {
        display: flex;
      }
      .cartSelectInner.spaceBetween {
        justify-content:space-between;
        margin-bottom: 20px;

        .gbtnInit{
          display: flex;
          > div {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
      .cartSelectInner.itemInner {
        justify-content:flex-start;
        color: $gray-A1;
        padding: 20px 0 10px 0;
        &:first-child{
          border-top: 1px solid $gray-e5;
        }
        .cartInfo {
          .customStyle {
            color: $gray-44;
            font-weight: 700;
            width: 180px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: pre;
          }
          span {
            height: 20px;
          }
        }
      }
      .cartSelectInner.optionBtnInner {
        justify-content:flex-start;
        padding-bottom: 20px;
        button:nth-child(n+2) {
          margin-left: 10px;
        }
      }
      .cartSelectInner {
        .cartInfo{
          margin-left: 10px;
        }
      }
      .nodata {
        text-align: center;
        border-top: 1px solid $gray-e5;
        padding: 50px 0 100px 0;
        .customStyle {font-size: $font-size-64;color: $gray-e5;}
        p{margin-top: 10px;font-size: $font-size-14;}
      }
      .optionItem{
        .quantity{
          position: relative;
          .plus.customStyle{
            position: absolute;
            top: 50%;
            right: 40px;
            border: 1px solid $gray-77;
            transform: translate(-50%, -50%);
            &::before{
              width: 2px;
              height: 10px;
              background-color: $gray-77;
            }
            &::after{
              width: 10px;
              height: 2px;
              background-color: $gray-77;
            }
          }
          .minus.customStyle{
            position: absolute;
            top: 50%;
            right: 0;
            border: 1px solid $gray-77;
            transform: translate(-50%, -50%);
            &::after{
              width: 10px;
              height: 2px;
              background-color: $gray-77;
            }
          }
        }
      }
      .btnInner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .customStyle {
          font-size: 16px;
          width: 48%;
        }
      }
    }
    .cartGlobalBg {
      padding: 30px;
      background-color: $gray-F0;
      .item {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        border-bottom: 1px solid $gray-F0;
        padding: 16px 20px;
        color: $gray-44;
        .price {
          font-weight: 600;
        }
      }
      .item.total{font-weight: 700; color: $green-04;}
      .customStyle {
        width: 100%;
        margin-top: 20px;
        font-weight: 700;
        span {
          margin-left: 10px;
        }
        .fontWeight {
          font-weight: 400;
        }
      }
    }
  }
</style>
