import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import helpRouter from './help'
import orderRouter from './order'
import categoryRouter from './category'
import memberRouter from './member'
import http from "@/api";
import shopByhttp from "@/api/shopBy";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
      path: '/etc/webviewBridge',
      name: 'webviewBridge',
      component: () => import('@/components/Etc/WebviewBridge.vue')
  },
  ...categoryRouter,
  ...orderRouter,
  ...helpRouter,
  ...memberRouter
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    const appDom = document.querySelector('.appInner')
    return appDom.scrollTo(0, 0)
  },
  routes
})

// 로그인 필요한 메뉴 router name
const needLogin = ['orderList', 'orderDetail', 'csList', 'csDetail', 'orderForm', 'helpInquiry'];
router.beforeEach((to, from, next) => {
  // 웹 로그인 처리

  // 앱 로그인 처리
  // loginAccess=true&token=TOKEN
  if(to.query.loginAccess && to.query.token) {
    if(store.state.login) {
        return router.push(to.path)
    }

    const loginInfo = {
      token: to.query.token,
      redirectUrl: to.path
    }

    http.post('/user/conversion_token', loginInfo)
        .then(res => {
          const {accessToken} = res.data.data
          const token = accessToken.token
          shopByhttp.get(`/oauth/openid?provider=ncpstore&openAccessToken=${token}`).then((res) => {
              let payload = res.data
              payload.state = true
              store.commit('login', payload)

              // 전북 토큰
              const jbhdToken = {
                  access: accessToken.token,
                  exp: accessToken.exp
              };
              localStorage.setItem('jbhd_token', JSON.stringify(jbhdToken));

              return router.push(to.path)
          }).catch((err) => {
              console.log(err)
              return router.push(to.path)
          })
        })
        .catch(err => {
          console.log('ERROR', err)
            return router.push(to.path)
        })
  } else {
      if(needLogin.indexOf(to.name) > -1 && !store.state.login){
          console.log('로그인이 필요한 서비스입니다.')
          alert('로그인이 필요한 서비스입니다.')
          // next();
          return router.push({name: 'signin', query: {redirectUrl: to.path}})
      } else {
          console.log('NEXT');
          next()
      }
  }

})

router.afterEach(() => {
    const token = JSON.parse(localStorage.getItem('token'));
    if(!store.state.loginProcess && token && token.expired) {
        store.commit('logout');
    }
})

export default router
