export default {
    claimReasonType: [
        {id: 'CHANGE_MIND', label: '단순변심(색상,사이즈 등)'},
        {id: 'DEFECTIVE_PRODUCT', label: '상품불량/파손'},
        {id: 'WRONG_DELIVERY', label: '배송누락/오배송'},
        {id: 'OUT_OF_STOCK_SYSTEM', label: '재고부족(품절취소)'},
        {id: 'CANCEL_BEFORE_PAY', label: '입금전취소'},
        {id: 'WRONG_PRODUCT_DETAIL', label: '상품상세 정보와 다름'},
        {id: 'DELAY_DELIVERY', label: '판매자 배송 지연'},
        {id: 'OTHERS_SELLER', label: '기타(판매자 귀책)'},
        {id: 'OTHERS_BUYER', label: '기타(구매자 귀책)'},
        {id: 'OUT_OF_STOCK', label: '상품 품절/재고 없음'},
        {id: 'LATER_INPUT_ORDER', label: '배송미입력취소'}
    ],
    getLabel: function (type) {
        return this.claimReasonType.find(item => item.id === type).label
    }
}
