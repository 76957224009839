<template>
  <div class="helpPage">
    <Top
        :depth1="depth1"
        :depth2="depth2"
    />
    <router-view
        :depth1="depth1"
        :depth2="depth2"
    ></router-view>
  </div>
</template>

<script>
import Top from '@/components/Help/Top'

export default {
  name: "Media-f180c6",
  components: {
    Top
  },
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const role = this.$route.meta.role
      const subName = role === 'list'
          ? this.$route.meta.subName
          : role === 'view'
              ? this.$route.params.help
              : null
      const current = this.tab.find(x => x.id === subName)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
      }
    },
  },
  data () {
    return {
      page: {
        id: 'help',
        name: 'HELP',
        link: '/help'
      },
      tab: [
        { id: 'notice', name: 'NOTICE', link: '/help/notice' },
        { id: 'inquiry', name: '1:1 문의', link: '/help/inquiry' },
      ]
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>