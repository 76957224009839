import axios from "axios";
//import tokenCtrl from '@/lib/token';

const baseUrl = process.env.VUE_APP_ENVIROMENT === 'dev' ? process.env.VUE_APP_API_SERVER_TEST : process.env.VUE_APP_API_SERVER_URI;
const instance = axios.create({
    baseURL: baseUrl,
    headers: { "content-type": "application/json" },
    timeout: 30000,
    withCredentials: true,
    crossDomain: true,
    credentials: "include"
});

/*
instance.interceptors.request.use(
    async config => {

        // Access token expire check
        return await tokenCtrl.accessTokenChk(config);

    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
     res => {
        return res;
    }, async (err) => {

        // Token error check
        if (err.response.status === 401) {
            const chkToken = tokenCtrl.tokenErrChk(err);
            if(!chkToken){
                return false;
            }
        }

        return Promise.reject(err);

    }
);
*/

export default instance;