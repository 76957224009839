<template>
  <div class="product">
    <div class="productListBg" v-if="meta === 'list'">
      <div class="textSize18 custom">CLUB WEAR</div>
      <div class="textSize28 custom">{{ currentCategory }}</div>
    </div>
    <TabsCategory v-if="meta === 'list'"/>
    <router-view />
    <div class="wrapper noticeList">
      <NoticeList/>
    </div>
  </div>
</template>

<script>
import NoticeList from '@/components/Common/Noticelist.vue';
import TabsCategory from '@/components/Product/TabsCategory.vue';

export default {
  name: "Product-134d58",
  components: {
    TabsCategory,
    NoticeList
  },
  computed: {
    category () {
      return this.$store.getters['category']
    }
  },
  watch: {
    $route () {
      this.init();
    },
    category () {
      this.init();
    }
  },
  data(){
    return {
      meta: '',
      currentCategory: '',
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.meta = this.$route.meta.subName;
      if(this.meta === 'list'){
        this.currentCategory = this.$route.params.cate.toUpperCase();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.product {
  position: relative;
  .wrapper{
    padding-left: 30px;
    padding-right: 30px;
  }
  .wrapper.noticeList{
    padding-top: 50px;
    padding-bottom: 70px;
  }
}
.productListBg {
  width: 100%;
  height: 20vh;
  background: url('@/assets/images/list/bg_rectangle.png') center center no-repeat rgba(0, 0, 0, 0.9);
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .textSize18.custom {font-weight: 700;}
  .textSize28.custom {font-weight: 500;}
}

</style>