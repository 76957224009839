<template>
  <div class="detail">
    <section class="detailSection wrapper">
      <div class="detailHeade">
        <router-link :to="historyBack" class="textSize20">
          <i class="ic-back"></i>
        </router-link>
        <h2 class="textSize18">상품 상세</h2>
        <div class="icon"><i></i></div>
      </div>
      <swiper class="swiper detailBanner" :options="detailBannerOption">
        <swiper-slide v-for="(item, idx) in detailData.imageUrl" :key="idx" class="swiper-slide">
          <img :src="item" alt="detailBanner" />
        </swiper-slide>
        <div class="swiper-pagination detailPagination" slot="pagination"></div>
      </swiper>
      <div class="detailTile">
        <p class="title" v-html="detailData.productName"></p>
        <div class="priceInner">
          <del v-if="this.detailData.immediateDiscountAmt !== 0" class="price-del">{{ fixedPrice }}원</del>
          <p class="price">{{ salePrice }}원</p>
        </div>
      </div>

      <!-- option 영역 -->
      <OptionCont
          :productNo="productNo"
          @endLoading="endLoading"
          @inputOptionChk="inputOptionChk"
          @changeOption="changeOption"
      />
      <!-- option 영역 // -->

      <div class="btnInner">
        <button @click="goOrder" class="btn-bgGreen-03 customStyle"><i class="ic-buy"></i><span>구매</span></button>
        <button @click="goCart" class="btn-bgGreen-04 customStyle"><i class="ic-cart"></i><span>장바구니</span></button>
      </div>
    </section>
    <!-- tabs 영역 -->
    <div @click="onScrollTabs" class="detailTabs wrapper">
      <div :class="[{'active': tabs}, 'x-titleSmall']">상세정보</div>
      <div :class="[{'active': !tabs}, 'x-titleSmall']">기본정보</div>
    </div>
    <!-- tabs 영역 // -->
    <!-- 상세정보 영역 -->
    <section class="wrapper">
      <div class="detailInfoWrap contents" id="상세정보">
        <div v-if="isDetailInfoText"  class="nodata" v-html="detailData.detailInfoText"></div>
        <div v-else class="detailInfo">
          <i class="ic-nodata customStyle"></i>
          <p>상세정보가 준비중입니다.</p>
        </div>
      </div>
      <div class="detailInfoWrap" id="기본정보">
        <h3 class="titleMedium">기본정보</h3>
        <div class="defaultInfoWrap">
          <div class="acodionWrap" @click="toggleDefault" :class="{on:detailData.isOpenDefaultInfo}">
            <div class="acodionHeader">
              <div class="acodion">
                <div class="title">상품 기본정보</div>
                <div class="txt"><i class="ic-down"></i></div>
              </div>
            </div>
            <div class="acodionInner">
              <div v-if="isBaseInfo">
                <p v-html="detailData.defaultInfo"></p>
              </div>
              <div v-else class="detailInfo">
                <i class="ic-nodata customStyle"></i>
                <p>상세정보가 준비중입니다.</p>
              </div>
            </div>
          </div>
          <div class="acodionWrap" @click="toggleExchange" :class="{on:detailData.isOpenExchangeInfo}">
            <div class="acodionHeader">
              <div class="acodion">
                <div class="title">배송/반품/교환 안내</div>
                <div class="txt"><i class="ic-down"></i></div>
              </div>
            </div>
            <div class="acodionInner">
              <div v-if="isReturnInfo">
                <p v-html="detailData.changeExInfo"></p>
              </div>
              <div v-else class="detailInfo">
                <i class="ic-nodata customStyle"></i>
                <p>상세정보가 준비중입니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 상세정보 영역 // -->

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import http from '@/api/shopBy';
import regExp from '@/lib/regExp.js';
import 'swiper/css/swiper.css'
import OptionCont from "@/components/Product/Option.vue"
import LoadingCont from "@/components/Common/Loading.vue";
// apis호출 메소드 모음.
const apis = {
  productItem: (productId) => http.get(`/products/${productId}`),
  productOption: (productId) => http.get(`/products/${productId}/options`),
  cartPost: (data) => http.post('/cart', data),
}

export default {
  name: 'Detail-373bbc',
  components: {
    Swiper,
    SwiperSlide,
    OptionCont,
    LoadingCont
  },
  computed: {
    isDetailInfoText() { //상세정보 존재 여부
      return this.detailData.detailInfoText
    },
    isBaseInfo(){ //기본정보 존재 여부
      return this.detailData.productName
    },
    isReturnInfo(){ //배송/반품 정보 존재 여부
      return this.detailData.addressStr
    },
    fixedPrice(){
      return regExp.comma(this.detailData.salePrice)
    },
    salePrice(){
      return regExp.comma(this.detailData.salePrice - this.detailData.immediateDiscountAmt)
    },
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
  },
  data() {
    return {
      loading: true,
      detailData: [],
      totalDatas: [],
      flatDatas: [],
      detailInfo: {
        isOpenDefaultInfo: false,
        isOpenExchangeInfo: false,
      },
      tabs: true,
      detailBannerOption: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      optionDetail: {},
      options: [],
      selectedOption: {},
      selectedOptionNo: 0,
      purchaseCount: 1,
      selectTargetOption: {},
      productNo: this.$route.params.productNo,
      inputOptions: [],
      inputTexts: [],
    }
  },
  mounted(){
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const productItem = await apis.productItem(this.$route.params.productNo)
        this.detailData = {
          ...this.detailInfo,
          productNo: Number(this.$route.params.productNo),
          imageUrl: productItem.data.baseInfo.imageUrls.map(item => item),
          productName: productItem.data.baseInfo.productName ? productItem.data.baseInfo.productName : null,
          salePrice: productItem.data.price.salePrice,
          immediateDiscountAmt: productItem.data.price.immediateDiscountAmt,
          options: [],
          detailInfoText: productItem.data.baseInfo.content,
          addressStr: productItem.data.deliveryFee.returnWarehouse.addressStr,
          changeExInfo: productItem.data?.baseInfo?.contentFooter,
          defaultInfo: productItem.data?.baseInfo?.contentHeader
        }
        if (productItem.data.stock.stockCnt === 0) {
          this.detailData.productName += ' <span style="color:#999">(품절)</span>'
        }
      } catch (e) {
        alert('잘못된 접근입니다.')
        this.$router.go(-1)
      }
    },
    inputOptionChk(options) {
      options.forEach(el => {
        this.inputOptions.push(el.inputLabel)
      })
    },
    changeOption(options) {
      if(options.type === 'select') {
        this.selectedOptionNo = options.optionNo;
        if(options.optionNo && options.salePrice) {
          if(options.salePrice < 0) {
            this.detailData.immediateDiscountAmt = Math.abs(options.salePrice)
          } else {
            this.detailData.immediateDiscountAmt = -Math.abs(options.salePrice)
          }
        } else {
          this.detailData.immediateDiscountAmt = 0
        }
      } else if(options.type === 'input') {
        this.inputTexts = []
        options.inputOption.forEach(item => {
          this.inputTexts.push({
            inputLabel: item.inputLabel,
            inputValue: item.inputValue
          })
        })
      } else if(options.type === 'count') {
        this.purchaseCount = options.orderCnt
      }
    },
    async goCart(){
      this.loading = true
      if(!this.validation()) {
        this.loading = false
        return false
      }

      const _cartDataObj = {
        productNo: Number(this.productNo),
        optionNo: Number(this.selectedOptionNo),
        orderCnt: this.purchaseCount,
        optionInputs: this.inputTexts
      }

      await apis.cartPost([_cartDataObj]).then(({data}) => {
        this.$store.commit('setCount', data.count)
        this.$router.push({ name: 'cartList' }).catch(() => {})
      }).catch((err) => {
        alert(err.response.data.message);
      }).finally(() => {
        this.loading = false
      })
    },
    toggleDefault() {
      this.detailData.isOpenDefaultInfo = !this.detailData.isOpenDefaultInfo
    },
    toggleExchange() {
      this.detailData.isOpenExchangeInfo = !this.detailData.isOpenExchangeInfo
    },
    onScrollTabs(e) {
      const eventTarget = document.querySelector(`#${e.target.innerHTML}`)
      const appScroll = document.querySelector('.appInner');
      const targetLocation = eventTarget.offsetTop
      appScroll.scrollTo({top: targetLocation, left:0, behavior: 'smooth'})
      this.tabs = e.target.innerHTML !== '상세정보';
      this.tabs = e.target.innerHTML !== '기본정보';
    },
    onResize () {
      this.parallaxWidth = window.innerWidth >= 1024
    },
    setOrderCnt(type){
      if(type === 'plus') {
        this.purchaseCount += 1;
      } else {
        if(this.purchaseCount > 1) {
          this.purchaseCount -= 1;
        }
      }
    },
    validation() {
      // console.log('>validation>>>>>>>>', this.selectedOptionNo);
      // 필수
      if(this.purchaseCount < 1) {
        alert('구매수량을 입력해주세요.');
        return false;
      }

      if(!this.selectedOptionNo || this.selectedOptionNo === 0) {
        alert('옵션을 선택해주세요.');
        return false;
      }

      if(this.inputOptions.length > 0 && this.inputOptions.length !== this.inputTexts.length) {
        alert('옵션을 입력해주세요.');
        return false;
      }

      for(let i=0; i<this.inputTexts.length; i++) {
        if(!this.inputTexts[i].inputValue || this.inputTexts[i].inputValue === '') {
          alert('옵션을 입력해주세요.');
          return false;
        }
      }

      return true
    },
    goOrder(){
      this.loading = true
      if(!this.validation()) {
        this.loading = false
        return
      }
      const _data = {
        products: [],
        productCoupons: [],
        trackingKey: "",
        channelType: ""
      }
      const _tmp = {
        productNo: Number(this.productNo),
        optionNo: Number(this.selectedOptionNo),
        orderCnt: Number(this.purchaseCount),
        optionInputs: this.inputTexts,
      }

      _data.products.push(_tmp);

      http.post('/order-sheets', _data).then(res => {
        this.$router.push({name: 'orderForm', params: {orderSheetNo: res.data.orderSheetNo}})
      }).catch(err => {
        alert(err.response.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
    endLoading() {
      this.optionLoading = false
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';

.detail{
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  .quantity{
    position: relative;
    .plus.customStyle{
      position: absolute;
      top: 50%;
      right: 40px;
      border: 1px solid $gray-77;
      transform: translate(-50%, -50%);
      &::before{
        width: 2px;
        height: 10px;
        background-color: $gray-77;
      }
      &::after{
        width: 10px;
        height: 2px;
        background-color: $gray-77;
      }
    }
    .minus.customStyle{
      position: absolute;
      top: 50%;
      right: 0;
      border: 1px solid $gray-77;
      transform: translate(-50%, -50%);
      &::after{
        width: 10px;
        height: 2px;
        background-color: $gray-77;
      }
    }
  }
  .swiper-container {
    overflow:hidden;
  }
  .detailHeade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18px;
    padding: 20px 0;
    color: $gray-44;
    font-size: $font-size-18;
    font-weight: 700;
    margin-top: 10px;
    .back {
      font-size: $font-size-20;
      cursor: pointer;
    }
    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .detailTile {
    .priceInner {
      display: flex;
      color: $gray-A1;
      padding-top: 10px;
    }
    .title {
      color: $gray-44;
      font-size: $font-size-18;
      font-weight: 700;
    }
    del {
      text-decoration: line-through;
      margin-right: 10px;
    }
  }
  .detailOptions {
    margin-top: 20px;
  }
  .detailTabs {
    display: flex;
    border-bottom: 1px solid $gray-e5;
    >div {
      padding-bottom: 10px;
      cursor: pointer;
    }
    >div:last-child {
      margin-left: 24px;
    }
    >div.active {
      border-bottom: 2px solid $green-04;
    }
    .detailSection {
      width: 190px;
      display: flex;
      justify-content: space-between;
      font-size: $font-size-16;
      font-weight: 500;
      div {
        padding-bottom: 10px;
      }
      div.active {
        color: $green-04;
        border-bottom: 4px solid $green-04;
      }
    }
  }

  .detailInfoWrap.contents {
    min-height: 500px;
  }
  .detailInfo {
    text-align: center;
    padding: 30px 0;
    .customStyle {color: $gray-e5; font-size: $font-size-64;}
    p{font-size: $font-size-14}
  }
  .defaultInfoWrap {
    padding-top: 10px;
    >div:last-child {
      margin-top: 10px;
    }
  }
  .btnInner {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 30px 0;
    .customStyle {
      width: 48%;
      font-size: 16px;
      i {
        font-size: 16px;
      }
      span {
        margin-left:10px;
      }
    }
  }
  .detailPagination {
    background: transparent
  }
}
</style>
