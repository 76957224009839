<template>
  <div class="greenBanner" v-if="isBanner">
    <h3 class="titleMedium">초록이네 배너존</h3>
    <div>
      <div v-for="(item, idx) in greenBannerData" :key="idx" class="greenBannerInner">
        <div class="inner">
          <a :href="item.landingUrl"><img :src="item.imageUrl" alt="이미지 배너"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/shopBy';
// apis호출 메소드 모음.
const apis = {
  greenBanner: (bannerSesencode) => http.get(`/display/banners/${bannerSesencode}`)
}
const GREENBANNERCODE = '004'; //초록이네 배너 섹션코드

export default {
  name: 'GreenBanner-b15d62',
  data() {
    return {
      greenBannerData : []
    }
  },
  computed: {
    isBanner () {
      return this.greenBannerData.length > 0
    },
  },
  mounted() {
    this.init()
  },
  created() {
    // console.log(111, this.isBanner);
  },
  methods: {
    async init() {
      const greenBanner = await apis.greenBanner(GREENBANNERCODE);
      this.greenBannerData = greenBanner.data.flatMap(group => this.greenBannerInfo(group)).slice(1,3);
    },
    greenBannerInfo(group){
      return group?.accounts.flatMap(accountsItem => {
        return accountsItem?.banners.flatMap(bannerItem => {
          return {
            bannerNo: bannerItem.bannerNo,
            imageUrl: bannerItem.imageUrl,
            landingUrl: bannerItem.landingUrl
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.greenBanner {
  padding: 70px 0 0 0;
  h3 {
    padding-bottom: 15px;
  }
}
.greenBannerInner {
  &:last-child {
    padding-top: 15px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid $gray-DD;
  }
  .title {
   
    .sub {
      font-size: 15px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
    .tit {
      font-size: 22px;
      font-weight: 600;
      padding-top: 8px;
      color: $white;
    }
  }
}
</style>