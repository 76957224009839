<template>
  <div class="contentsWrap">
    <!-- 뒤로가기 & 목록 -->
    <div class="backMenu">
      <router-link :to="historyBack" class="back"><img src="@/assets/images/common/ico_back.png" alt="뒤로가기"/></router-link>
      <p>
        <router-link :to="{name: 'inquiryForm', params: {inquiryNo: articleNo}}" class="btn-border-small green03 list">글 수정</router-link>
        <a href="javascript:void(0)" class="btn-border-small green03 list" @click="deleteInquiry">글 삭제</a>
        <router-link :to="{name: 'helpInquiry'}" class="btn-border-small list">목록</router-link>
      </p>
    </div>
    <!-- //뒤로가기 & 목록 -->

    <!-- Detail View -->
    <div class="detailView simple answer">
      <div class="title">
        <p class="subject">{{ viewData.title }}</p>
        <div class="info">
          <span class="date">{{ viewData.date }}</span>
          <span :class="[{'green':viewData.answered}, 'status']">{{ viewData.inquiryStatus }}</span>
        </div>
      </div>
      <div class="content" v-text="viewData.content">
      </div>
      <div class="attachment" v-if="viewData.imageUrls">
        첨부파일 :
          <a :href="viewData.imageUrls" target="_blank">{{ viewData.originalImage }}</a>
      </div>
    </div>
    <div class="answerView">
      <p class="subject" v-if="viewData.answered">답변 내용</p>
      <div class="content" v-if="viewData.answered" v-html="viewData.answerContent"></div>
      <div v-else class="nodata">
        <i class="ic-nodata customStyle"></i>
        <p>문의주신 내용에 대해서 답변 대기중입니다.</p>
      </div>
    </div>
    <!-- //Detail View -->

    <List class="mgTop50"/>

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import regExp from '@/lib/regExp'
import List from '@/components/Common/Noticelist.vue';
import http from '@/api/shopBy'
import dayjs from 'dayjs'
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "InquiryView-c211a5",
  props: ['depth1', 'depth2'],
  components: {
    List,
    LoadingCont
  },
  computed: {
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
  },
  data() {
    return {
      loading: true,
      articleNo: this.$route.params.id,
      viewData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      http.get(`/inquiries/${this.articleNo}`)
        .then(({data}) => {
          this.viewData = {
            title: data.inquiryTitle,
            content: data.inquiryContent.replace(/\\r\\n|\\n|\\r/gm,'<br>'),
            date: dayjs(data.registerYmdt).format('YYYY-MM-DD'),
            inquiryStatus: this.setStatus(data.inquiryStatus),
            answered: data.inquiryStatus === 'ANSWERED',
            imageUrls: data.imageUrls[0],
            originalImage: data.originalImageUrls[0],
          }
          if(data.answer) this.viewData.answerContent = data.answer.answerContent
        })
        .catch(err => {
          console.log(err);
          alert(err.response.data.message)
          this.$router.push({name: 'helpInquiry'})
        }).finally(() => {
          this.loading = false
        })
    },
    setStatus(status) {
      switch (status) {
        case 'ISSUED':
          return '답변대기'
        case 'ASKED':
          return '답변대기'
        case 'IN_PROGRESS':
          return '답변 진행중'
        case 'ANSWERED':
          return '답변완료'
      }
    },
    deleteInquiry() {
      if(confirm('삭제하시겠습니까?')) {
        this.loading = true
        http.delete(`/inquiries/${this.articleNo}`)
          .then(({data}) => {
            console.log(data);
            alert('삭제되었습니다.')
            this.$router.push({name: 'helpInquiry'})
          })
          .catch(err => {
            console.log(err);
            alert(err.response.data.message)
            this.$router.push({name: 'helpInquiry'})
          }).finally(() => {
            this.loading = false
          })
      }
    },
    comma(num) {
      return regExp.comma(num)
    },
  }
}
</script>

<style scoped>
.contentsWrap {
  padding-bottom: 50px;
}
</style>