<template>
  <div>
    <div class="innerPd">
      <!-- 뒤로가기 -->
      <div class="backBtnCont">
        <router-link :to="historyBack">
          <img src="@/assets/images/common/ico_back.png" alt="뒤로가기">
        </router-link>
        <h2>주문서 작성</h2>
      </div>
      <!-- //뒤로가기 -->

      <!-- 주문 상품 -->
      <ul class="orderList line">
        <li v-for="(item, idx) in orderProducts" :key="idx">
          <div class="orderItem">
            <div class="orderItemImg">
              <router-link
                  :style="{'background': productThumb(item.imageUrl)}"
                  :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                  class="linkArea img"
              >
                <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
                <!--<img :src="item.imageUrl" :alt="item.productName">-->
              </router-link>
            </div>
            <div class="orderItemInfo">
              <router-link :to="{name: 'categoryDetail', params: {productNo: item.productNo}}" class="linkArea">
                <span class="itemName">{{ item.productName }}</span>
              </router-link>
              <!-- 상품 옵션(optionUsed) -->
              <div class="option" v-if="item.optionUsed">
                <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                  {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                </p>
                <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                  {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                </p>
              </div>
              <!-- //상품 옵션 -->
              <p>수량 : {{ comma(item.orderCnt) }}개</p>
              <p>가격 : {{ comma(item.standardAmt) }}원</p>
            </div>
          </div>
        </li>
      </ul>
      <!-- //주문 상품 -->

    </div>

    <!-- 주문자 정보 -->
    <div class="formWrap">
      <div class="innerPd pdTop0">
        <div class="row top">
          <h3 class="layerSubTit">주문자 정보</h3>
          <div class="fixRight">
              <span class="radioCont">
              <input type="radio" id="radio1" name="radio1" value="ordererSame" v-model="ordererType"/>
              <label for="radio1">회원정보와 동일</label>
            </span>
            <span class="radioCont">
              <input type="radio" id="radio2" name="radio1" value="ordererDirect" v-model="ordererType"/>
              <label for="radio2">직접입력</label>
            </span>
          </div>
        </div>
        <div class="row">
          <label class="label" for="orderer">주문자 이름</label>
          <input type="text" placeholder="주문자 이름을 입력하세요" id="orderer" class="form-control w100"
                 v-model="form.orderer.ordererName"/>
        </div>
        <div class="row">
          <label class="label" for="ordererPhone_1">주문자 휴대폰번호</label>
          <input type="text" placeholder="주문자 휴대폰번호를 입력하세요" id="ordererPhone_1" class="form-control w100"
                 v-model="form.orderer.ordererContact1" @keyup="checkPhoneNum"/>
        </div>
        <div class="row">
          <label class="label" for="ordererPhone_2">주문자 연락처</label>
          <input type="text" placeholder="주문자 연락처를 입력하세요" id="ordererPhone_2" class="form-control w100"
                 v-model="form.orderer.ordererContact2" @keyup="checkPhoneNum"/>
        </div>
        <div class="row">
          <label class="label" for="ordererMemo">주문 메모</label>
          <input type="text" placeholder="주문 메모를 입력하세요" id="ordererMemo" class="form-control w100"
                 v-model="form.orderMemo"/>
        </div>
      </div>
    </div>
    <!-- //주문자 정보 -->

    <!-- 배송 정보 -->
    <div class="formWrap innerPd pdTop0">
      <div class="topLine pdTop40">
        <div class="row top">
          <h3 class="layerSubTit line">배송 정보</h3>
          <div class="fixRight">
              <span class="radioCont">
              <input type="radio" id="radio3" name="radio3" value="same" v-model="shippingType"/>
              <label for="radio3">주문자 정보와 동일</label>
            </span>
            <span class="radioCont">
              <input type="radio" id="radio4" name="radio4" value="direct" v-model="shippingType"/>
              <label for="radio4">직접입력</label>
            </span>
          </div>
        </div>
        <div class="row">
          <label class="label" for="rName">받으실분 이름</label>
          <input type="text" placeholder="주문자 이름을 입력하세요" id="rName" class="form-control w100" v-model="form.shippingAddress.receiverName"/>
        </div>
        <div class="row">
          <label class="label" for="rPhone_1">휴대폰번호</label>
          <input type="text" placeholder="휴대폰번호를 입력하세요" id="rPhone_1" class="form-control w100"
                 v-model="form.shippingAddress.receiverContact1" @keyup="checkPhoneNum"/>
        </div>
        <div class="row">
          <label class="label" for="rPhone_2">연락처</label>
          <input type="text" placeholder="연락처를 입력하세요" id="rPhone_2" class="form-control w100"
                 v-model="form.shippingAddress.receiverContact2" @keyup="checkPhoneNum"/>
        </div>
        <div class="row">
          <label class="label">주소</label>
          <div class="address">
            <input type="text" placeholder="우편번호를 검색하세요" class="form-control" v-model="form.shippingAddress.receiverZipCd" readonly/>
            <a href="javascript:void(0)" class="btn-border" @click="findZipcode">우편번호 검색</a>
            <div class="zipcodeWrap" ref="embed"></div>
          </div>
          <input type="text" placeholder="우편번호를 검색하세요" class="form-control w100" v-model="form.shippingAddress.receiverAddress" readonly/>
          <input type="text" placeholder="세부 주소를 입력하세요" class="form-control w100" v-model="form.shippingAddress.receiverDetailAddress"/>
        </div>
        <div class="row">
          <label class="label" for="rMemo">배송 메모</label>
          <input type="text" placeholder="배송 메모를 입력하세요" id="rMemo" class="form-control w100" v-model="form.deliveryMemo"/>
        </div>
      </div>
    </div>
    <!-- //배송 정보 -->

    <!-- 결제 정보 -->
    <div class="payWrap">
      <h3>결제 정보</h3>
      <div class="infoBox">
        <div class="row flex">
          <p class="label">총 상품금액</p>
          <p class="content">{{ comma(paymentInfo.totalStandardAmt) }}원</p>
        </div>
        <div class="row flex">
          <p class="label">총 할인금액</p>
          <p class="content">{{ comma(paymentInfo.totalImmediateDiscountAmt + paymentInfo.totalAdditionalDiscountAmt) }}원</p>
        </div>
        <div class="row flex">
          <p class="label">배송비({{ orderCnt }}건)</p>
          <p class="content">{{ comma(paymentInfo.deliveryAmt) }}원</p>
        </div>
        <div class="row">
          <p class="label">쿠폰 사용</p>
          <div class="optionItem mgTop10">
            <select class="pick" v-model="form.coupons.cartCouponIssueNo">
              <option value="">쿠폰 미사용</option>
              <option v-for="item in coupon" :key="item.couponIssueNo" :value="item.couponIssueNo">{{ item.displayCouponName }}</option>
            </select>
            <i class="ic-arrow-down selectDown fix"></i>
          </div>
          <div class="btnRightBox">
            <a href="javascript:void(0)" class="btn-border-small green03" @click="calculate('coupon')">쿠폰 적용</a>
            <a href="javascript:void(0)" class="btn-border-small" @click="cancelCouponApply">쿠폰 적용 취소</a>
          </div>
        </div>
        <div class="row" v-if="accumulationUsable">
          <p class="label">
            포인트 사용
            <span>사용가능 포인트 : {{ comma(paymentInfo.accumulationAmt) }}포인트</span>
          </p>
          <input type="text" class="form-control w100 mgTop10" v-model="form.subPayAmt" @keyup="checkNum" />
          <div class="btnRightBox">
            <a href="javascript:void(0)" class="btn-border-small green03" @click="checkPoint">포인트 사용</a>
            <a href="javascript:void(0)" class="btn-border-small" @click="cancelPoint">포인트 사용 취소</a>
          </div>
        </div>
        <div class="row flex">
          <p class="label c-green03 bold">최종 결제 금액</p>
          <p class="content c-green03 bold">{{ comma(paymentInfo.paymentAmt) }}원</p>
        </div>
      </div>
    </div>
    <!-- //결제 정보 -->

    <!-- 결제 수단 -->
    <div class="payWrap">
      <h3>결제 수단</h3>
      <div class="infoBox">
        <div class="row" v-for="(pay, index) in payTypeList" :key="pay.id">
          <p class="label">
            <input type="radio" class="radio" :id="'paymethod_'+index" name="paymethod" :value="pay.id" v-model="form.payType"/>
            <label :for="'paymethod_'+index">{{ pay.name }}</label>
          </p>
        </div>
      </div>
    </div>
    <!-- //결제 수단 -->

    <!-- 필수 동의(개인정보처리방침, 판매자 , 구매진행 동의) -->
    <div class="payWrap agreeCont">
      <h4>개인정보 수집/이용 <span>(필수)</span></h4>
      <div class="textarea w100" rows="4" ref="PI_COLLECTION_AND_USE_ON_ORDER"></div>
      <p class="radioCont">
        <span>
          <input type="radio" class="radio" id="agree_1_y" name="agree_1" value="Y" v-model="terms.PI_COLLECTION_AND_USE_ON_ORDER.agree"/>
          <label for="agree_1_y">동의</label>
        </span>
        <span>
          <input type="radio" class="radio" id="agree_1_n" name="agree_1" value="N" v-model="terms.PI_COLLECTION_AND_USE_ON_ORDER.agree"/>
          <label for="agree_1_n">미동의</label>
        </span>
      </p>

      <h4>개인정보 판매자 제공<span>(필수)</span></h4>
      <div class="textarea w100" rows="4" ref="PI_SELLER_PROVISION"></div>
      <p class="radioCont">
        <span>
          <input type="radio" class="radio" id="agree_2_y" name="agree_2" value="Y" v-model="terms.PI_SELLER_PROVISION.agree"/>
          <label for="agree_2_y">동의</label>
        </span>
        <span>
          <input type="radio" class="radio" id="agree_2_n" name="agree_2" value="N" v-model="terms.PI_SELLER_PROVISION.agree"/>
          <label for="agree_2_n">미동의</label>
        </span>
      </p>

      <h4><span>(필수)</span>이용자께서는 구매하실 상품의 내용 및 종류, 가격, 배송정보 등을 확인하였으며, 구매에 동의하시겠습니까?<br/>(「전자상거래 등에서의 소비자보호에 관한 법률」 제8조 제2항에 따른 동의 확인 절차)</h4>
      <p class="radioCont pdTop10">
        <span>
          <input type="radio" class="radio" id="agree_3_y" name="agree_3" value="Y" v-model="terms.ORDER_INFO_AGREE.agree"/>
          <label for="agree_3_y">동의</label>
        </span>
        <span>
          <input type="radio" class="radio" id="agree_3_n" name="agree_3" value="N" v-model="terms.ORDER_INFO_AGREE.agree"/>
          <label for="agree_3_n">미동의</label>
        </span>
      </p>

      <a href="javascript:void(0)" class="btn-bgGreen-03 w100" @click="reserve">
        <strong>결제하기</strong>({{ comma(paymentInfo.paymentAmt) }}원)
      </a>
    </div>
    <!-- // 필수 동의 -->

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import shopByHttp from '@/api/shopBy'
// import shopByCtrl from '@/common/shopbyCallback'
import regExp from "@/lib/regExp";
import lib from "@/lib/common"
import LoadingCont from "@/components/Common/Loading.vue"
import cacheStorage from "@/lib/cacheStorage"

export default {
  name: "Form-9f3e5e",
  components: {
    LoadingCont
  },
  computed: {
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
    isLogin() {
      return this.$store.getters['isLogin']
    },
    accessToken() {
      return this.$store.getters['accessToken']
    }
  },
  watch: {
    ordererType () {
      if(this.ordererType === 'ordererDirect') {
        this.form.orderer = {
          ordererName: '',
          ordererContact1: '',
          ordererContact2: '',
          ordererEmail: ''
        }
      } else {
        this.form.orderer = this.data.ordererContact
      }
    },
    shippingType () {
      if(this.shippingType === 'direct') {
        this.form.shippingAddress = {
          addressNo: 0,
          receiverZipCd: '',
          receiverAddress: '',
          receiverJibunAddress: '',
          receiverDetailAddress: '',
          receiverName: '',
          addressName: '',
          receiverContact1: '',
          receiverContact2: '',
          customsIdNumber: '',
          countryCd: 'KR'
        }
      } else {
        this.form.shippingAddress.receiverName = this.form.orderer.ordererName
        this.form.shippingAddress.receiverContact1 = this.form.orderer.ordererContact1
        this.form.shippingAddress.receiverContact2 = this.form.orderer.ordererContact2
        const mainAddress = this.data.orderSheetAddress.mainAddress;
        this.form.shippingAddress.addressNo = mainAddress.addressNo
        if(mainAddress.addressNo !== 0) {
          this.form.shippingAddress.receiverZipCd = mainAddress.receiverZipCd
          this.form.shippingAddress.receiverAddress = mainAddress.receiverAddress
          this.form.shippingAddress.receiverJibunAddress = mainAddress.receiverJibunAddress
          this.form.shippingAddress.receiverDetailAddress = mainAddress.receiverDetailAddress
          this.form.shippingAddress.addressName = mainAddress.addressName
          this.form.shippingAddress.customsIdNumber = mainAddress.customsIdNumber

          this.calculate('delivery')
        }
        this.form.shippingAddress.countryCd = 'KR'
      }
    }
  },
  data() {
    return {
      loading: true,
      data: {},
      orderProducts: [],
      orderCnt: 0, // 주문건(묶음)
      coupon: [],
      paymentInfo: [], // 결제정보
      payTypeList: [ // 결제 수단 리스트(신용카드, 실시간 계좌이체만 지원)
        { id: 'CREDIT_CARD', name: '신용카드' },
        { id: 'REALTIME_ACCOUNT_TRANSFER', name: '실시간 계좌이체' }
      ],
      payType: '', // 결제 수단
      ordererType: 'ordererSame',
      shippingType: 'direct',
      form: {
        orderSheetNo: '',
        orderer: {
          ordererName: '',
          ordererContact1: '',
          ordererContact2: '',
          ordererEmail: '',
        },
        orderMemo: '',
        shippingAddress: {
          addressNo: 0, // 0: 신규, 0 이상: 이전배송지
          receiverZipCd: '',
          receiverAddress: '',
          receiverJibunAddress: '', // 배송지 지번(지역추가배송비계산 시 사용)
          receiverDetailAddress: '',
          receiverName: '',
          addressName: '',
          receiverContact1: '',
          receiverContact2: '',
          customsIdNumber: '', // 개인고유통관부호
        },
        deliveryMemo: '',
        accumulationUseAmt: 0, // 적립금 사용금액
        useDefaultAddress: false, // 기본 주소지 설정 여부
        saveAddressBook: true, // 주소록에 등록 여부
        member: true, // 회원 여부
        updateMember: false,
        tempPassword: null, // 임시주문번호(비회원인경우 필수)
        coupons: {
          cartCouponIssueNo: '', // 장바구니 쿠폰 발행 번호
          // promotionCode: "", // 쿠폰 할인 코드
          // productCoupons: [ // 상품 쿠폰
          //   {
          //     productNo: 0,
          //     couponIssueNo: 0
          //   }
          // ]
        },
        subPayAmt: 0, // 적립금 사용액
        orderTitle: '', // PG 명세서에 표시될 주문명
        payType: 'CREDIT_CARD', // 결제 수단
        pgType: 'KCP', // PG사
        clientReturnUrl: '', // 결제완료 후 이동할 URL
        clientParams: '', // 결제완료 API 호출 후 다시 전달 받을 값(NCPPay script에서 넣음)
        paymentAmtForVerification: 0, // 검증을위한 결제예정금액(적립금사용후)
        savesLastPayType: false, // 선택한 결제수단 사용여부
        applyCashReceipt: false, // 무통장거래시 현금영수증 발행 신청 여부
        // cashReceipt: {
        //   cashReceiptKey: '', // 발급용도에 따른 키(소득공제용: 휴대폰번호, 지출증빙용: 사업자번호)
        //   cashReceiptIssuePurposeType: '', // 발금용도 (INCOME_TAX_DEDUCTION: 소득공제용, PROOF_EXPENDITURE: 지출증빙)
        // }
      },
      termsList: ['PI_COLLECTION_AND_USE_ON_ORDER', 'PI_SELLER_PROVISION', 'ORDER_INFO_AGREE'],
      terms: {
        PI_COLLECTION_AND_USE_ON_ORDER: {
          agree: "N",
        },
        PI_SELLER_PROVISION: {
          agree: "N",
        },
        ORDER_INFO_AGREE: {
          agree: "N",
        },
      },
      accumulationUsable: true, // 적립금 사용 가능 여부
      isApp: false
    }
  },
  created() {
    this.form.orderSheetNo = this.$route.params.orderSheetNo
    this.form.member = this.isLogin
    this.form.tempPassword = this.isLogin ? null : this.form.orderSheetNo

    this.init()
    this.getCouponList()
    // userAgent has 'JeonBukWebView' String
    this.isApp = navigator.userAgent.includes('fc.hyundai')
  },
  mounted() {
    this.getTerms()
  },
  methods: {
    init() {
      shopByHttp.get(`/order-sheets/${this.form.orderSheetNo}`).then(({data}) => {
        this.data = data;

        if (data.invalidProducts) {
          alert('유효하지 않은 상품이 포함되어 있습니다.');
          // this.$router.push({ name: 'main' })
        }

        this.orderProducts = data.deliveryGroups.flatMap(deliveryGroup => this.makeOrderProductInfo(deliveryGroup));
        this.orderCnt = data.deliveryGroups.length;
        this.paymentInfo = data.paymentInfo; // 결제정보

        this.orderProducts.forEach(orderProduct => { // 적립금 사용 가능 여부 확인
          if (!orderProduct.accumulationUsable) {
            this.accumulationUsable = false;
          }
        })

        if (this.ordererType === 'ordererSame') {
          this.form.orderer = data.ordererContact; // 주문자정보
        }
        if (this.shippingType === 'same') {
          this.form.shippingAddress = data.orderSheetAddress.mainAddress; // 배송정보
          this.form.shippingAddress.countryCd = 'KR'
        }

      }).catch(() => {
        alert('잘못된 요청입니다.');
        this.$router.push({ name: 'main' })
      }).finally(() => {
        this.loading = false;
      })
    },
    makeOrderProductInfo(deliveryGroup) {
      const REPRESENTATIVE_PRODUCT = 0;
      const { deliveryAmt } = deliveryGroup;

      return deliveryGroup.orderProducts
          .flatMap(({ orderProductOptions, imageUrl, productName, optionUsed, accumulationUsable }) => {
            orderProductOptions[REPRESENTATIVE_PRODUCT].imagesUrl = imageUrl;
            orderProductOptions.forEach(orderProductOption => {
              orderProductOption.productName = productName;
              orderProductOption.optionUsed = optionUsed;
              orderProductOption.accumulationUsable = accumulationUsable;
            });
            return orderProductOptions;
          })
          .map((orderProductOption, _, orderProductOptions) => ({
            imageUrl: orderProductOption.imageUrl,
            productName: orderProductOption.productName,
            deliveryAmt,
            //deliveryPayType: deliveryPayType === 'PAY_ON_DELIVERY' ? '착불' : '선결제',
            productNo: orderProductOption.productNo,
            optionUsed: orderProductOption.optionUsed,
            optionTitle: orderProductOption.optionTitle,
            optionName: orderProductOption.optionName,
            optionValue: orderProductOption.optionValue,
            optionInputs: orderProductOption.optionInputs,
            orderCnt: orderProductOption.orderCnt,
            standardAmt: orderProductOption.price.standardAmt, // 상품금액
            // accumulationAmtWhenBuyConfirm: orderProductOption.accumulationAmtWhenBuyConfirm, // 예상 적립금
            // discountAmt: shopby.utils.getDiscountAmt(orderProductOption.price, orderProductOption.orderCnt),
            // productTotalAmt: shopby.utils.getProductTotalAmt(orderProductOption.price, orderProductOption.orderCnt), // 합계금액
            rowSpan: orderProductOptions.indexOf(orderProductOption) !== 0 ? 0 : orderProductOptions.length,
            deliveryInternational: deliveryGroup.orderProducts[0].deliveryInternational,
            accumulationUsable: orderProductOption.accumulationUsable
          }));
    },
    reserve () { // 주문
      if(this.validation('order')) {

        this.form.shippingAddress.receiverJibunAddress = `${this.form.shippingAddress.receiverAddress} ${this.form.shippingAddress.receiverDetailAddress}`
        this.form.paymentAmtForVerification = this.paymentInfo.paymentAmt //검증을위한 결제예정금액(적립금사용후)
        this.form.paymentAmt = this.paymentInfo.paymentAmt

        // coupons 적용 필요!!!!!!!!!!!

        let orderTit = regExp.truncate(this.orderProducts[0].productName, 25)
        this.form.orderTitle = orderTit
        if(this.orderProducts.length > 1) {
          orderTit += ` 외 ${this.orderProducts.length - 1}건`
        }

        // 임시설정
        //this.form.subPayAmt = 0 // 적립금 사용액
        const clientReturnUrl = process.env.VUE_APP_ENVIROMENT === 'dev' ? process.env.VUE_APP_DEV_CLIENT_REDIRECT_URI : process.env.VUE_APP_CLIENT_REDIRECT_URI
        this.form.clientReturnUrl = clientReturnUrl
        this.form.clientParams = {}
        this.form.member = true
        // this.form.paymentAmtForVerification = 0 //검증을위한 결제예정금액(적립금사용후)
        // this.form.paymentAmt = 0 //검증을위한 결제예정금액(적립금사용후)

        // confirmUrl, form.clientReturnUrl env 처리 필요 !!!!!!!!!!!!!!
        // this.loading = true
        // shopByHttp.post('/payments/reserve', this.form)
        //   .then(() => {

        const accessToken = JSON.parse(localStorage.getItem('token')).access;

        const purchaseConfigure = {
          'clientId': process.env.VUE_APP_SHOP_BY_CLIENT_ID,
          'confirmUrl': clientReturnUrl,
          'platform': lib.getPlatform(),
          'accessToken': accessToken
        }
        // console.log(navigator.userAgent);


        if(purchaseConfigure['platform'] === "AOS" || purchaseConfigure['platform'] === "IOS") {
          purchaseConfigure['confirmUrl'] = "jbfc://purchaseGreenStore"
          this.form.inAppYn = "Y"
          this.form.extraData = {
            appUrl: "jbfc://"
          }
          alert(JSON.stringify(purchaseConfigure));
        }


        // console.log(purchaseConfigure);


        // return;
        // alert(purchaseConfigure['platform'])
        window.NCPPay.setConfiguration(purchaseConfigure);
        window.NCPPay.reservation(this.form, () => {
          // console.log('주문 성공', response);
        }, (err) => {
          console.log('주문 실패', err);
        })

          // }).catch((err) => {
          //   console.log('주문 실패 ERR >>>>>>>>', err.response.data);
          //   shopByCtrl.failCallback(err);
          // }).finally(() => {
          //   this.loading = false
          // })
      }

    },
    getTerms () {
      // this.termsList.map(row => {
      //   if(row !== 'ORDER_INFO_AGREE') {
      //     const term = termsInfo.data[row.toLowerCase()].contents
      //     this.$refs[row].innerHTML = term
      //   }
      //   this.terms[row].agree = "N"
      // })

      Promise.all(
          this.termsList.map(async (row) => {
            if (row !== 'ORDER_INFO_AGREE') {
              const term = await cacheStorage.getItem(row);
              // const term = await shopByHttp.get(`/terms?termsTypes=${row}`);
              // this.terms[row].content = term.data[row.toLowerCase()].contents;
              // console.log(term, row.toLowerCase(), term[row.toLowerCase()]);
              this.$refs[row].innerHTML = term[row.toLowerCase()].contents
            }
            this.terms[row].agree = "N"
          })
      )
    },
    getCouponList () {
      shopByHttp.get(`/order-sheets/${this.form.orderSheetNo}/coupons`).then(res => {
        this.coupon = res.data.cartCoupons
      }).catch(err => {
        console.log('getCouponList ERR ----> ', err);
      })
    },
    cancelCouponApply () {
      if(this.form.coupons.cartCouponIssueNo === ''){
        alert('적용중인 쿠폰이 없습니다.');
        return;
      }
      this.form.coupons.cartCouponIssueNo = '';
      this.calculate('coupon')
    },
    checkPoint () {
      if(this.form.subPayAmt % 10 !== 0 || this.form.subPayAmt < 10) {
        alert('포인트는 10포인트 단위로 사용하실 수 있습니다.');
        this.form.subPayAmt = 0;
        return;
      }
      if(this.form.subPayAmt > this.paymentInfo.accumulationAmt) {
        alert('사용가능 포인트를 초과하였습니다.');
        this.form.subPayAmt = 0;
        return;
      }

      this.calculate('point')
    },
    cancelPoint () {
      this.form.subPayAmt = 0;
      this.calculate('point')
    },
    calculate (type) {
      this.loading = true;
      if(!this.validation('calculate')) {
        this.loading = false;
      } else {
        const reqBody = {
          addressRequest: this.form.shippingAddress
        };
        reqBody.addressRequest.addressType = 'BOOK'; // 배송지타입(BOOK: 기본주소, RECENT: 최근주소, RECURRING_PAYMENT: 정기결제 배송주소)
        reqBody.addressRequest.defaultYn = 'false'; // 기본배송지 여부
        // if (this.shippingType === 'same') {
        //   reqBody.addressRequest.defaultYn = 'true';
        // }
        reqBody.couponRequest = this.form.coupons;

        if (type === 'coupon') {
          const couponData = {
            cartCouponIssueNo: this.form.coupons.cartCouponIssueNo
          }

          shopByHttp.post(`/order-sheets/${this.form.orderSheetNo}/coupons/apply`, couponData).then(res => {
            this.paymentInfo.paymentAmt = res.data.paymentInfo.paymentAmt;
          }).catch(err => {
            console.log('coupon apply ERR ----> ', err);
          }).finally(() => {
            this.loading = false;
          })
        }

        if (type === 'point') {
          this.subPayAmt = Number(this.form.subPayAmt);
          reqBody.accumulationUseAmt = Number(this.form.subPayAmt);

          // 쿠폰, 적립금 적용 금액
          shopByHttp.post(`/order-sheets/${this.form.orderSheetNo}/calculate`, reqBody).then(res => {
            this.paymentInfo.paymentAmt = res.data.paymentInfo.paymentAmt;
          }).catch(err => {
            // this.paymentInfo.paymentAmt = 0;
            alert(err.response.data.message);
          }).finally(() => {
            this.loading = false;
          })
        }

        if (type === 'delivery') {
          reqBody.addressRequest.receiverJibunAddress = reqBody.addressRequest.receiverAddress + ' ' + reqBody.addressRequest.receiverDetailAddress;
          shopByHttp.post(`/order-sheets/${this.form.orderSheetNo}/calculate`, reqBody).then(({data}) => {
            this.paymentInfo.deliveryAmt = data.paymentInfo.deliveryAmt + data.paymentInfo.remoteDeliveryAmt;
            this.paymentInfo.paymentAmt = data.paymentInfo.paymentAmt;
          }).catch(err => {
            alert(err.response.data.message);
          }).finally(() => {
            this.loading = false;
          })
        }

      }
    },
    findZipcode () {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.form.shippingAddress.receiverZipCd = data.zonecode;
          this.form.shippingAddress.receiverAddress = data.address;

          this.calculate('delivery')
        }
      }).embed(this.$refs.embed);
    },
    comma (num) {
      return regExp.comma(num)
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    checkPhoneNum (e) {
      const regex = /^[0-9\b -]{0,13}$/;
      if (!regex.test(e.target.value)) {
        e.target.value = e.target.value.slice(0, -1);
      }
      e.target.value = regExp.phoneFormatter(e.target.value);
    },
    checkNum (e) {
      e.target.value = e.target.value.replace(/[^-0-9]/g,'');
    },
    validation (type) {
      // 필수입력란
      const arr = [
        { id: '우편번호', value: this.form.shippingAddress.receiverZipCd },
        { id: '주소', value: this.form.shippingAddress.receiverAddress }
      ];
      const detail = [
        { id: '주문자 이름', value: this.form.orderer.ordererName },
        { id: '주문자 휴대폰번호', value: this.form.orderer.ordererContact1 },
        { id: '받으실분 이름', value: this.form.shippingAddress.receiverName },
        { id: '휴대폰번호', value: this.form.shippingAddress.receiverContact1 },
        { id: '세부 주소', value: this.form.shippingAddress.receiverDetailAddress }
      ];
      if(type !== 'calculate'){
        arr.push(...detail);
      }

      // 주문자정보, 배송정보
      for(let i = 0; i < arr.length; i++) {
        if(!arr[i].value) {
          alert(arr[i].id + '을(를) 입력해주세요.');
          return false;
        }
      }

      if(type === 'calculate') {
        return true;
      }

      // 필수동의
      for(let term in this.terms) {
        if(!this.terms[term].agree || this.terms[term].agree === 'N') {
          alert('필수 동의 항목은 모두 동의하셔야 구매 가능합니다.');
          return false;
        }
      }

      return true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
.orderList {
  margin-top: 0;

  li:first-child {
    border-top: 0;
  }
}
.pdTop10 {
  padding-top: 10px !important;
}
.agreeCont {
  .textarea {
    height: 142px;
    overflow: auto;
    padding: 16px;
    border: 1px solid #ddd;
    color: $gray-77;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
}
</style>
