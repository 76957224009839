<template>
  <div>
    <div class="productListWrap">
      <div class="saleFillter">
        <select v-model="selectedOrderType" @change="selectedOrder">
          <option
              v-for="item in orderType"
              :key="item.type"
              :value="item.type">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="productList">
        <div class="productMoreInner">
          <div v-for="(item, index) in productList" :key="index" class="item">
            <router-link :to="{name: 'categoryDetail', params: {productNo: item.productNo}}">
              <p class="img" :style="{'background': productThumb(item.listImageUrls[0])}">
                <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
              </p>
              <p class="titleSmall" v-html="item.productName"></p>
              <p class="txteSmall">{{ comma(item.salePrice) }}원</p>
            </router-link>
          </div>
        </div>
        <div v-if="productList.length === 0 && !loading" class="nodata">
          <i class="ic-nodata customStyle"></i>
          <p class="noData">등록된 제품이 없습니다.</p>
        </div>
        <div v-else>
          <a href="javascript:void(0)" class="btn-border customStyle" v-if="more" @click="nextPage">더보기</a>
        </div>
      </div>
    </div>
    <LoadingCont v-if="loading"/>
  </div>
</template>

<script>
import shopByHttp from "@/api/shopBy";
import regExp from '@/lib/regExp.js';
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: 'List-23f6a9',
  components: {
    LoadingCont
  },
  watch: {
    $route () {
      this.loading = true;
      this.productList = [];
      this.setCategory()
    },
    category() {
      this.productList = [];
      this.setCategory()
    }
  },
  computed: {
    category () {
      return this.$store.getters['category']
    }
  },
  data(){
    return {
      loading: true,
      currentCategory: '',
      categoryNo: '',
      categoryQuery: '',
      orderType: [
        {name: '판매량순', type: 'SALE_CNT.DESC'},
        {name: '낮은가격순', type: 'DISCOUNTED_PRICE.ASC'},
        {name: '높은가격순', type: 'DISCOUNTED_PRICE.DESC'},
        {name: '상품후기순', type: 'REVIEW.DESC'},
        {name: '등록일순', type: 'RECENT_PRODUCT.DESC'},
      ],
      selectedOrderType: 'RECENT_PRODUCT.DESC',
      productList : [],
      orderBy: '',
      pageSize: 20,
      page: 1,
      categoryList: [],
      more: true,
    }
  },
  async created() {
    this.currentCategory = this.$route.params.cate;
    this.setCategory();
  },
  methods: {
    init() {
      this.loading = true
      if (this.currentCategory !== 'all' && this.categoryNo === '') {
        this.loading = false;
        return;
      }
      let url = `/products/search?categoryNos=${this.categoryNo}&pageNumber=${this.page}&pageSize=${this.pageSize}&${this.orderBy}&hasTotalCount=true&filter.soldout=true&filter.saleStatus=RESERVATION_AND_ONSALE`;
      shopByHttp.get(url)
        .then(({data}) => {
          data.items.map(item => {
            if(item.isSoldOut) {
              item.productName += `<span style="color:#999">(품절)</span>`;
            }
          })
          this.productList = this.productList.concat(data.items);
          this.more = data.totalCount > this.productList.length;

        }).catch(err=>{
          console.log(err);
        }).finally(()=> {
          this.loading = false
        });
    },
    nextPage() {
      this.page++;
      this.init();
    },
    selectedOrder() {
      this.loading = true;
      this.page = 1;
      this.productList = [];
      this.setOrderBy();
      this.init();
    },
    setOrderBy() {
      let orderby = this.selectedOrderType.split('.'),
          order = orderby[0],
          dir = orderby[1];
      this.orderBy = `order.by=${order}&order.direction=${dir}`;
    },
    setCategory() {
      this.setOrderBy();
      this.page = 1;
      this.selectedOrderType = 'RECENT_PRODUCT.DESC';
      this.currentCategory = this.$route.params.cate;
      const current = this.category.find(x => x.id === this.currentCategory)
      this.categoryNo = current.no
      this.init()
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    comma (num) {
      return regExp.comma(num)
    },
  }
}

</script>
<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.productListWrap {
  position: relative;

  .saleFillter {
    position: absolute;
    top: -42px;
    right: 30px;

    select {
      font-size: $font-size-13;
      border: 0;
    }
    .customStyle {
      font-size: 10px;
      color: $gray-be;
    }
    &::after{
      content: '▾';
      color: $gray-be;
      font-size: $font-size-20;
      pointer-events: none;
      position: relative;
      top: 2px;
    }
  }
  .arr {
    margin: 0 4px;
    font-size: $font-size-18;
    font-weight: 400;
  }
  .productList{
    padding: 20px 30px;

    .productMoreInner {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-column-gap: 20px;

      .item {
        margin-bottom: 15px;
        padding: 0;

        a {
          display: block;
        }
        .titleSmall,
        .txteSmall {
          text-align: center;
        }
        p:last-child {
          padding-top: 6px;
        }
        .img {
          background-size: 100% 100% !important;

          .hidden {
            visibility: hidden;
          }
        }
      }
    }
    .customStyle {
      width: 100%;
      color: $gray-44;
    }

    .nodata {
      padding: 50px 0;
      text-align: center;
      font-size: 14px;

      .ic-nodata {
        display: block;
        margin-bottom: 10px;
        font-size: 64px;
        color: $gray-E5;
      }
    }
  }
}
</style>
