<!-- eslint-disable vue/html-indent -->
<template>
  <div class="notice">
    <div class="noticeTitleInner">
      <h3 class="titleMedium">공지사항</h3>
      <div class="more-next" v-if="isNotice"><router-link :to="{name: 'helpNotice'}">더보기</router-link></div>
    </div>
    <ul class="noticeInner" v-if="isNotice">
      <li v-for="item in resNotice" :key="item.categoryNo" class="noticeItem">
        <router-link :to="{name: 'noticeView', params: {id: item.articleNo}}">{{ item.title }}</router-link>
      </li>
    </ul>
    <div v-if="!isNotice || errorNotice" class="errorInfo">
      <i class="ic-nodata customStyle"></i> 
      <p>등록된 게시물이 없습니다</p>
    </div>
  </div>
</template>

<script>
import useFatchNotice from '@/composables/useFatchNotice.js'
import http from '@/api/shopBy';
import boardConf from '@/common/board'

// apis호출 메소드 모음.
const apis = {
  configNotice: () => http.get('boards/configurations'),
  notice: (boardInfo, options) => http.get(`/boards/${boardInfo}/articles?${options}`)
}
const BOARDNUMBER = boardConf.noticeNo; //공지사항 게시판 번호

export default {
  name: 'NoticeList-d511b6',
  setup() {
    const {resNotice, errorNotice, encodingNotice} = useFatchNotice();
    return {resNotice, errorNotice, encodingNotice}
  },
  computed: {
    isNotice () {
      return this.resNotice.length > 0
    },
  },
  mounted(){
    this.init();
  },
  methods: {
    async init() {
      const greenBanner = await apis.configNotice();
      greenBanner.data.boardConfigs.map(item => {
        if(item.boardNo === BOARDNUMBER){
          this.encodingNotice(apis.notice, BOARDNUMBER, {pageNumber: 1, pageSize: 5, direction: 'DESC'});
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.noticeTitleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.noticeInner {
  border-top: 1px solid $gray-DD;
  .noticeItem {
     a {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 5px;
        border-bottom: 1px solid $gray-DD;
        font-size: $font-size-14;
     }
  }
}
.errorInfo {
  text-align: center;
  padding: 60px 0;
  .customStyle {
    color: $gray-e5; 
    font-size: $font-size-64;
  }
  p{font-size: $font-size-14}
}
</style>