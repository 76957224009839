<template>
  <div class="periodSet">
    <p class="list">
      <a
          href="javascript:void(0)"
          v-for="item in period"
          :key="item.id"
          :class="{active: item.id === periodPick}"
          @click="periodSet(item.id)"
      >{{ item.name }}</a>
    </p>
    <div class="datePicker">
      <date-picker v-model="term.from" valueType="format"></date-picker>
      <span> ~ </span>
      <date-picker v-model="term.to" valueType="format"></date-picker>
      <button type="button" class="btn-border-small" @click="submit">검색</button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  name: "Filter-1s9a1a",
  components: {
    DatePicker,
  },
  data() {
    return {
      period: [
        { id: 'today', name: '오늘' },
        { id: 'week', name: '7일' },
        { id: 'fifteen', name: '15일' },
        { id: 'month', name: '1개월' },
        { id: 'three', name: '3개월' },
        { id: 'year', name: '1년' }
      ],
      periodPick: 'today',
      term: {},
    }
  },
  created() {
    const termFrom = dayjs().format('YYYY-MM-DD')
    const termTo = dayjs().format('YYYY-MM-DD')
    this.term = {
      from: termFrom,
      to: termTo
    }
  },
  methods: {
    periodSet(id) {
      this.periodPick = id
      switch (id) {
        case 'today':
          this.term.from = dayjs().format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
        case 'week':
          this.term.from = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
        case 'fifteen':
          this.term.from = dayjs().subtract(15, 'day').format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
        case 'month':
          this.term.from = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
        case 'three':
          this.term.from = dayjs().subtract(3, 'month').format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
        case 'year':
          this.term.from = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
          this.term.to = dayjs().format('YYYY-MM-DD')
          break
      }
    },
    submit() {
      this.$emit('submit', this.term)
    }
  }
}
</script>

<style scoped>

</style>