<template>
  <div class="contentsWrap">
    <!-- sort -->
    <DateFilter @submit="datePick"/>
    <!-- //sort -->

    <!-- 목록 -->
    <ul class="orderList">
      <li v-for="(item, index) in orderList" :key="index">
        <div class="orderItem">
          <div class="orderItemImg">
            <router-link
                :style="{'background': productThumb(item.imageUrl)}"
                :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                class="linkArea img"
            >
              <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
            </router-link>
          </div>
          <div class="orderItemInfo">
            <router-link :to="{name: 'orderDetail', params: {id: item.orderNo}}" class="orderTit">{{ item.productName }} <span>&gt;</span></router-link>
            <p class="orderNo">{{ item.orderNo }}</p>
            <p class="orderDate">{{ item.orderYmdt }}</p>
            <p class="orderState">
              <span>{{ comma(item.price) }}원</span>
              <span>{{ item.payTypeLabel }}</span>
              <span v-if="item.claimStatusTypeLabel">{{ item.claimStatusTypeLabel }}</span>
              <span v-else>{{ item.orderStatusTypeLabel }}</span>
            </p>
          </div>
        </div>
        <div class="orderItemCsBtn">
          <p class="top">
            <a :href="item.viewDeliveryUri"
               class="btn-bgGreen-03 w100"
               v-if="item.nextActions.indexOf('VIEW_DELIVERY') > -1"
               target="_blank">
               <i class="ic-delivery customStyle"></i>
              배송조회
            </a>
            <!--
            <a href="javascript:void(0)" class="btn-bgGreen-04"
               v-if="checkShippingAddr(item.orderStatusType)"
               @click="layer('addressSet', item.orderNo)">
               <i class="ic-delivery customStyle"></i>
               배송지변경
            </a>
            -->
            <a href="javascript:void(0)" class="btn-bgGreen-03"
               v-if="item.nextActions.includes('CANCEL')"
               @click="layer('cancel', item.orderNo)">
               <i class="ic-cancel customStyle"></i>
               구매취소
            </a>
            <a href="javascript:void(0)" class="btn-bgGray-99"
               v-if="item.nextActions.includes('RETURN')"
               @click="layer('return', item.orderNo)">
               <i class="ic-return customStyle"></i>
               반품신청
            </a>
            <!--
            <a href="javascript:void(0)" class="btn-bgGray-99"
               v-if="item.nextActions.includes('EXCHANGE')"
               @click="layer('change', {orderNo: item.orderNo, productNo: item.productNo, optionNo: item.orderOptionNo})">
               <i class="ic-change customStyle"></i>
               교환신청
            </a>
            -->
          </p>
        </div>
      </li>
    </ul>
    <!-- //목록 -->

    <!-- 공지사항 -->
    <Notice />
    <!-- //공지사항 -->

    <!-- 구매취소 팝업 -->
    <CancelLayer
        v-if="cancelLayer"
        :orderNo="claim.orderNo"
        @confirm="layer('cancel')"
        @close="layer('cancel', null, true)"
    />
    <!-- //구매취소 팝업 -->

    <!-- 배송지변경 팝업 -->
    <!--
    <AddressSetLayer
        v-if="addressSetLayer"
        :orderNo="claim.orderNo"
        @confirm="layer('addressSet')"
        @close="layer('addressSet', null, true)"
    />
    -->
    <!-- //배송지변경 팝업 -->

    <!-- 반품신청 팝업 -->
    <ReturnLayer
        v-if="returnLayer"
        :orderNo="claim.orderNo"
        @confirm="layer('return')"
        @close="layer('return', null, true)"
    />
    <!-- //반품신청 팝업 -->

    <!-- 교환신청 팝업 -->
    <!--
    <ChangeLayer
        v-if="changeLayer"
        :orderNo="claim.orderNo"
        :productNo="claim.productNo"
        :optionNo="claim.optionNo"
        @confirm="layer('change')"
        @close="layer('change', null, true)"
    />
    -->
    <!-- //교환신청 팝업 -->

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import DateFilter from '@/components/Order/Filter'
import Notice from '@/components/Common/Noticelist.vue'
import CancelLayer from "@/components/Order/CancelLayer.vue";
// import AddressSetLayer from "@/components/Order/AddressSetLayer.vue";
import ReturnLayer from "@/components/Order/ReturnLayer.vue";
//import ChangeLayer from "@/components/Order/ChangeLayer.vue";
import shopByHttp from "@/api/shopBy"
import dayjs from "dayjs"
import regExp from "@/lib/regExp";
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: 'List-be28ec',
  components: {
    DateFilter,
    Notice,
    CancelLayer,
    // AddressSetLayer,
    ReturnLayer,
    //ChangeLayer,
    LoadingCont
  },
  data() {
    return {
      loading: true,
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      orderList: [],
      claim: {},
      cancelLayer: false,
      addressSetLayer: false,
      returnLayer: false,
      changeLayer: false,
    }
  },
  created() {
    this.getOrderList()
  },
  methods: {
    getOrderList() {
      const orderTypeArr = ['DEPOSIT_WAIT','PAY_DONE','PRODUCT_PREPARE','DELIVERY_PREPARE','DELIVERY_ING','DELIVERY_DONE','BUY_CONFIRM']
      const orderType = orderTypeArr.join(',')
      let query = `?startYmd=${this.startDate}&endYmd=${this.endDate}&orderRequestTypes=${orderType}`;
      shopByHttp.get(`/profile/orders${query}`)
        .then(({ data }) => {

          this.orderList = data.items.flatMap(items => this.makeOrderProductInfo(items));
          if(this.orderList.length < 1) {
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    makeOrderProductInfo(items) {
      let res = items.orderOptions.flatMap(item => {
        let nextActions = [];
        item.nextActions.forEach(action => {
          nextActions.push(action.nextActionType)
          if(action.nextActionType === 'VIEW_DELIVERY') {
            item.viewDelivery = action.uri
          }
        })
        return {
          orderNo: items.orderNo,
          productNo: item.productNo,
          optionName: item.optionName,
          orderOptionNo: item.orderOptionNo,
          imageUrl: item.imageUrl,
          productName: item.productName,
          orderCnt: item.orderCnt,
          price: item.price.buyPrice,
          payTypeLabel: items.payTypeLabel,
          orderStatusType: item.orderStatusType,
          orderStatusTypeLabel: item.orderStatusTypeLabel,
          claimStatusTypeLabel: item.claimStatusTypeLabel,
          orderYmdt: dayjs(items.orderYmdt).format('YYYY년 MM월 DD일'),
          nextActions: nextActions,
          viewDeliveryUri: item.viewDelivery,
        }
      })
      this.loading = false
      return res;
    },
    checkShippingAddr (orderStatus) {
      let passStatus = ['DEPOSIT_WAIT', 'PAY_DONE', 'PRODUCT_PREPARE', 'DELIVERY_PREPARE'];
      return passStatus.indexOf(orderStatus) > -1;
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    comma (num) {
      return regExp.comma(num)
    },
    datePick(date) {
      this.loading = true
      this.startDate = date.from
      this.endDate = date.to
      this.getOrderList()
    },
    layer(cont, orderNo, close) {
      this.claim = {};
      if(cont === 'change') {
        this.claim = orderNo
      } else {
        this.claim.orderNo = orderNo
      }

      switch (cont) {
        case 'cancel':
          this.cancelLayer = !this.cancelLayer
          break;
        case 'addressSet':
          this.addressSetLayer = !this.addressSetLayer
          break;
        case 'return':
          this.returnLayer = !this.returnLayer
          break;
        case 'change':
          this.changeLayer = !this.changeLayer
          break;
      }
      if(close) {
        this.getOrderList()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.orderList {
  margin-bottom: 50px;
}
.customStyle{
  font-size: 18px;
  margin-right: 10px;
}
</style>
