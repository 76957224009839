<template>
  <div>

    <!-- 결제완료 -->
    <div class="orderCompleteBox" v-if="result === 'SUCCESS'">
      <i class="ic-check c-green03"></i>
      <h2>결제완료</h2>
      <p>
        주문 요청 건에 대한 결제 완료되었습니다.<br>
        주문내역 통해 주문내용 확인이 가능합니다.
      </p>
      <div class="btnBox">
        <router-link class="btn-bgGreen-03 w100" :to="'/order/list'">주문내역</router-link>
      </div>
    </div>
    <!-- //결제완료 -->

    <!-- 결제실패 -->
    <div class="orderCompleteBox" v-else>
      <i class="ic-nodata"></i>
      <h2>결제실패</h2>
      <p>
        주문 요청 건에 대한 결제 실패하였습니다.<br><br>
        {{ message }}
      </p>
      <div class="btnBox">
        <router-link class="btn-bgGreen-03 w100" :to="'/order/form/'+orderSheetNo">주문페이지 이동</router-link>
      </div>
    </div>
    <!-- //결제실패 -->

  </div>
</template>

<script>

export default {
  name: "Complete-gi388ae",
  data() {
    return {
      result: '',
      orderNo: '',
      message: '',
      isApp: false
    };
  },
  created() {
    this.result = this.$route.query.result;
    this.orderNo = this.$route.query.orderNo;
    this.message = this.$route.query.message;
    this.orderSheetNo = this.$route.query.orderSheetNo;

  }
}
</script>

<style scoped>

</style>
