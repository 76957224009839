<template>
  <div class="header">
    <div class="headerMenu">
      <ul class="headerInner">
        <li>
          <ul class="infoInner">
            <li @click="goMypage"><i class="ic-mypage"></i></li>
            <li @click="goCart"><i class="ic-cart"></i></li>
          </ul>
        </li>
        <li @click="goMain" class="logo"><img src="@/assets/images/common/logo-header.png" alt="GREENSTORE" /></li>
        <li @click="menuToggle" :class="{'menuActive': toggleMenu}"><i class="ic-menu"></i></li>
        <li @click="menuToggle" :class="{'menuActive': !toggleMenu}"><i class="ic-close_i"></i></li>
      </ul>
      <Gnb :class="{active: toggleMenu}" :toggleMenu="toggleMenu" :menu="menu" @close="menuToggle"/>
    </div>
  </div>
</template>

<script>
import Gnb from '@/components/Gnb/index.vue';
import MENU from '@/common/menu.js';
import store from "@/store";

export default {
  name: 'Header-85f69a',
  components: {
    Gnb
  },
  computed: {
    category () {
      return this.$store.getters['category']
    }
  },
  watch: {
    category() {
      this.setMenu()
    },
  },
  data(){
    return {
      toggleMenu: false,
      menu: []
    }
  },
  created(){
    this.menu = MENU;
    this.setMenu()
  },
  methods: {
    setMenu() {
      let child = [];
      this.category.forEach(el=>{
        child.push({
          name: el.id,
          title: el.name,
          link: el.link
        })
      })

      this.menu.forEach(el=>{
        if(el.name === 'category'){
          el.children = child;
        }
      })
    },
    menuToggle() {
      this.toggleMenu = !this.toggleMenu;
      document.body.classList.toggle('overflowBody');
    },
    goMypage(){
      this.toggleMenu = false;
      if(!store.state.login){
        this.$router.push({name: 'signin'})
      } else {
        this.$router.push({name: 'orderList'})
      }
    },
    goCart() {
      this.toggleMenu = false;
      if(!store.state.login){
        this.$router.push({name: 'signin'})
      } else {
        this.$router.push({name: 'cartList'})
      }
    },
    goMain(){
      this.toggleMenu = false;
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/constants/breakpoints.scss';
  @import '@/assets/styles/constants/colors.scss';
  .header {width: 100%;position: relative;}
  .headerMenu {
    width: 100%;
    max-width: 520px;
    position: fixed;
    z-index: 10;
    margin: 0 auto;
    .menuActive {
      display: none;
    }
  }
  .headerInner {
    margin: 0 auto;
    height: 70px;
    display: flex;
    background: $green-03 url('@/assets/images/main/bg_header.png') right center no-repeat;
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    color: $white;
    font-size: 25px;
    li {cursor: pointer;}
    .logo {width: 120px; margin-left: -30px;}
  }
  .infoInner {
    display: flex;
    li:last-child {margin-left: 10px;}
  }
</style>