import Index from '@/views/Help.vue'
import Notice from '@/components/Help/Notice.vue'
import View from '@/components/Help/View.vue'
import Inquiry from '@/components/Help/Inquiry.vue'
import InquiryForm from '@/components/Help/InquiryForm.vue'
import InquiryView from '@/components/Help/InquiryView.vue'

const help = [
    {
        path: '/help',
        name: 'help',
        component: Index,
        redirect: {
            name: 'helpNotice'
        },
        children: [
            {
                path: 'notice',
                name: 'helpNotice',
                meta: {
                    role: 'list',
                    subName: 'notice'
                },
                component: Notice
            },
            {
                path: 'notice/:id',
                name: 'noticeView',
                meta: {
                    role: 'view',
                    noSubTop: true // 서브상단 없는 경우
                },
                component: View,
                props: true,
                beforeEnter: (to, from, next) => {
                    if (to) {
                        to.meta.historyBack = from.path;
                    }
                    next()
                }
            },
            {
                path: 'inquiry',
                name: 'helpInquiry',
                meta: {
                    role: 'list',
                    subName: 'inquiry'
                },
                component: Inquiry
            },
            {
                path: 'inquiry/form/:inquiryNo',
                name: 'inquiryForm',
                meta: {
                    role: 'view',
                    noSubTop: true
                },
                component: InquiryForm,
                beforeEnter: (to, from, next) => {
                    if (to) {
                        to.meta.historyBack = from.path;
                    }
                    next()
                }
            },
            {
                path: 'inquiry/:id',
                name: 'inquiryView',
                meta: {
                    role: 'view',
                    noSubTop: true // 서브상단 없는 경우
                },
                component: InquiryView,
                props: true,
                beforeEnter: (to, from, next) => {
                    if (to) {
                        to.meta.historyBack = from.path;
                    }
                    next()
                }
            },
        ]
    },

]

export default help

