<template>
  <div class="contentsWrap">
    <!-- 뒤로가기 & 목록 -->
    <div class="backMenu">
      <router-link :to="historyBack" class="back"><img src="@/assets/images/common/ico_back.png" alt="뒤로가기"/></router-link>
      <router-link :to="{name: 'helpInquiry'}" class="btn-border-small list">목록</router-link>
    </div>
    <!-- //뒤로가기 & 목록 -->


    <div class="formWrap pdTop0">
      <div class="topLine pdTop40">
        <div class="row optionItem customStyle">
          <label class="label">문의유형</label>
          <select class="pick" v-model="form.inquiryTypeNo" :disabled="formState === 'update'">
            <option value="">선택하세요</option>
            <option
                v-for="item in inquiryType"
                :key="item.inquiryTypeNo"
                :value="item.inquiryTypeNo">
              {{ item.inquiryTypeName }}
            </option>
          </select>
          <i class="ic-arrow-down selectDown"></i>
        </div>
        <div class="row">
          <label class="label" for="title">제목</label>
          <input type="text" placeholder="제목을 입력하세요" id="title" class="form-control w100" v-model="form.inquiryTitle"/>
        </div>
        <div class="row">
          <label class="label">문의내용</label>
          <textarea placeholder="내용을 입력하세요" class="form-control textarea w100" v-model="form.inquiryContent"></textarea>
        </div>
        <div class="row">
          <label class="label">첨부파일</label>
          <div class="file">
            <input type="text" placeholder="첨부파일을 등록하세요" class="form-control" v-model="fileName" readonly/>
            <input type="file" id="file" class="hidden" accept="image/*" @change="setFile"/>
            <label for="file" class="btn-border">첨부파일 등록</label>
            <a href="javascript:void(0)" class="resetFile" @click="resetFile" v-if="isNaN(Number(this.inquiryNo))">첨부파일 삭제</a>
          </div>
        </div>
      </div>
      <a href="javascript:void(0)" class="btn-bgGreen-03 w100" @click="submit">
        {{ btnTitle }}
      </a>
    </div>

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import regExp from '@/lib/regExp'
import http from '@/api/shopBy'
import dayjs from 'dayjs'
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "InquiryForm-1f1c5e",
  props: ['depth1', 'depth2'],
  components: {
    LoadingCont,
  },
  computed: {
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
  },
  data() {
    return {
      loading: true,
      inquiryNo: this.$route.params.inquiryNo,
      inquiryType: [],
      form: {
        inquiryTypeNo: '',
        inquiryTitle: '',
        inquiryContent: '',
        originalFileName: [], //원본 파일명
        uploadedFileName: [], //업로드 된 파일명
        //productNo: null,
        //orderNo: null,
        answerSmsSendYn: false,
        answerEmailSendYn: false,
        //email: ''
      },
      inquiryDate: '',
      formState: 'create',
      btnTitle: '1:1문의 작성하기',
      fileName: '',
    }
  },
  created() {
    this.init()
    if(this.inquiryNo === 'form') {
      this.formState = 'create'
      this.loading = false
    } else if(!isNaN(Number(this.inquiryNo))) {
      this.formState = 'update'
      this.btnTitle = '1:1문의 수정하기'
      this.getInquiry()
    } else {
      alert('잘못된 접근입니다.')
      this.$router.push({name: 'helpInquiry'})
    }
  },
  methods: {
    init() {
      http.get(`malls`)
        .then(({data}) => {
          this.inquiryType = data.inquiryType
        })
        .catch(err => {
          console.log(err);
        }).finally(() => {
          // this.loading = false
        })
    },
    getInquiry() {
      http.get(`/inquiries/${this.inquiryNo}`)
        .then(({data}) => {
          this.form.inquiryTitle = data.inquiryTitle
          this.form.inquiryContent = data.inquiryContent
          this.form.inquiryTypeNo = data.inquiryType.inquiryTypeNo
          this.inquiryDate = dayjs(data.registerYmdt).format('YYYY-MM-DD')
          this.form.originalFileName = data.originalImageUrls[0]
          this.form.uploadedFileName = data.imageUrls[0]
          this.fileName = data.originalImageUrls[0]
        })
        .catch(err => {
          console.log(err);
        }).finally(() => {
          this.loading = false
        })

    },
    submit() {
      if(!this.validation()) return

      this.loading = true
      if(this.formState === 'create') {
        this.createInquiry()
      } else if(this.formState === 'update') {
        this.updateInquiry()
      }
    },
    validation() {
      if(this.form.inquiryTypeNo === '') {
        alert('문의유형을 선택해주세요.')
        return false
      }
      if(regExp.replaceAllEmpty(this.form.inquiryTitle) === '') {
        alert('제목을 입력해주세요.')
        return false
      }
      if(regExp.replaceAllEmpty(this.form.inquiryContent) === '') {
        alert('내용을 입력해주세요.')
        return false
      }

      return true
    },
    createInquiry() {
      http.post(`/inquiries`, this.form)
        .then(() => {
          alert('1:1문의가 등록되었습니다.')
          this.$router.push({name: 'helpInquiry'})
        })
        .catch(err => {
          console.log(err);
          alert('1:1문의 등록에 실패하였습니다.');
        }).finally(() => {
          this.loading = false
        })
    },
    updateInquiry() {
      http.put(`/inquiries/${this.inquiryNo}`, this.form)
        .then(() => {
          alert('1:1문의가 수정되었습니다.')
        })
        .catch(err => {
          console.log(err);
          alert('1:1문의 수정에 실패하였습니다.');
        }).finally(() => {
          this.loading = false
        })
    },
    setFile(e) {
      const file = e.target.files[0];
      // if (file.size > 1024 * 1024 * 12) {
      //   alert('파일은 용량은 12MB 이하만 가능합니다.');
      //   return;
      // }

      const formData = new FormData()
      formData.append('file', file)
      http.post(`/files/images`, formData).then(res => {
        this.form.originalFileName = [res.data.originName];
        this.form.uploadedFileName = [res.data.imageUrl];
        this.fileName = res.data.originName
      }).catch(err => {
        alert(err.response.data.message)
      })
    },
    resetFile() {
      if(confirm('첨부파일을 삭제하시겠습니까?')) {
        this.fileName = '';
        this.form.originalFileName = [];
        this.form.uploadedFileName = [];
      }
    },
    comma(num) {
      return regExp.comma(num)
    },
  }
}
</script>

<style lang="scss" scoped>
.resetFile {
  color: #046A38;
  text-decoration: underline;
}
</style>