<template>
  <div class="contentsWrap">
    <!-- sort -->
    <DateFilter @submit="datePick"/>
    <!-- //sort -->

    <!-- 목록 -->
    <ul class="orderList">
      <li v-for="(item, index) in csList" :key="index">
        <div class="orderItem">
          <div class="orderItemImg">
            <router-link
                :style="{'background': productThumb(item.imageUrl)}"
                :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                class="linkArea img"
            >
              <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
            </router-link>
          </div>
          <div class="orderItemInfo">
            <router-link :to="{name: 'orderDetail', params: {id: item.orderNo}}" class="orderTit">{{ item.productName }} <span>&gt;</span></router-link>
            <p class="orderNo">{{ item.orderNo }}</p>
            <p class="orderDate">{{ item.orderYmdt }}</p>
            <p class="orderState">
              <span>{{ comma(item.price) }}원</span>
              <span v-if="item.claimStatusTypeLabel">{{ item.claimStatusTypeLabel }}</span>
              <span v-else>{{ item.orderStatusTypeLabel }}</span>
            </p>
          </div>
        </div>
        <div class="orderItemCsBtn">
          <p v-if="item.claimStatusType && item.claimStatusType.indexOf('CANCEL') !== -1">
            <router-link :to="{name: 'csDetail', params: {id: item.claimNo}, query: {orderNo: item.orderNo}}" class="btn-bgGray-99">
              <i class="ic-change styleCuscom"></i>취소정보
            </router-link>
            <a v-if="item.nextActions.indexOf('WITHDRAW_CANCEL') !== -1" class="btn-bgGray-99" @click="checkWithdraw('cancel', item.claimNo)">
              <i class="ic-cancel styleCuscom"></i>취소철회
            </a>
          </p>
          <p v-if="item.claimStatusType && item.claimStatusType.indexOf('RETURN') !== -1">
            <router-link :to="{name: 'csDetail', params: {id: item.claimNo}, query: {orderNo: item.orderNo}}" class="btn-bgGray-99">
              <i class="ic-return styleCuscom"></i>반품정보
            </router-link>
            <a v-if="item.nextActions.indexOf('WITHDRAW_RETURN') !== -1" class="btn-bgGray-99" @click="checkWithdraw('return', item.claimNo)">
              <i class="ic-cancel styleCuscom"></i>반품철회
            </a >
          </p>
          <p v-if="item.claimStatusType && item.claimStatusType.indexOf('EXCHANGE') !== -1">
            <router-link :to="{name: 'csDetail', params: {id: item.claimNo}, query: {orderNo: item.orderNo}}" class="btn-bgGray-99">
              <i class="ic-change styleCuscom"></i>교환정보
            </router-link>
            <!--
            <a v-if="item.nextActions.indexOf('WITHDRAW_EXCHANGE') !== -1" class="btn-bgGray-99" @click="checkWithdraw('exchange', item.claimNo)">
              <i class="ic-cancel styleCuscom"></i>교환철회
            </a>
            -->
          </p>
        </div>
      </li>
    </ul>
    <!-- //목록 -->

    <!-- 공지사항 -->
    <Notice />
    <!-- //공지사항 -->

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import DateFilter from '@/components/Order/Filter';
import Notice from '@/components/Common/Noticelist.vue';
import shopByHttp from "@/api/shopBy";
import regExp from "@/lib/regExp";
import dayjs from "dayjs";
import LoadingCont from "@/components/Common/Loading.vue";

export default {
  name: 'cancel-b593ab',
  components: {
    DateFilter,
    Notice,
    LoadingCont
  },
  data() {
    return {
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      csList: [],
      loading: true
    }
  },
  created(){
    this.getCsList();
  },
  methods: {
    getCsList() {
      // CANCEL_DONE: 취소완료, RETURN_DONE: 반품완료, EXCHANGE_DONE: 교환완료, CANCEL_PROCESSING: 취소처리중, RETURN_PROCESSING: 반품처리중, EXCHANGE_WAITING: 교환대기중, EXCHANGE_PROCESSING: 교환처리중
      // const orderTypeArr =
      // const orderType = orderTypeArr.join(',')
      let query = `?startYmd=${this.startDate}&endYmd=${this.endDate}`;
      shopByHttp.get(`/profile/claims${query}`)
        .then(({ data }) => {
          this.csList = data.items.flatMap(items => this.makeOrderProductInfo(items));
          this.csList = this.csList.filter(item => item !== undefined);
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    makeOrderProductInfo(items) {
      return items.claimedOptions.flatMap(item => {
        if(!item.claimStatusType) return;
        let nextActions = [];
        item.nextActions.forEach(action => {
          nextActions.push(action.nextActionType)
          if(action.nextActionType === 'VIEW_DELIVERY') {
            item.viewDelivery = action.uri
          }
          if(item.nextActions.length > 1){ //다음 상태(nextActions)에 따른 버튼 UI 변경.
            item.active = true;
          } else {
            item.active = false;
          }                               //다음 상태(nextActions)에 따른 버튼 UI 변경.
        })
        return {
          orderNo: items.orderNo,
          productNo: item.productNo,
          optionName: item.optionName,
          imageUrl: item.imageUrl,
          productName: item.productName,
          orderCnt: item.orderCnt,
          price: item.price.buyPrice,
          payTypeLabel: items.payTypeLabel,
          orderStatusType: item.orderStatusType,
          orderStatusTypeLabel: item.orderStatusTypeLabel,
          claimStatusTypeLabel: item.claimStatusTypeLabel,
          claimStatusType: item.claimStatusType,
          orderYmdt: dayjs(items.orderYmdt).format('YYYY년 MM월 DD일'),
          nextActions: nextActions,
          viewDeliveryUri: item.viewDelivery,
          claimNo: item.claimNo,
          active: item.active,
        }
      })
    },
    checkWithdraw (type, claimNo) {
      switch (type) {
        case 'cancel':
          type = '취소'
          break;
        case 'return':
          type = '반품'
          break;
        case 'exchange':
          type = '교환'
          break;
      }
      if(confirm(`${type} 신청을 철회하시겠습니까?`)) {
        this.loading = true
        shopByHttp.get(`/profile/claims/${claimNo}/check-withdraw`)
            .then(({ data }) => {
              if(data.validationType === 'WITHDRAWABLE') {
                this.withdraw(claimNo)
              } else {
                this.loading = false
                alert('철회 불가능한 상태입니다.')
              }
            })
            .catch(err => {
              console.log(err)
            })
      }
    },
    withdraw (claimNo) {
      shopByHttp.put(`/profile/claims/${claimNo}/withdraw`)
          .then(() => {
            alert('철회되었습니다.');
            this.getCsList()
          })
          .catch(err => {
            console.log(err)
            alert(err.response.data.message)
          }).finally(() => {
            this.loading = false
          })
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    comma (num) {
      return regExp.comma(num)
    },
    datePick(date) {
      console.log(date.from, date.to);
      this.startDate = date.from
      this.endDate = date.to
      this.getCsList()
    },
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  margin-bottom: 50px;
  li {
    .orderItemCsBtn p.hide {display: none;}
    .orderItemCsBtn{
      .styleCuscom{
        font-size: 18px;
        margin-right: 10px;
      }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: initial;
        a {width: 48%;}
      }
    }
  }
}
</style>
