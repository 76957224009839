<template>
  <div class="contentsWrap">
    <!-- 뒤로가기 -->
    <div class="backBtnCont">
      <router-link :to="historyBack">
        <img src="@/assets/images/common/ico_back.png" alt="뒤로가기">
      </router-link>
      <h2>주문서 내용</h2>
    </div>
    <!-- //뒤로가기 -->

    <!-- 주문 내용 -->
    <ul class="orderList">
      <li>
        <div class="orderItem noImg">
          <div class="orderItemInfo">
            <p class="orderTit">{{ orderInfo.orderTitle }}</p>
            <p class="orderNo">{{ orderInfo.orderNo }}</p>
            <p class="orderDate">{{ orderInfo.orderDate }}</p>
            <p class="orderState">
              <span>{{ comma(orderInfo.price) }}원</span>
              <span>{{ orderInfo.payType }}</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- //주문 내용 -->

    <!-- 주문 상품 -->
    <div>
      <h3 class="headTxt_3">주문 상품</h3>
      <ul class="orderList line">
        <li v-for="(item, index) in orderList" :key="index">
          <div class="orderItem">
            <div class="orderItemImg">
              <router-link
                  :style="{'background': productThumb(item.imageUrl)}"
                  :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                  class="linkArea img"
              >
                <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
              </router-link>
            </div>
            <!-- 상품 옵션(optionUsed) -->
            <div class="orderItemInfo">
              <p class="itemName">{{ item.productName }}</p>
              <div v-if="item.optionUsed">
                <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                  {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                </p>
                <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                  {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                </p>
              </div>
              <p>수량 : {{ comma(item.orderCnt) }}개</p>
              <p>가격 : {{ comma(item.price) }}원</p>
              <p>상태 : {{ item.orderStatusTypeLabel }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- //주문 상품 -->

    <!-- 주문자 정보 -->
    <div>
      <h3 class="headTxt_3">주문자 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">이름</div>
          <div class="content">{{ order.orderer.ordererName }}</div>
        </li>
        <li class="row flex">
          <div class="label">휴대폰번호</div>
          <div class="content">{{ order.orderer.ordererContact1 }}</div>
        </li>
        <li class="row flex">
          <div class="label">연락처</div>
          <div class="content">{{ order.orderer.ordererContact2 }}</div>
        </li>
        <li class="row">
          <div class="label">주문 메모</div>
          <div class="content">
            {{ order.orderMemo }}
          </div>
        </li>
      </ul>
    </div>
    <!-- //주문자 정보 -->

    <!-- 배송 정보 -->
    <div>
      <h3 class="headTxt_3">배송 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">이름</div>
          <div class="content">{{ shippingInfo.receiverName }}</div>
        </li>
        <li class="row flex">
          <div class="label">휴대폰번호</div>
          <div class="content">{{ shippingInfo.receiverContact1 }}</div>
        </li>
        <li class="row flex">
          <div class="label">연락처</div>
          <div class="content">{{ shippingInfo.receiverContact2 }}</div>
        </li>
        <li class="row">
          <div class="label">주소</div>
          <div class="content">({{ shippingInfo.receiverZipCd }}) {{ shippingInfo.receiverJibunAddress }}</div>
        </li>
        <li class="row">
          <div class="label">배송 메모</div>
          <div class="content">
            {{ order.deliveryMemo }}
          </div>
        </li>
      </ul>
    </div>
    <!-- //배송 정보 -->

    <!-- 결제 정보 -->
    <div>
      <h3 class="headTxt_3">결제 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">총 상품금액</div>
          <div class="content">{{ comma(payInfo.standardAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">총 할인금액</div>
          <div class="content">{{ comma(payInfo.totalDiscountAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">배송비</div>
          <div class="content">{{ comma(payInfo.totalDeliveryAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">쿠폰 사용</div>
          <div class="content">{{ comma(payInfo.totalCouponAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">포인트 사용</div>
          <div class="content">{{ comma(payInfo.subPayAmt) }}포인트</div>
        </li>
        <li class="row flex">
          <div class="label">최종 결제 금액</div>
          <div class="content">{{ comma(payInfo.chargeAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">결제 수단</div>
          <div class="content">{{ order.payTypeLabel }}</div>
        </li>
      </ul>
    </div>
    <!-- //배송 정보 -->

    <!-- 공지사항 -->
    <Notice class="mgTop50"/>
    <!-- //공지사항 -->

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import Notice from '@/components/Common/Noticelist.vue'
import http from '@/api/shopBy'
import regExp from "@/lib/regExp";
import dayjs from "dayjs";
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: 'detail-1cf3d9',
  components: {
    Notice,
    LoadingCont
  },
  computed: {
    detailId() {
      return this.$route.params.id
    },
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
  },
  data() {
    return {
      loading: true,
      order: {
        orderer: {
          ordererName: '',
          ordererContact1: '',
          ordererContact2: '',
        }
      },
      orderInfo: {},
      orderList: [],
      payInfo: {},
      refundInfos: {},
      shippingInfo: {},
    }
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      http.get(`/profile/orders/${this.detailId}`)
        .then(({data}) => {
          this.order = data;
          this.getOrderProduct() // 주문상품

          // 주문정보
          let orderTit = regExp.truncate(this.orderList[0].productName, 25)
          if(this.orderList.length > 1) {
            orderTit += ` 외 ${this.orderList.length - 1}건`
          }
          this.orderInfo = {
            orderTitle: orderTit,
            orderNo: data.orderNo,
            orderDate: dayjs(data.orderTmdt).format('YYYY년 MM월 DD일 HH:mm'),
            price: data.lastOrderAmount.chargeAmt,
            payType: data.payTypeLabel,
          }

          this.payInfo = data.lastOrderAmount; // 결제정보
          // 총할인금액 = 추가할인금액 + 즉시할인금액 + 장바구니쿠폰할인금액 + 상품쿠폰할인금액
          this.payInfo.totalDiscountAmt = this.payInfo.additionalDiscountAmt + this.payInfo.immediateDiscountAmt + this.payInfo.cartCouponDiscountAmt + this.payInfo.productCouponDiscountAmt;
          // 총배송비 = 배송비 + 추가배송비
          this.payInfo.totalDeliveryAmt = this.payInfo.deliveryAmt + this.payInfo.remoteDeliveryAmt;
          // 총쿠폰할인금액
          this.payInfo.totalCouponAmt = this.payInfo.cartCouponDiscountAmt + this.payInfo.productCouponDiscountAmt;

          this.shippingInfo = data.shippingAddress

        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getOrderProduct() {
      const groupByDelivery = this.order.orderOptionsGroupByPartner[0].orderOptionsGroupByDelivery
      this.orderList = groupByDelivery.flatMap(group => this.makeOrderProductInfo(group));
      this.loading = false
    },
    makeOrderProductInfo(group) {
      return group.orderOptions.flatMap(item => {
        console.log(item);
        return {
          orderNo: group.orderNo,
          productNo: item.productNo,
          orderOptionNo: item.orderOptionNo,
          productName: item.productName,
          imageUrl: item.imageUrl,
          optionUsed: item.optionUsed,
          optionName: item.optionName,
          optionValue: item.optionValue,
          optionInputs: item.inputs,
          orderCnt: item.orderCnt,
          price: item.price.buyPrice,
          payTypeLabel: group.payTypeLabel,
          orderStatusType: item.orderStatusType,
          orderStatusTypeLabel: item.orderStatusTypeLabel,
          orderYmdt: dayjs(group.orderYmdt).format('YYYY년 MM월 DD일')
        }
      })
    },
    comma (num) {
      return regExp.comma(num)
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
  }
}
</script>

<style lang="scss" scoped>
  .contentsWrap {
    margin-bottom: 30px;
  }
</style>
