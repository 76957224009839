<template>
  <div class="ProductSlider" v-if="mainProduct.length !== 0">
    <div class="productTileInner">
      <h3 class="titleMedium">주요상품</h3>
      <div class="more-next"><router-link :to="{name: 'categoryList', params: {cate: 'all'}}">더보기</router-link></div>
    </div>
    <swiper class="swiper main_product" :options="mainProductOption">
      <swiper-slide v-for="(item, idx) in mainProduct" :key="idx" class="swiper-slide">
        <router-link :to="{name: 'categoryDetail', params: {productNo: item.productNo}}">
          <img :src="item.imageUrl" alt="배너 이미지" />
          <p class="titleSmall" v-html="item.productName"></p>
          <p class="titleSmall">{{ item.salePrice }}</p>
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import http from '@/api/shopBy';
import regExp from '@/lib/regExp.js';
import 'swiper/css/swiper.css'

// apis호출 메소드 모음.
const apis = {
  products: () => http.get('/products/search?order.by=MD_RECOMMEND&order.direction=ASC&pageSize=10&filter.soldout=true')
}

export default {
  name: 'Product-f746ec',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      mainProduct: [],
      mainProductOption: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 31,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    async init(){
      const productsData = await apis.products();
      productsData.data.items.map(item => {
        let obj = {
          imageUrl: item.listImageUrls[0],
          productNo: item.productNo,
          productName: item.productName,
          salePrice: regExp.comma(regExp.numberOnly(item.immediateDiscountAmt - item.salePrice))
        }
        if(item.isSoldOut) {
          obj.productName += `<span style="color:#999">(품절)</span>`;
        }
        this.mainProduct.push(obj);
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.ProductSlider {
  padding-bottom: 30px;
}
.productTileInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper {
  width: 100%;
  //height: 55vh;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.ProductSlider {
  .swiper-slide img {
    display: block;
    width: 100%;
    //height: 60%;
    //object-fit: cover;
  }
}
.swiper-pagination {
  width: 40px;
  border-radius: 15px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-size: $font-size-13;
  padding: 2px 6px;
  box-sizing: border-box;
}
.swiper-container {
  overflow:visible;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #034F36;
}
.main_product {
  //height: 260px;
  width: 100%;
  max-height: 300px;
  .titleSmall {
    text-align: center;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    img {
      padding-bottom: 10px;
    }
  }
  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: -30px;
    left: 50%;
    background-color: transparent;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
