<template>
  <div class="subTop">
    <!-- 서브 상단 -->
    <div class="topTitle">
      <p class="cate">{{ depth1.name }}</p>
      <p class="subCate">{{ depth2.name }}</p>
    </div>
    <!-- //서브 상단 -->
  </div>
</template>

<script>
export default {
  name: "Top-b520f6",
  props: ['depth1', 'depth2'],
  created() {
    const imgSrc = 'https://i.imgur.com/bkmKAyw.png'
    this.bg = `url(${imgSrc})`
  },
  data() {
    return {
      bg: '',

    }
  }
}
</script>

<style scoped>

</style>