<template>
  <div class="layerWrap">
    <div class="in">

      <div class="layerCont">
        <!-- head -->
        <div class="top">
          <a href="javascript:void(0)" class="close" @click="close"><img src="@/assets/images/common/ico_close.png" alt="닫기" /></a>
          반품신청
        </div>
        <!-- //head -->

        <!-- contents -->
        <div class="contents form mgTop20">
          <!-- 상품 -->
          <ul class="orderList line checkbox">
            <li v-for="(item, index) in orderList" :key="index">
              <div class="orderItem">
                <div class="orderItemImg">
                  <router-link
                      :style="{'background': productThumb(item.imageUrl)}"
                      :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                      class="linkArea img"
                  >
                    <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
                  </router-link>
                </div>
                <!-- 상품 옵션(optionUsed) -->
                <div class="orderItemInfo">
                  <p class="itemName">
                    <input type="checkbox" :id="'chk_'+item.orderOptionNo" v-model="item.selected" class="form-radio" :disabled="item.claimStatusType"/>
                    <label :for="'chk_'+item.orderOptionNo">{{ item.productName }}</label>
                  </p>
                  <div v-if="item.optionUsed">
                    <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                      {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                    </p>
                    <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                      {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                    </p>
                  </div>
                  <p>수량 : {{ comma(item.orderCnt) }}개</p>
                  <p>가격 : {{ comma(item.price) }}원</p>
                  <p v-if="item.claimNo">상태 : {{ item.claimStatusTypeLabel }}</p>
                </div>
              </div>
            </li>
          </ul>
          <!-- //상품 -->

          <!-- 반품정보 -->
          <div>
            <div class="row optionItem customStyle">
              <label class="label">반품사유</label>
              <select class="pick" v-model="form.claimReasonType">
                <option value="">선택하세요</option>
                <option
                    v-for="item in claimReason"
                    :key="item.claimReasonType"
                    :value="item.claimReasonType">
                  {{ item.label }}
                </option>
              </select>
              <i class="ic-arrow-down selectDown"></i>
            </div>
            <div class="row">
              <label class="label" for="reason">상세사유</label>
              <input type="text" placeholder="상세사유를 입력하세요" id="reason" class="form-control w100" v-model="form.claimReasonDetail"/>
            </div>
            <div class="row">
              <label class="label">첨부파일</label>
              <div class="file">
                <input type="text" placeholder="첨부파일을 등록하세요" class="form-control" v-model="fileName" readonly/>
                <input type="file" id="file" class="hidden" accept="image/*" @change="setFile"/>
                <label for="file" class="btn-border">첨부파일 등록</label>
                <a href="javascript:void(0)" class="resetFile" @click="resetFile">첨부파일 삭제</a>
              </div>
            </div>
          </div>
          <!-- //반품정보 -->

          <!-- 반품수거정보 -->
          <div>
            <h3 class="layerSubTit">반품수거정보</h3>
            <div class="row">
              <p class="label">수거방법</p>
              <span class="radioCont">
                <input type="radio" id="radio1" name="radio1" value="SELLER_COLLECT" v-model="form.returnWayType"/>
                <label for="radio1">판매자 수거요청</label>
              </span>
              <span class="radioCont">
                <input type="radio" id="radio2" name="radio1" value="BUYER_DIRECT_RETURN" v-model="form.returnWayType"/>
                <label for="radio2">구매자 직접반품</label>
              </span>
            </div>
            <!-- 판매자 수거요청 -->
            <div class="row" v-if="form.returnWayType === 'SELLER_COLLECT'">
              <label class="label" for="returnName">반품자명</label>
              <input type="text" placeholder="반품자명을 입력하세요" id="returnName" class="form-control w100" v-model="form.returnAddress.receiverName"/>
            </div>
            <div class="row" v-if="form.returnWayType === 'SELLER_COLLECT'">
              <label class="label">수거지 주소</label>
              <div class="address">
                <input type="text" placeholder="우편번호를 검색하세요" class="form-control" v-model="form.returnAddress.receiverZipCd" readonly/>
                <a href="javascript:void(0)" class="btn-border" @click="findZipcode">우편번호 검색</a>
                <div class="zipcodeWrap" ref="embed"></div>
              </div>
              <input type="text" placeholder="우편번호를 검색하세요" class="form-control w100" v-model="form.returnAddress.receiverAddress" readonly/>
              <input type="text" placeholder="세부 주소를 입력하세요" class="form-control w100" v-model="form.returnAddress.receiverDetailAddress"/>
            </div>
            <div class="row" v-if="form.returnWayType === 'SELLER_COLLECT'">
              <label class="label" for="returnPhone">휴대폰번호</label>
              <input type="text" placeholder="휴대폰번호를 입력하세요" id="returnPhone" class="form-control w100" v-model="form.returnAddress.receiverContact1" @keyup="checkPhoneNum"/>
            </div>
            <div class="row" v-if="form.returnWayType === 'SELLER_COLLECT'">
              <label class="label" for="returnMemo">수거시 참고사항</label>
              <input type="text" placeholder="수거시 참고사항을 입력하세요" id="returnMemo" class="form-control w100" v-model="form.returnAddress.deliveryMemo"/>
            </div>
            <!-- //판매자 수거요청 -->
            <!-- 구매자 직접반품 -->
            <div class="row" v-if="form.returnWayType === 'BUYER_DIRECT_RETURN'">
              <label class="label">택배사</label>
              <div class="optionItem mgTop10">
                <select class="pick" v-model="form.deliveryCompanyType">
                  <option value="">택배사 선택</option>
                  <option v-for="company in deliveryCompanyType" :key="company.id" :value="company.id">
                    {{ company.name }}
                  </option>
                </select>
                <i class="ic-arrow-down selectDown fix"></i>
              </div>
            </div>
            <div class="row" v-if="form.returnWayType === 'BUYER_DIRECT_RETURN'">
              <label class="label" for="returnParcelNumber">송장번호</label>
              <input type="text" placeholder="송장번호를 입력하세요" id="returnParcelNumber" class="form-control w100" v-model="form.invoiceNo"/>
            </div>
            <!-- //구매자 직접반품 -->
          </div>
          <!-- //반품수거정보 -->

          <!-- 환불 금액 -->
          <div>
            <h3 class="layerSubTit">
              환불 금액
              <a href="javascript:void(0)" class="btn-border-small green03" @click="estimate">환불 금액 계산</a>
            </h3>
            <div class="row">
              <div class="infoBox">
                <li class="row flex">
                  <div class="label">환불 상품금액</div>
                  <div class="content">{{ comma(refundInfo.totalAmt) }}원</div>
                </li>
                <li class="row flex">
                  <div class="label">환불 배송비</div>
                  <div class="content">{{ comma(refundInfo.refundDeliveryAmt) }}원</div>
                </li>
                <li class="row flex">
                  <div class="label">환불 포인트</div>
                  <div class="content">{{ comma(refundInfo.refundSubPayAmt) }}포인트</div>
                </li>
                <li class="row flex">
                  <div class="label">환불금액</div>
                  <div class="content">{{ comma(refundInfo.refundMainPayAmt) }}원</div>
                </li>
              </div>
            </div>
          </div>
          <!-- //환불 금액 -->

        </div>
        <!-- //contents -->

        <!-- bottom -->
        <div class="bottom">
          <a href="javascript:void(0)" class="btn-bgGreen-03" @click="submit"><i class="ic-return customStyle"></i>반품신청</a>
          <a href="javascript:void(0)" class="btn-bgGreen-04" @click="close"><i class="ic-cancel customStyle"></i>취소</a>
        </div>
        <!-- //bottom -->
      </div>

    </div>

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import http from "@/api/shopBy";
import regExp from "@/lib/regExp";
import deliveryCompanyType from "@/common/deliveryCompanyType";
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "CancelLayer-b3b5e5",
  props: ['orderNo'],
  components: {
    LoadingCont
  },
  data() {
    return {
      loading: true,
      order: {},
      orderList: [],
      claimReason: [],
      form: {
        claimType: 'RETURN',
        claimReasonType: '', //반품사유
        claimReasonDetail: '', //상세사유
        claimImageUrls: [], //첨부파일
        returnAddress: {
          addressNo: 0,
          receiverName: '',
          addressName: '',
          receiverContact1: '',
          receiverContact2: '',
          receiverZipCd: '',
          receiverAddress: '',
          receiverJibunAddress: '',
          receiverDetailAddress: '',
          customsIdNumber: '',
          countryCd: 'KR',
          deliveryMemo: ''
        },
        returnWayType: '', //반품 수거방법
        claimedProductOptions: [],
        productCnt: 0, //반품할 제품수량
        deliveryCompanyType: '', //택배사
        invoiceNo: '', //송장번호
      },
      fileName: '',
      deliveryCompanyType: [],
      refundInfo: {
        totalAmt: 0,
        refundDeliveryAmt: 0,
        refundSubPayAmt: 0,
        refundMainPayAmt: 0
      }
    }
  },
  created() {
    this.deliveryCompanyType = deliveryCompanyType
    this.getOrderInfo()
    this.getClaimInfo()
  },
  methods: {
    getOrderInfo() {
      http.get(`/profile/orders/${this.orderNo}`)
          .then(({data}) => {
            this.order = data;
            this.getOrderProduct()
          })
    },
    getClaimInfo() {
      http.get(`/profile/orders/${this.orderNo}/claim?claimType=RETURN`)
          .then(({data}) => {
            this.claimReason = data.claimReasonTypes
          })
    },
    getOrderProduct() {
      const groupByDelivery = this.order.orderOptionsGroupByPartner[0].orderOptionsGroupByDelivery
      this.orderList = groupByDelivery.flatMap(group => this.makeOrderProductInfo(group));
      this.loading = false
    },
    makeOrderProductInfo(group) {
      return group.orderOptions.flatMap(item => {
        return {
          orderNo: group.orderNo,
          productNo: item.productNo,
          orderOptionNo: item.orderOptionNo,
          productName: item.productName,
          imageUrl: item.imageUrl,
          optionUsed: item.optionUsed,
          optionName: item.optionName,
          optionValue: item.optionValue,
          optionInputs: item.inputs,
          orderCnt: item.orderCnt,
          price: item.price.buyPrice,
          claimNo: item.claimNo,
          claimStatusType: item.claimStatusType,
          claimStatusTypeLabel: item.claimStatusTypeLabel,
          selected: false,
        }
      })
    },
    submit() {
      this.loading = true
      if(!this.validation()) {
        this.loading = false
        return
      }

      this.orderList.forEach(el=>{
        if( el.selected ){
          this.form.claimedProductOptions.push({
            orderProductOptionNo: el.orderOptionNo,
            productCnt: el.orderCnt
          })
          this.form.productCnt += el.orderCnt
        }
      })

      if(this.form.claimedProductOptions.length === 0) {
        alert('반품할 상품을 선택해주세요.')
        this.loading = false
        return
      }

      if(this.form.returnWayType === 'SELLER_COLLECT') {
        this.form.returnAddress.receiverJibunAddress = this.form.returnAddress.receiverAddress + ' ' + this.form.returnAddress.receiverDetailAddress
      }

      http.post(`/profile/claims/return`, this.form).then(() => {
        alert('반품신청이 완료되었습니다.')
        this.close()
      }).catch(err=> {
        console.log(err);
        alert(err.response.data.message);
      }).finally(() => {
        this.loading = false
      })
    },
    validation() {
      if(this.form.claimReasonType === '') {
        alert('반품사유를 선택해주세요.')
        return false
      }
      if(this.form.returnWayType === '') {
        alert('반품수거방법을 선택해주세요.')
        return false
      }
      if(this.form.returnWayType === 'SELLER_COLLECT') {
        delete this.form.deliveryCompanyType
        delete this.form.invoiceNo
        // 필수입력란
        const arr = [
          { id: '받으실분 이름', value: this.form.returnAddress.receiverName },
          { id: '휴대폰번호', value: this.form.returnAddress.receiverContact1 },
          { id: '우편번호', value: this.form.returnAddress.receiverZipCd },
          { id: '주소', value: this.form.returnAddress.receiverAddress },
          { id: '세부 주소', value: this.form.returnAddress.receiverDetailAddress }
        ];
        for(let i = 0; i < arr.length; i++) {
          if(!arr[i].value) {
            alert(arr[i].id + '을(를) 입력해주세요.');
            return false;
          }
        }
      }
      if(this.form.returnWayType === 'BUYER_DIRECT_RETURN') {
        this.form.returnAddress = null
        if(this.form.deliveryCompanyType === '') {
          alert('택배사를 선택해주세요.')
          return false
        }
        if(this.form.invoiceNo === '') {
          alert('송장번호를 입력해주세요.')
          return false
        }
      }
      return true
    },
    estimate() {
      this.loading = true
      const data = {
        claimType: 'RETURN',
        claimReasonType: this.form.claimReasonType,
        returnWayType: this.form.returnWayType,
        claimedProductOptions: [],
        productCnt: 0
      }
      this.orderList.forEach(el=>{
        if( el.selected ){
          data.claimedProductOptions.push({
            orderProductOptionNo: el.orderOptionNo,
            productCnt: el.orderCnt
          })
          data.productCnt += el.orderCnt
        }
      })

      if(data.claimedProductOptions.length < 1) {
        alert('반품할 상품을 선택해주세요.')
        this.loading = false
        return
      }
      if(this.form.claimReasonType === '') {
        alert('반품사유를 선택해주세요.')
        this.loading = false
        return
      }
      if(this.form.returnWayType === '') {
        alert('반품수거방법을 선택해주세요.')
        this.loading = false
        return
      }

      http.post(`/profile/claims/estimate`, data)
          .then(({data}) => {
            this.refundInfo.totalAmt = data.productAmtInfo.totalAmt;
            this.refundInfo.refundDeliveryAmt = data.deliveryAmtInfo.refundDeliveryAmt;
            this.refundInfo.refundSubPayAmt = data.refundSubPayAmt;
            this.refundInfo.refundMainPayAmt = data.refundMainPayAmt;
          }).catch(err=> {
            console.log(err);
            alert(err.response.data.message);
          }).finally(() => {
            this.loading = false
          })
    },
    close() {
      this.$emit('close')
    },
    setFile(e) {
      const file = e.target.files[0];

      // if (file.size > 1024 * 1024 * 12) {
      //   alert('파일은 용량은 12MB 이하만 가능합니다.');
      //   return;
      // }

      const formData = new FormData()
      formData.append('file', file)
      http.post(`/files/images`, formData).then(res => {
        this.form.claimImageUrls = [res.data.imageUrl];
        if(this.fileName.replace(/\s+/g, '') === '') {
          this.fileName = res.data.originName
        }
      }).catch(err => {
        alert(err.response.data.message)
      })
    },
    resetFile() {
      if(confirm('첨부파일을 삭제하시겠습니까?')) {
        this.fileName = '';
        this.form.claimImageUrls = [];
      }
    },
    findZipcode () {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.form.returnAddress.receiverZipCd = data.zonecode;
          this.form.returnAddress.receiverAddress = data.address;
        }
      }).embed(this.$refs.embed);
    },
    comma (num) {
      return regExp.comma(num)
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    checkPhoneNum (e) {
      const regex = /^[0-9\b -]{0,13}$/;
      if (!regex.test(e.target.value)) {
        e.target.value = e.target.value.slice(0, -1);
      }
      e.target.value = regExp.phoneFormatter(e.target.value);
    },
  }
}
</script>

<style lang="scss" scoped>
.resetFile {
  color: #046A38;
  text-decoration: underline;
}
.layerSubTit {
  a {
    float: right
  }
}
.ic-return.customStyle{
  font-size: 18px;
  margin-right: 10px;
}
.ic-cancel.customStyle{
  font-size: 18px;
  margin-right: 10px;
}
</style>
