<template>
  <div class="orderTop">
    <h2>주문 목록</h2>

    <div class="optionItem mgTop10">
      <select class="pick" v-model="pick" @change="setCategory">
        <option
            v-for="item in list"
            :key="item.id"
            :value="item.id">
          {{ item.name }}
        </option>
      </select>
      <i class="ic-arrow-down selectDown fix"></i>
    </div>

  </div>
</template>

<script>
export default {
  name: "Top-1b0b1b",
  watch: {

  },
  data() {
    return {
      pick: 'list',
      list: [
        { id: "list", name: "주문 목록/배송 조회", to: "/order/list" },
        { id: "cs", name: "취소/반품/교환 목록", to: "/order/cs" },
      ]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init () {
      this.pick = this.$route.meta.order;
    },
    setCategory () {
      let cate = this.list.find(x => x.id === this.pick).to
      this.$router.push(cate);
    },
  }
}
</script>

<style scoped>

</style>