<template>
  <swiper class="swiper main_banner" :options="mainBannerOption">
    <swiper-slide v-for="(item, idx) in mainBanner" :key="idx" class="swiper-slide">
      <a :href="item.landingUrl" class="swiperBox">
        <img :src="item.imageUrl" alt="배너 이미지" />
      </a>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import http from '@/api/shopBy';
import 'swiper/css/swiper.css'
import boardCont from '@/common/board'

// apis호출 메소드 모음.
const apis = {
  mainBanner: (bannerSesencode) => http.get(`/display/banners/${bannerSesencode}`)
}
const MAINBANNER = boardCont.bannerCode; //메인배너 섹션코드

export default {
  name: 'MainSlider-3c468f',
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      mainBanner: [],
      mainBannerOption: {
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    async init(){
      const bannerData = await apis.mainBanner(MAINBANNER);
      const itemDep1 = bannerData.data.find((item) => item);
      const itemDep2 = itemDep1?.accounts.find((item) => item);
      this.mainBanner = itemDep2?.banners.map(item => item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.swiper {
  width: 100%;
  //height: 55vh;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #8b8b8b;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-container {
  overflow:hidden;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination {
  width: 40px;
  border-radius: 15px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-size: $font-size-13;
  padding: 2px 6px;
  box-sizing: border-box;
}
.swiperBox {
  display: block;
}
</style>