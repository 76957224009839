export default {
    userAgent: navigator.userAgent.toLowerCase(),
    navPlatform: navigator.platform,
    appVersionCode: 0,
    pcDevice: "win16|win32|win64|mac|macintel",
    getPlatform: function () {
        // if ( this.navPlatform ) {
        //     if ( this.pcDevice.indexOf(this.navPlatform.toLowerCase()) < 0 ) {
        //         // mobile
        //         if ( this.userAgent.indexOf('android') > -1) {
        //             return 'AOS'
        //         } else if ( this.userAgent.indexOf("iphone") > -1 || this.userAgent.indexOf("ipad") > -1 || this.userAgent.indexOf("ipod") > -1 ) {
        //             return 'IOS'
        //         } else {
        //             //아이폰, 안드로이드 외 모바일
        //             return 'MOBILE_WEB'
        //         }
        //     } else {
        //         return 'PC'
        //     }
        // }

        const match = this.userAgent.match(/fc\.hyundai\((\d+)\)/)
        this.appVersionCode = match ? parseInt(match[1]) : 0

        //PC, MOBILE_WEB, AOS, IOS
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.userAgent)) { // mobile
            // text to lowwercase

            if ( this.userAgent.indexOf('android') > -1 && this.userAgent.indexOf('fc.hyundai') > -1 && this.appVersionCode >= 183){
                return 'AOS'
            } else if ( (this.userAgent.indexOf("iphone") > -1 || this.userAgent.indexOf("ipad") > -1 || this.userAgent.indexOf("ipod") > -1) && this.userAgent.indexOf('fc.hyundai') > -1  && this.appVersionCode >= 183) {
                return 'IOS'
            } else {
                //아이폰, 안드로이드 외 모바일
                return 'MOBILE_WEB'
            }
        } else {
            return 'PC'
        }
    },
}
