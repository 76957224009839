<template>
  <div class="signIn">
    <div class="signInInner">
      <div class="imgInner"><img src="@/assets/images/common/logo2.png" alt="전북현대모터스"></div>
      <p class="textSize28 custom">로그인</p>
      <p class="textSize14 custom">전북현대모터스에프씨<br/> MD샵 '초록이네' 방문을 환영합니다.</p>
    </div>
    <div class="signInInner">
      <div class="loginInput">
        <div class="loginInputInner form-control custom">
          <input v-model="userId" type="text" placeholder="아이디를 입력하세요"/>
        </div>
      </div>
      <div class="loginInput">
        <div class="loginInputInner form-control custom">
          <input v-model="userPwd" :type="isOpen ? 'text' : 'password'" placeholder="비밀번호를 입력하세요" @keyup.enter="signin"/>
          <button @click="isOpenFu" :class="[{'isOpen': !isOpen}, 'textSize18']"><i class="ic-visibility_on"></i></button>
          <button @click="isOpenFu" :class="[{'isOpen': isOpen}, 'textSize18']"><i class="ic-visibility_off"></i></button>
        </div>
      </div>
    </div>
    <button class="textSize16 btn-bgGreen-03 customStyle" @click="signin">로그인</button>
    <div class="memberInner">
      <div @click="signup"><span>회원가입</span><i class="ic-rigth"></i></div>
      <div>
        <div @click="findId"><span>아이디찾기</span><i class="ic-rigth"></i></div>
        <div @click="findPw"><span>비밀번호찾기</span><i class="ic-rigth"></i></div>
      </div>
    </div>
    <ul class="snsInner">
      <li @click="snsLogin('kakao')" class="textSize12"><img src="@/assets/images/member/login_kakao.png" alt="카카오 로그인"/><p>카카오 로그인</p></li>
      <li @click="snsLogin('naver')" class="textSize12"><img src="@/assets/images/member/login_naver.png" alt="네이버 로그인"/><p>네이버 로그인</p></li>
      <li @click="snsLogin('facebook')" class="textSize12"><img src="@/assets/images/member/login_facebook.png" alt="페이스북 로그인"/><p>페이스북 로그인</p></li>
      <li @click="snsLogin('apple')" class="textSize12"><img src="@/assets/images/member/login_apple.png" alt="애플 로그인"/><p>애플 로그인</p></li>
    </ul>
    <div id="naver_id_login" style="display:none;"></div>

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import http from '@/api/shopBy';
import regExp from '@/lib/regExp'
import jbHttp from '@/api/index'
import LoadingCont from '@/components/Common/Loading.vue'

export default {
  name: 'Signin-f02d38',
  components: {
    LoadingCont
  },
  data() {
    return {
      userId: '',
      userPwd: '',
      redirectUrl: '/',
      isOpen: false,
      loading: false
    }
  },
  mounted() {
    this.naver_id_login = new window.naver_id_login(process.env.VUE_APP_NAVER_CLIENT_ID, `${window.location.origin}/member/signin`);
    let state = this.naver_id_login.getUniqState();
    this.naver_id_login.setButton("white", 1,40);
    this.naver_id_login.setState(state);
    this.naver_id_login.init_naver_id_login();
    this.naverTokenCheck();
  },
  created() {
    if(this.$route.query.redirectUrl) {
      this.redirectUrl = this.$route.query.redirectUrl
    }
  },
  methods: {
    isOpenFu(){
      this.isOpen = !this.isOpen
    },
    signup() {
      window.open('https://hyundai-motorsfc.com/member/signup/1')
    },
    signin() {
      this.loading = true
      if(regExp.replaceAllEmpty(this.userId) === '' || regExp.replaceAllEmpty(this.userPwd) === ''){
        alert('아이디 또는 비밀번호를 입력해주세요.');
        this.loading = false
        return;
      }

      // 전북 accessToken 발급
      jbHttp.post('/user/login?agent=web', {
        user_id: this.userId,
        user_pw: this.userPwd
      }).then((res) => {
        const payload = res.data.data;
        this.shopByLogin(payload);
      }).catch((err) => {
        alert(err.response.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
    findId() {
      window.open('https://hyundai-motorsfc.com/member/findid')
    },
    findPw() {
      window.open('https://hyundai-motorsfc.com/member/findpw')
    },
    shopByLogin(args) {
      const token = args.accessToken.token;
      const body = {
        provider: 'ncpstore',
        openAccessToken: token
      }
      http.post(`/oauth/openid`, body).then((res) => {
        let payload = res.data;
        payload.state = true;
        this.$store.commit('login', payload); // 샵바이 토큰

        // 전북 토큰
        const jbhdToken = {
          access: args.accessToken.token,
          exp: args.accessToken.exp,
          refresh: args.refreshToken.token,
          refreshExp: args.refreshToken.exp
        };
        localStorage.setItem('jbhd_token', JSON.stringify(jbhdToken));

        alert('전북현대모터스에프씨의 공식 MD샵에 로그인하셨습니다.');
        this.loading = false;
        this.$router.push(this.redirectUrl);
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },
    snsLogin(type) {
      if(this.loading) return;
      this.loading = true;

      if (type === "kakao") {
        this.kakaoLogin();
      } else if (type === "naver") {
        this.naverLogin();
      } else if (type === "facebook") {
        this.facebookLogin();
      } else if (type === "apple") {
        this.appleLogin();
      } else if (type === "google") {
        //this.googleLogin();
      }
    },
    appleLogin() {
      window.onmessage = (e) => {
        if(e.origin === process.env.VUE_APP_API_SERVER_URI) {
          if (e.data.id_token) {
            let body = {
              code: e.data.id_token,
              agent: 'web'
            }
            jbHttp.post('/user/apple/callback', body).then(res=>{

              let payload = res.data.data;
              this.shopByLogin(payload);

            }).catch(err => {
              console.log(err)
              this.loading = false;
              alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
            })
          } else {
            this.loading = false;
            alert('애플 로그인 실패하였습니다.');
          }
        } else {
          this.loading = false;
          alert('애플 로그인 실패하였습니다.');
        }
      };

      window.open(process.env.VUE_APP_APPLE_LOGIN_URI, "apple", "width=500, height=500");

    },
    naverTokenCheck() {
      if(this.naver_id_login.getAccessToken() !== undefined) {
        this.loading = true;
        let body = {
          code: this.naver_id_login.getAccessToken(),
          agent: 'web'
        };
        jbHttp.post(`/user/naver/callback`, body).then((res) => {

          let payload = res.data.data;
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.$router.replace({ query });

          this.shopByLogin(payload);

        }).catch((err) => {
          console.log(err)
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.loading = false;
          this.$router.replace({ query });
          alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
        })
      } else {
        this.loading = false;
      }
    },
    naverLogin() {
      document.querySelector("#naver_id_login>a").click();
    },
    facebookLogin() {
      alert("페이스북 로그인은 기본 브라우저에서만 가능합니다.");
      window.FB.login((response) => {
        if (response.status === 'connected') {
          let body = {
            code: response.authResponse.accessToken,
            agent: 'web'
          };
          jbHttp.post(`/user/facebook/callback`, body).then((res) => {

            let payload = res.data.data;
            this.shopByLogin(payload);

          }).catch((err) => {
            console.log(err)
            this.loading = false;
            alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
          })

        } else {
          this.loading = false;
          alert('페이스북 로그인에 실패하였습니다.');
        }
      }, {scope: 'email'});
    },
    kakaoLogin() {
      window.Kakao.Auth.login({
        success: (authObj) => {
          console.log('naver authObj :::::', authObj);
          let body = {
            code: authObj.access_token,
            agent: 'web'
          };
          jbHttp.post(`/user/kakao/callback`, body).then((res) => {

            let payload = res.data.data;
            this.shopByLogin(payload);

          }).catch((err) => {
            console.log(err)
            this.loading = false;
            alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
          })
        },
        fail: (err) => {
          console.log(err)
          this.loading = false;
          alert('카카오 로그인 실패하였습니다.');
        }
      });
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.signIn{
  .signInInner{
    text-align: center;
    .imgInner{
      width: 70px;
      margin: 0 auto;
    }
    .textSize28.custom {
      font-weight: 600;
      color: $gray-44;
      padding: 10px 0 15px 0;
    }
    .textSize14.custom {
      font-weight: 500;
      color: $gray-77;
    }
  }
  .signInInner:nth-child(1){
    padding-top: 70px;
  }
  .signInInner:nth-child(2) {
    padding: 57px 0 28px 0;
    .loginInput {
      &:last-child{
        margin-top: 10px;
        button{
          display: none;
        }
        button.isOpen {
          display: block;
        }
      }
      .loginInputInner {
        input {width: 100%; border: 0;}
      }
      .loginInputInner.custom {
        color: $gray-44;
      }
    }
  }
  .memberInner {
    padding: 20px 0 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-14;
    >div {cursor: pointer;}
    >div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
        >div {
          margin-left: 10px;
        }
    }
    span {margin-right: 3px;}
    i {font-size: 10px;}
  }
  .customStyle {
    font-weight: 700;
    width: 100%;
  }
  .snsInner {
    width: 240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 70px;
    li {
      width: 45px;
      text-align: center;
      a {display: inline-block; line-height: 16px;}
      p {padding-top: 17px;}
    }
  }
}
</style>
