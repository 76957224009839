<template>
  <div class="contentsWrap">
    <!-- breadCrumb -->
    <div class="breadCrumb">
      <router-link :to="depth1.link">{{ depth1.name }}</router-link>
      <span class="arr">&#8250;</span>
      <router-link :to="depth2.link">{{ depth2.name }}</router-link>
    </div>
    <!-- //breadCrumb -->

    <!-- List -->
    <div class="titleList border">
      <div v-if="list.length > 0">
        <div class="item" v-for="row in list" :key="row.id">
          <router-link :to="{name: 'noticeView', params: {id: row.articleNo}}">
            <div class="subject">{{ row.title }}</div>
            <div class="info">
              <span class="date">{{ row.date }}</span>
              <span class="hit">{{ comma(Number(row.viewCnt)) }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else class="nodata">
        <i class="ic-nodata customStyle"></i>
        <p>공지사항이 없습니다</p>
      </div>
      <a href="javascript:void(0)" class="btn-border moreBtn" v-if="more" @click="nextPage">더보기</a>
    </div>
    <!-- //List -->

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import regExp from '@/lib/regExp'
import http from '@/api/shopBy'
import dayjs from 'dayjs'
import boardConf from '@/common/board'
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "Notice-a513c6",
  props: ['depth1', 'depth2'],
  components: {
    LoadingCont
  },
  data () {
    return {
      loading: true,
      boardNo: boardConf.noticeNo, // 공지사항 게시판 번호
      list: [],
      pageSize: 10,
      page: 1,
      more: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    init() {
      let query = `pageSize=${this.pageSize}&pageNumber=${this.page}&direction=ASC&hasTotalCount=true`;
      http.get(`/boards/${this.boardNo}/articles?${query}`)
        .then(({data}) => {
          data.items.map(item => {
            item.date = dayjs(item.registerYmdt).format('YYYY.MM.DD')
          })
          this.list = this.list.concat(data.items);
          this.more = data.totalCount > this.list.length;
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
    },
    nextPage() {
      this.page++;
      this.init()
    },
    comma(num) {
      return regExp.comma(num)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';

.titleList {
  .nodata {
    padding: 100px 0 0;
    text-align: center;
    font-size: 14px;

    .ic-nodata {
      display: block;
      margin-bottom: 10px;
      font-size: 64px;
      color: $gray-E5;
    }
  }
  .moreBtn {
    width: 100%;
  }
}
</style>