const menu = [
  {
    name: 'category',
    title: 'CATEGORY',
    grid: 2,
    footer: false,
    children: [
      {
        name: 'all',
        title: 'ALL',
        link: '/category/all',
      }
    ]
  },
  {
    name: 'myaccount',
    title: 'MY ACCOUNT',
    grid: 1,
    children: [
      // {
      //   name: 'mypage',
      //   title: '나의 회원 정보',
      //   link: '/mypage',
      // },
      {
        name: 'signin',
        title: '로그인',
        link: '/member/signin'
      }
    ]
  },
  {
    name: 'myorder',
    title: 'MY ORDER',
    grid: 1,
    children: [
      {
        name: 'orderList',
        title: '주문 목록/배송 조회',
        link: '/order/list'
      },
      {
        name: 'orderCancel',
        title: '취소/반품/교환 목록',
        link: '/order/cs'
      }
    ]
  },
  {
    name: 'help',
    title: 'HELP',
    grid: 1,
    children: [
      {
        name: 'notice',
        title: '공지사항',
        link: '/help/notice'
      },
      {
        name: 'inquiry',
        title: '1:1 문의',
        link: '/help/inquiry'
      },
    ]
  }
]

export default menu