import Index from '@/views/Member.vue'
import Signin from '@/components/Member/Signin.vue'

const member = [
  {
    path: '/member',
    name: 'member',
    component: Index,
    children: [
      {
        path: 'signin',
        name: 'signin',
        component: Signin
      }
    ]
  }
]

export default member
