import http from '@/api/shopBy';

export default {
    getItem: async function (id) {
        const objString = window.localStorage.getItem(id);
        if(objString) {
            const obj = JSON.parse(objString);
            if(Date.now() <= obj.expire) {
                return obj.data;
            }
        }

        const res = await this.requestShopBy(id);
        localStorage.setItem(id, JSON.stringify({ data: res, expire: Date.now() + 1000 * 60 * 5 }));
        return res;
    },
    requestShopBy: async function (id) {
        const url = this.getUrlById(id);
        const ret = await http.get(url);
        return ret.data;
    },
    getUrlById: function (id) {
        switch (id) {
            case 'malls':
                return '/malls';
            case 'category':
                return '/categories';
            case 'PI_COLLECTION_AND_USE_ON_ORDER':
            case 'ORDER_INFO_AGREE':
            case 'PI_SELLER_PROVISION':
                return `/terms?termsTypes=${id}`;
        }
    }
}


