<template>
  <div class="contentsWrap">
    <!-- breadCrumb -->
    <div class="breadCrumb">
      <router-link :to="depth1.link">{{ depth1.name }}</router-link>
      <span class="arr">&#8250;</span>
      <router-link :to="depth2.link">{{ depth2.name }}</router-link>
    </div>
    <!-- //breadCrumb -->

    <!-- List -->
    <div class="titleList border">
      <div v-if="list.length > 0">
        <div class="item" v-for="row in list" :key="row.id">
          <router-link :to="{name: 'inquiryView', params: {id: row.inquiryNo}}">
            <div class="subject">{{ row.inquiryTitle }}</div>
            <div class="info">
              <span class="date">{{ row.date }}</span>
              <span :class="[{'green':row.answered}, 'status']">{{ setStatus(row.inquiryStatus) }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else class="nodata">
        <i class="ic-nodata customStyle"></i>
        <p>1:1 문의가 없습니다</p>
      </div>
      <a href="javascript:void(0)" class="btn-border more-100" v-if="more" @click="nextPage">더보기</a>
      <router-link :to="{name: 'inquiryForm', params: {inquiryNo: 'form'}}" class="btn-bgGreen-03 w100 mgTop20">1:1문의 등록하기</router-link>
    </div>
    <!-- //List -->

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import regExp from '@/lib/regExp'
import http from '@/api/shopBy'
import dayjs from 'dayjs'
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "Inquiry-a838g2",
  props: ['depth1', 'depth2'],
  components: {
    LoadingCont
  },
  computed: {
  },
  data () {
    return {
      loading: true,
      list: [],
      pageSize: 10,
      page: 1,
      more: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    init() {
      let query = `pageSize=${this.pageSize}&pageNumber=${this.page}&direction=ASC&hasTotalCount=true`;
      http.get(`/inquiries?${query}`)
          .then(({data}) => {
            data.items.map(item => {
              item.date = dayjs(item.registerYmdt).format('YYYY.MM.DD')
              item.answered = item.inquiryStatus === 'ANSWERED'
            })
            this.list = this.list.concat(data.items);
            this.more = data.totalCount > this.list.length;
          })
          .catch(err => {
            console.log(err)
          }).finally(() => {
        this.loading = false
      })
    },
    setStatus(status) {
      switch (status) {
        case 'ISSUED':
          return '답변대기'
        case 'ASKED':
          return '답변대기'
        case 'IN_PROGRESS':
          return '답변 진행중'
        case 'ANSWERED':
          return '답변완료'
      }
    },
    nextPage() {
      this.page++;
      this.init()
    },
    comma(num) {
      return regExp.comma(num)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
.titleList {
  .nodata {
    padding: 100px 0 0;
    text-align: center;
    font-size: 14px;

    .ic-nodata {
      display: block;
      margin-bottom: 10px;
      font-size: 64px;
      color: $gray-E5;
    }
  }
  .moreBtn {
    width: 100%;
  }
}
</style>