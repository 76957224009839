<template>
  <div class="detailOptions">
    <div v-for="(item, idx) in options" class="optionItem" v-bind:key="idx">
      <p class="titleSmall">{{item.label}}</p> <!-- options 타이틀 이름 -->
      <select class="pick" @change="(event) => optionChange(idx, item.label, event)" v-model="selectedOption[item.label]">
        <template v-if="item.status === true">
          <option value="">선택하세요</option>
          <template v-for="(value, idx2) in item.text">
            <option :value="value" v-bind:key="idx2" :data-option-no="item.optionNo[idx2]">{{ value }}</option>
          </template>
        </template>
        <template v-else>
          <option value="">상위 옵션을 선택하세요</option>
        </template>
      </select>
      <i class="ic-arrow-down selectDown"></i>
    </div>
    <div v-for="(item) in detailData.inputs" :key="item.inputNo" class="optionItem">

      <p class="titleSmall">{{item.inputLabel}}</p> <!-- options 타이틀 이름 -->
      <input type="text" placeholder="텍스트를 입력하세요." class="form-control w100" :data-option-no="item.inputNo" :ref="item.inputNo" @blur="inputOptionChange"/>
    </div>
    <div class="optionItem">
      <p class="titleSmall">구매수량</p>
      <div class="quantity">
        <input type="number" placeholder="구매수량을 입력해주세요." class="form-control w100" v-model="orderCnt"/>
        <button @click="orderCntChange('plus')" class="plus customStyle" type="button"></button>
        <button @click="orderCntChange('minus')" class="minus customStyle" type="button"></button>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/api/shopBy';
// apis호출 메소드 모음.
const apis = {
  productItem: (productId) => http.get(`/products/${productId}`),
  productOption: (productId) => http.get(`/products/${productId}/options`),
}
export default {
  name: "Option-a5b5e5",
  props: ['productNo','optionType','orderedCnt'],
  data() {
    return {
      options: [],
      selectedOption: {},
      selectedOptionNo: 0,
      selectTargetOption: {},
      detailData: [],
      totalDatas: [],
      flatDatas: [],
      detailInfo: {
        isOpenDefaultInfo: false,
        isOpenExchangeInfo: false,
      },
      orderCnt: 1,
    }
  },
  watch: {
    'orderCnt': 'orderCntChange'
  },
  created() {
    this.init()
  },
  methods: {
    async init(){
      const productItem = await apis.productItem(this.productNo);
      const productItemOption = await apis.productOption(this.productNo);

      // const isChildren = productItemOption.data.multiLevelOptions.find((item) => item);
      this.detailData = {
        ...this.detailInfo,
        productNo: Number(this.productNo),
        optionNo: productItemOption.data.flatOptions.map(item => item.optionNo),
        imageUrl: productItem.data.baseInfo.imageUrls.map(item => item),
        productName: productItem.data.baseInfo.productName ? productItem.data.baseInfo.productName : null,
        salePrice: productItem.data.price.salePrice,
        immediateDiscountAmt: productItem.data.price.immediateDiscountAmt,
        labels: productItemOption.data.labels.map(item => item),
        options: [],
        detailInfoText: productItem.data.baseInfo.content,
        addressStr: productItem.data.deliveryFee.returnWarehouse.addressStr,
        inputs: productItemOption.data?.inputs,
        changeExInfo: productItem.data?.baseInfo?.contentHeader,
        defaultInfo: productItem.data?.baseInfo?.contentFooter
      }
      this.flatDatas = productItemOption.data.flatOptions;

      this.detailData.options = [];


      if(productItemOption.data.type === "COMBINATION") {
        const createDataStructure = (parent, children) => {
          children.forEach((child) => {
            parent[child['value']] = child;
            // if this.optionDetailinfo[child['value']] is not undefined, then set optionNo
            if (child.children) {
              parent[child['value']]['child'] = {};
              createDataStructure(parent[child['value']]['child'], child.children);
              delete parent[child['value']]['children'];
            }
          });
        };

        const ret = {};

        createDataStructure(ret, productItemOption.data.multiLevelOptions);

        this.optionDetail = ret;
        this.optionDetailInfo = {};

        for (let i = 0; i < productItemOption.data.labels.length; i++) {
          const _tmp = {
            label: productItemOption.data.labels[i],
            text: [],
            optionNo: [],
            optionStock: {}
          }

          this.selectedOption[productItemOption.data.labels[i]] = ''

          for (let j = 0; j < productItemOption.data.multiLevelOptions.length; j++) {
            _tmp.text.push( productItemOption.data.multiLevelOptions[j].value )
            _tmp.optionNo.push( productItemOption.data.multiLevelOptions[j].optionNo )
            if(this.detailData.labels.length === 1) {
              // 선택 옵션 1개일 경우 재고 기록
              _tmp.optionStock[productItemOption.data.multiLevelOptions[j].optionNo] = productItemOption.data.multiLevelOptions[j].stockCnt
            }
          }

          if(i === 0) {
            _tmp.status = true;
          } else {
            _tmp.status = false;
          }

          this.options.push(_tmp)
        }
        // console.log("options",  this.options )
        // console.log("selectedOption", this.selectedOption);
        // console.log("optionDetail", this.optionDetail);
      } else if(productItemOption.data.type === "FLAT") {
        this.detailData.options = productItemOption.data.flatOptions.map(item => item);
      } else if(productItemOption.data.type === "DEFAULT") {
        this.selectedOptionNo = productItemOption.data.flatOptions[0].optionNo
        this.$emit('changeOption', {type: 'select', optionNo: this.selectedOptionNo});
      }
      // console.log('***********', productItemOption);
      // inputs option
      if(productItemOption.data.inputs) {
        this.detailData.inputs = productItemOption.data.inputs.map(item => item);
        this.$emit('inputOptionChk', this.detailData.inputs)
      }
      // console.log(productItemOption.data)


      /*
          isChildren.children ? {colors: productItemOption.data.multiLevelOptions.map((item, idx) => item.children[idx].value)} : null,
          isChildren ? {sizes: productItemOption.data.multiLevelOptions.map(item => item.value)} : null,
          productItem.data.limitations.minBuyCnt >= 2 ? {labels: '구매수량', volumes: Array(productItem.data.limitations.minBuyCnt).fill(0).map((_, i) => i+1)} : null
       */

      productItemOption.data.multiLevelOptions.map(item => {
        this.totalDatas.push(item)
      });

      this.$emit('endLoading')
    },
    optionChange(idx, target, e) {
      // console.log("optionChange", idx, target, e);
      if(idx === this.options.length - 1) {
        // console.log("LAST");
        // console.log(this.selectedOption[target]);
        // console.log(e.target.options[e.target.options.selectedIndex].getAttribute('data-option-no'));
        const targetKey = this.selectedOption[target];
        let salePrice = 0;
        if(typeof this.selectTargetOption[targetKey] === 'object' && this.selectTargetOption[targetKey].addPrice) {
          salePrice = this.selectTargetOption[targetKey].addPrice;
        }

        this.selectedOptionNo = e.target.options[e.target.options.selectedIndex].getAttribute('data-option-no');
        if( this.detailData.labels.length === 1 && this.options[0].optionStock[this.selectedOptionNo] === 0 ) {
          // 선택 옵션 1개일 경우
          alert('선택하신 상품의 옵션은 품절되었습니다.');
          e.target.options.selectedIndex = 0;
          return;
        } else if( this.detailData.labels.length > 1 && this.selectTargetOption[targetKey].stockCnt === 0 ) {
          alert('선택하신 상품의 옵션은 품절되었습니다.');
          // 옵션 초기화
          for(let i = 0; i < this.options.length; i++) {
            this.options[i].status = false;
            this.options[i].text = [];
            this.selectedOption[this.options[i].label] = ''
          }
          this.options[0].status = true;
          this.options[0].text = Object.keys(this.optionDetail);
          this.options[0].optionNo = Object.values(this.optionDetail).map(item => item.optionNo);
          return;
        }

        this.$emit('changeOption', {type: 'select', optionNo: this.selectedOptionNo, salePrice: salePrice});
        return;
      }
      // console.log(this.optionDetail[this.selectedOption[target]].child);

      // console.log("e", e);
      // console.log("target", target);
      // console.log("this.options", this.options);
      // console.log("this.optionDetail", this.optionDetail);
      // console.log("this.selectedOption", this.selectedOption);
      this.$emit('changeOption', {type: 'select', optionNo: null});
      for(let i = 0; i < this.options.length; i++) {
        // console.log("=================================", i, idx);
        if(i > idx) {
          // console.log("하위 초기화");
          this.options[i].status = false;
          this.options[i].text = [];
          this.selectedOption[this.options[i].label] = ''
        } else {
          // selected value this.selectedOption[this.options[i].label]
          const selectValue = this.selectedOption[this.options[i].label];
          // console.log("selectValue", this.selectedOption[this.options[i].label]);
          if(i === 0) {
            // console.log("this.optionDetail[selectValue]", this.optionDetail[selectValue]);
            // console.log("this.optionDetail[this.selectedOption[this.options[i].label]]", this.optionDetail[selectValue]['child']);
            this.selectTargetOption = this.optionDetail[selectValue]['child'];
          } else {
            // console.log("this.selectTargetOption[selectValue]", this.selectTargetOption[selectValue]);
            // console.log("this.selectTargetOption[selectValue]['child']", this.selectTargetOption[selectValue]['child']);
            this.selectTargetOption = this.selectTargetOption[selectValue]['child'];
          }
          // console.log("this.selectTargetOption " + i, this.selectTargetOption);
        }

      }

      this.options[idx+1].status = true;

      this.options[idx+1].text = Object.keys(this.selectTargetOption);
      this.options[idx+1].optionNo = Object.values(this.selectTargetOption).map(item => item.optionNo);

      // console.log("this.selectTargetOption", this.selectTargetOption)
    },
    inputOptionChange(e) {
      const target = e.target.getAttribute('data-option-no');
      this.detailData.inputs.map(item => {
        if(Number(item.inputNo) === Number(target)) {
          item.inputValue = e.target.value
        }
      })
      this.$emit('changeOption', {type: 'input', inputOption: this.detailData.inputs});
    },
    orderCntChange(type) {
      if(type === 'plus'){
        if(this.optionType === 'exchange' && this.orderCnt >= this.orderedCnt) {
          return;
        }
        this.orderCnt++;
      } else if(type === 'minus' && this.orderCnt > 1) {
        this.orderCnt--;
      }
      this.$emit('changeOption', {type: 'count', orderCnt: this.orderCnt});
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
.quantity{
  position: relative;
  .plus.customStyle{
    position: absolute;
    top: 50%;
    right: 40px;
    border: 1px solid $gray-77;
    transform: translate(-50%, -50%);
    &::before{
      width: 2px;
      height: 10px;
      background-color: $gray-77;
    }
    &::after{
      width: 10px;
      height: 2px;
      background-color: $gray-77;
    }
  }
  .minus.customStyle{
    position: absolute;
    top: 50%;
    right: 0;
    border: 1px solid $gray-77;
    transform: translate(-50%, -50%);
    &::after{
      width: 10px;
      height: 2px;
      background-color: $gray-77;
    }
  }
}
</style>
