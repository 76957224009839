import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import http from "@/api";
import cacheStorage from "@/lib/cacheStorage"

Vue.use(Vuex)

const urlParams = function () {
    const queryString = window.location.search

    function paramsObject(str) {
        const urlParams = new URLSearchParams(str)
        const entries = urlParams.entries()
        const result = {}
        for (const [key, value] of entries) {
            result[key] = value
        }
        return result
    }

    return queryString ? paramsObject(queryString) : null
}
const loginChk = () => {
    // token 있으면 true, 없으면 false
    // return !!JSON.parse(localStorage.getItem('token'))

    // index.html에서 token을 빈값으로 초기화 시키기 때문에 검증 필요 없음
    const _token = JSON.parse(localStorage.getItem('token'));
    if(_token.access === "") {
        return false;
    } else {
        return true;
    }
}

const store = new Vuex.Store({
  state: {
    login: loginChk(),
    loginProcess: false,
    token: {
        access: null,
        exp: null,
        refresh: null,
        refreshExp: null
    },
    user: {
        idx: null,
        id: null,
        name: null,
        encrypted_id: null
    },
    thisYear: '',
    params: urlParams(),
    category: [],
    count: 0
  },
  mutations: {
      loginCheck(state){
          if(!state.login){
              router.push('/member/signin');
          }
      },
      login(state, payload) {
          state.loginProcess = true;
          state.login = payload.state;
          state.token.access = payload.accessToken;
          state.token.exp = payload.expireIn;
          localStorage.setItem('token', JSON.stringify(state.token));
          state.loginProcess = false;
      },
      logout(state) {
          state.login = false;
          state.token.access = null;
          state.token.exp = null;

          localStorage.setItem('token', JSON.stringify({access: '', refresh: ''}));
          localStorage.removeItem('jbhd_token');
      },
      userInfo(state, payload) {
          state.user.idx = payload.idx;
          state.user.id = payload.id;
          state.user.name = payload.name;
          state.user.encrypted_id = payload.encrypted_id;
      },
      setThisYear(state, year) {
          state.thisYear = year
      },
      setCategory(state, payload) {
        state.category = payload
      },
      setCount(state, payload) {
        state.count = payload
      }
  },
  actions: {
      tokenCheck({commit}){
          const token = JSON.parse(localStorage.getItem('token'));
          if(token){
              commit('login', {
                  state: true,
                  accessToken: {
                      token: token.access,
                      exp: token.exp,
                      user_id: '',
                      user_name: ''
                  },
                  refreshToken: {
                      token: token.refresh,
                      exp: token.refreshExp
                  }
              })

              http.get('/user/info').then(res => {
                  commit('userInfo', {
                      idx: res.data.data.idx,
                      id: res.data.data.user_id,
                      name: res.data.data.user_name,
                      encrypted_id: res.data.data.encrypted_user_id
                  })
              })
          }
      },
      async getCategory({commit}) {
          let cateList = await cacheStorage.getItem('category');
          let newCate = [{no: '', id: 'all', name: 'ALL', link: '/category/all'}];
          cateList.multiLevelCategories?.forEach((item) => {
            newCate.push({
                no: item.categoryNo,
                id: item.label.toLowerCase(),
                name: item.label,
                link: '/category/'+item.label.toLowerCase()
            })
          });
          commit('setCategory', newCate)
      }
  },
  getters: {
      isLogin: state => state.login,
      token: state => state.token,
      accessToken: state => state.token.access,
      accessTokenExp: state => state.token.exp,
      refreshToken: state => state.token.refresh,
      refreshTokenExp: state => state.token.refreshExp,
      user: state => state.user,
      category: state => state.category,
      count: state => state.count
  }
})

export default store
