<template>
  <div class="orderListCont">
    <Top v-if="isTop"/>
    <router-view />
  </div>
</template>

<script>
import Top from '@/components/Order/Top'
export default {
  name: 'Order-585b47',
  components: {
    Top
  },
  computed: {
    isTop () {
      return this.$route.meta.order === 'list' || this.$route.meta.order === 'cs'
    }
  },
}
</script>