import Index from '@/views/Product.vue'
import List from '@/components/Product/List.vue'
import Detail from '@/components/Product/Detail.vue'

const category = [
  {
    path: '/category',
    name: 'category',
    component: Index,
    redirect: {
      name: 'categoryList'
    },
    children: [
      {
        path: '/category/:cate',
        name: 'categoryList',
        component: List,
        meta: {
          subName: 'list'
        },
      },
      {
        path: '/category/detail/:productNo',
        name: 'categoryDetail',
        component: Detail,
        meta: {
          subName: 'detail'
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = from.path;
          }
          next()
        }
      }
    ]
  }
]

export default category