<template>
  <div class="tabsCategory">
    <div class="tabsInner" ref="tabsBtn">
      <div v-for="tab in category" :key="tab.id" :class="[{'active':active(tab)}, 'tabItem']" @click="addHandler">
        <router-link :to="tab.link" class="tabs-border tabItemLink">{{tab.name}}</router-link>
      </div>
    </div>
    <div class="breadCrumbInner">
      <div class="breadCrumb">
        HOME <div class="arr">&#8250;</div>
        <router-link to="/category/all">{{depth1.name}}</router-link> <div class="arr">&#8250;</div>
        <router-link :to="depth2.link ? depth2.link : ''">{{depth2.name}}</router-link> <div class="arr"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsCategory-4f9746',
  props: ['cateList'],
  computed: {
    depth1() {
      return {
        id: this.page.id,
        name: this.page.name,
      }
    },
    category () {
      return this.$store.getters['category']
    }
  },
  watch: {
    $route () {
      this.setDepth2();
    },
    category() {
      this.setDepth2();
    },
  },
  data() {
    return {
      page: {
        id: 'category',
        name: 'CATEGORY',
        link: '/category/all'
      },
      depth2: {},
    }
  },
  created() {
    this.setDepth2();
  },
  methods: {
    setDepth2 () {
      const subname = this.$route.params.cate
      const current = this.category.find(x => x.id === subname)
      this.depth2 = {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link
      }
    },
    active(item) {
      return this.$route.params.cate === item.id
    },
    addHandler(e){
      const scrollLeft = e.currentTarget.offsetLeft - (this.$refs["tabsBtn"].clientWidth / 2);
      this.$refs['tabsBtn'].scrollTo({top: 0, left:scrollLeft, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.tabsInner {
  height: 90px;
  padding: 10px 0 0 30px;
  display: flex;
  align-items: center;
  overflow-x: auto;

  .tabItem {
    margin-right: 10px;
  }

  .tabItem.active {
    color: $white;

    .tabItemLink {
      color: $white;
      background-color: $green-03;
    }
  }
}

.breadCrumbInner {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 0;
  margin: 0 30px 0 30px;
  border-bottom: 1px solid $gray-e5;

  .breadCrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-13;
    margin: 0;
  }
}

.tabsInner::-webkit-scrollbar-thumb {
  background-color: $gray-E5;
}

.tabsInner::-webkit-scrollbar {
  height: 8px;
}

@media screen and (max-width: 425px) {
  .tabsInner::-webkit-scrollbar {
    display: none;
  }
}
</style>