const deliveryCompanyType = [
    { id: 'CJ', name: 'CJ대한통운' },
    { id: 'POST', name: '우체국' },
    { id: 'HANJIN', name: '한진택배' },
    { id: 'GTX', name: 'gtx로지스' },
    { id: 'LOTTE', name: '롯데택배' },
    { id: 'KGB', name: 'kgb택배' },
    { id: 'LOGEN', name: '로젠택배' },
    { id: 'GSI', name: 'gsi Express' },
    { id: 'KGL', name: 'KG로지스' },
    { id: 'INTRAS', name: 'INTRAS' },
    { id: 'UPS', name: 'UPS' },
    { id: 'CHUNIL', name: '천일택배' },
    { id: 'KDEXP', name: '경동택배' },
    { id: 'HDEXP', name: '합동택배' },
    { id: 'ILYANG', name: '일양택배' },
    { id: 'POST_EMS', name: '우체국 EMS' },
    { id: 'DAESIN', name: '대신택배' },
    { id: 'CVS', name: 'GSPostbox택배' },
    { id: 'DHL', name: 'DHL' },
    { id: 'FEDEX', name: 'FEDEX' },
    { id: 'GSM', name: 'GSM국제택배' },
    { id: 'WARPEX', name: 'WarpEx' },
    { id: 'WIZWA', name: 'WIZWA' },
    { id: 'ACI', name: 'ACI Express' },
    { id: 'PANTOS', name: '범한판토스' },
    { id: 'CJ_INTERNATIONAL', name: 'CJ대한통운(국제택배)' },
    { id: 'TNT', name: 'TNT' },
    { id: 'CU', name: 'CU편의점택배' },
    { id: 'KUNYOUNG', name: '건영택배' },
    { id: 'LOTTE_INTERNATIONAL', name: '롯데택배(국제택배)' },
    { id: 'HONAM', name: '호남택배' },
    { id: 'HANIPS', name: '한의사랑' },
    { id: 'IPARCEL', name: 'i-Parcel' },
    { id: 'SLX', name: 'SLX택배' },
    { id: 'USPS', name: 'USPS' },
    { id: 'WONDERS', name: '원더스퀵' },
    { id: 'REGISTPOST', name: '우편등기' },
    { id: 'DHLDE', name: 'DHL(독일)' },
    { id: 'EZUSA', name: 'EZUSA' },
    { id: 'SWGEXP', name: '성원글로벌' },
    { id: 'DAEWOON', name: '대운글로벌' },
    { id: 'DODOFLEX', name: '도도플렉스' },
    { id: 'NH_LOGIS', name: '농협택배' },
    { id: 'TODAY_PICKUP', name: '오늘의 픽업' },
    { id: 'UFO', name: '유에프오' },
    { id: 'ETC', name: '기타' }
]

export default deliveryCompanyType
