import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=548f9a0b&scoped=true&"
import script from "./Top.vue?vue&type=script&lang=js&"
export * from "./Top.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548f9a0b",
  null
  
)

export default component.exports