<template>
  <div class="footer">
    <div>
      <section class="footMenuWrap">
        <div class="cs_center footerInner">
          <div class="textSize14">
            <div class="call"><i class="ic-call"></i></div>
            <p>CS CENTER</p>
            <p class="customStyle">(063)211-4815</p>
            <p class="customStyle">sil_help@naver.com</p>
          </div>
        </div>
        <div class="footMenu">
          <ul>
            <li v-for="main in menuList" :key="main.name" :class="[{'isOpen':main.isOpen}, 'mainMenu']">
              <div @click="main.children ? setOpen(main.name) : goNotice()" class="mainMenuInner">
                <div class="footerInner menu">{{main.title}}</div>
                <button :class="[{'active':!main.isOpen}, 'plus','customStyle']"></button>
                <button :class="[{'active':main.isOpen}, 'minus','customStyle']"></button>
              </div>
              <ul v-if="!isLogin" :class="[isGrid(main), 'subMenuInner']">
                <li v-for="sub in main.children" :key="sub.name" class="subMenu">
                  <a v-if="sub.title !== '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">{{ sub.title }}</a>
                  <a v-if="sub.title === '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">로그인</a>
                </li>
              </ul>
              <ul v-if="isLogin" :class="[isGrid(main), 'subMenuInner']">
                <li v-for="sub in main.children" :key="sub.name" class="subMenu">
                  <a v-if="sub.title !== '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">{{ sub.title }}</a>
                  <a v-if="sub.title === '로그인'" href="javascript:void(0)" @click="logout">로그아웃</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section class="corpWrap">
        <div class="footerInner">
          <div class="terms">
            <span><a href="https://hyundai-motorsfc.com/policy/privacy/latest">개인정보처리방침</a></span>
            <span><a href="https://hyundai-motorsfc.com/policy/service">이용약관</a></span>
            <span><a href="https://hyundai-motorsfc.com/mypage/mypage">마이페이지</a></span>
          </div>
          <div class="info">
            <p class="company">{{malls.companyName}}</p>
            <p>대표이사 : {{malls.representativeName}}</p>
            <p>주소 : {{malls.address}}</p>
            <p>사업자등록번호 : {{malls.businessRegistrationNo}} <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1304014096" target="_blank">[사업자정보확인]</a></p>
            <p>통신판매신고 : {{ malls.onlineMarketingBusinessDeclarationNo }}</p>
            <p>개인정보처리책임자 : {{malls.privacyManagerName}}</p>
            <p>전화번호 : {{malls.representPhoneNo}} <br/> 팩스번호 : {{malls.faxNo}}</p>
            <p>이메일 : {{ malls.representEmail }}</p>
            <p>호스팅사업자 : 엔에이치엔커머스(주), 더스포츠커뮤니케이션</p>
          </div>
          <div class="sns">
            <a href="https://www.facebook.com/jeonbuk1994" target="_blank"><i class="ic-facebook"></i></a>
            <a href="https://www.youtube.com/user/JEONBUKHYUNDAI" target="_blank"><i class="ic-youtube"></i></a>
            <a href="https://www.instagram.com/jeonbuk1994/" target="_blank"><i class="ic-instagram"></i></a>
            <a href="https://apple.co/3HVkjc3" target="_blank"><i class="ic-apple"></i></a>
            <a href="https://bit.ly/3nQtcvC" target="_blank"><i class="ic-googleplay"></i></a>
            <p>Copyright ⓒ HYUNDAI MOTORS FC</p>
            <p>All Rights Reserved.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import MENU from '@/common/menu'
import cacheStorage from "@/lib/cacheStorage"
export default {
  name: 'Footer-373e38',
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    },
  },
  data () {
    return {
      footerKey: 0,
      footerMenu: [],
      menuList: [],
      malls: {}
    }
  },
  created(){
    this.footerMenu = MENU.filter((item) => item.footer !== false)
    this.init()
    this.getMalls()
  },
  mounted() {
  },
  methods:{
    init(){
      if (MENU) {
        this.menuList = this.footerMenu.map(item => {
          return {
            ...item,
            isOpen: false,
            footerKey: this.footerKey += 1
          }
        })
      }
    },
    async getMalls() {
      const malls = await cacheStorage.getItem('malls');
      this.malls = malls.serviceBasicInfo;
    },
    setOpen(menuName){
      const item = this.menuList.find(item => item.name === menuName)
      item.isOpen = !item.isOpen
    },
    isGrid(value){
      return value.grid === 1 ? 'grid-1' : 'grid-2'
    },
    goMenu(name, sub){
      this.setOpen(name)
      this.$router.push(sub.link)
    },
    logout(){
      document.getElementById( "iframe_connect" ).contentWindow.postMessage( "deleteAccessToken", "*" )
      document.getElementById( "iframe_connect_green" ).contentWindow.postMessage( "deleteAccessToken", "*" )

      this.$store.commit('logout');
      this.$router.push('/');
    },
    goNotice() {
      this.$router.push({ name: 'help' }).catch(() => {})
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
%line-base {
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
}
.footer {
  margin: 0 auto;
  .footerInner {
    padding-left:30px;
    padding-right:30px;
    .call {font-size: 40px; margin-bottom: 10px;}
    .customStyle {font-weight: 500;}
    .terms {
      font-size: 14px;
      padding: 20px 0;
      color: rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    }
    .terms span:first-child {margin: 0;}
    .terms span{margin: 0 20px;}
    .terms span:nth-child(2):before{
      @extend %line-base;
      border-left: 1px solid rgba(217, 217, 217, 0.2);
      padding: 0 10px;
    }
    .terms span:nth-child(2)::after{
      @extend %line-base;
      border-right: 1px solid rgba(217, 217, 217, 0.2);
      padding: 0 10px;
    }
    .terms span:last-child {margin: 0;}
    .info {
      color: rgba(255, 255, 255, 0.4);
      font-size: $font-size-13;
      padding-bottom:20px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.2);
      .company {
        font-size: $font-size-14;
        font-weight: 700;
        color: $white;
        padding: 20px 0;
      }
    }
    .sns {
      color: rgba(255, 255, 255, 0.4);
      font-size: $font-size-13;
      text-align: center;
      padding: 30px 0;
      a{font-size: $font-size-20; padding: 0 12px;}
      a:first-child {padding-left: 0;}
      a:nth-child(5) {padding-right: 0;}
    }
  }
  ul li:nth-child(n+1) .footerInner.menu{
    padding-top:20px;
    padding-bottom:20px;
  }
}
.footMenuWrap {
  background-color: $green-03;
  color: $white;
}
.cs_center {
  text-align: center;
  font-size: $font-size-14;
  > div {
    padding: 50px 0;
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
  }
}
.footMenu {
  font-size: $font-size-24;
  font-weight: 800;
  padding: 20px 0;
  cursor: pointer;

  .mainMenuInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .customStyle {
      margin-right: 30px;
    }
  }
  button{display: none;}
  button.active{display: block;}
  .mainMenu.isOpen {
    .subMenuInner{
      display: block
    }
  }
  .subMenuInner{
    display: none
  }
}
.corpWrap {
  background-color: $green-00;
}
</style>