<template>
  <div class="main">
    <MainSlider />
    <div class="wrapper">
      <ProductSlider />
      <GreenBanner />
      <NewProduct />
      <NoticeList/>
    </div>
  </div>
</template>

<script>
import NoticeList from '@/components/Common/Noticelist.vue';
import MainSlider from '@/components/Main/MainSlider.vue';
import ProductSlider from '@/components/Main/ProductSlider.vue';
import GreenBanner from '@/components/Main/GreenBanner.vue';
import NewProduct from '@/components/Main/NewProduct.vue';

export default {
  name: 'Main-34bb34',
  components: {
    NoticeList,
    MainSlider,
    ProductSlider,
    GreenBanner,
    NewProduct,
  },
  data(){
    return {
    }
  },
  methods: {
    test() {
      console.log(this.inits)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.wrapper {
  padding: 30px 30px 50px;
}
.swiper {
  width: 100%;
  //height: 55vh;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #8b8b8b;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  width: 40px;
  border-radius: 15px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-size: $font-size-13;
  padding: 2px 6px;
  box-sizing: border-box;
}
</style>
