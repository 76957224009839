<template>
  <div class="contentsWrap">
    <!-- 뒤로가기 & 목록 -->
    <div class="backMenu">
      <router-link :to="historyBack" class="back"><img src="@/assets/images/common/ico_back.png" alt="뒤로가기"/></router-link>
      <router-link :to="{name: 'helpNotice'}" class="btn-border-small list">목록</router-link>
    </div>
    <!-- //뒤로가기 & 목록 -->

    <!-- Detail View -->
    <div class="detailView simple">
      <div class="title">
        <p class="subject">{{ viewData.title }}</p>
        <div class="info">
          <span class="date">{{ viewData.date }}</span>
          <span class="hit">{{ comma(Number(viewData.hit)) }}</span>
        </div>
      </div>
      <div class="content" v-html="viewData.content">
      </div>
    </div>
    <!-- //Detail View -->

    <List />

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import regExp from '@/lib/regExp'
import List from '@/components/Common/Noticelist.vue';
import http from '@/api/shopBy'
import dayjs from 'dayjs'
import boardConf from '@/common/board'
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "View-c211a5",
  props: ['depth1', 'depth2'],
  components: {
    List,
    LoadingCont
  },
  computed: {
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
  },
  watch: {
    $route () {
      this.articleNo = this.$route.params.id
      this.init();
    },
  },
  data() {
    return {
      loading: true,
      boardNo: boardConf.noticeNo, // 공지사항 게시판 번호
      articleNo: this.$route.params.id,
      viewData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      console.log('>>>',this.articleNo);
      http.get(`/boards/${this.boardNo}/articles/${this.articleNo}`)
        .then(({data}) => {
          console.log(data);
          this.viewData = {
            title: data.title,
            content: data.content,
            date: dayjs(data.registerYmdt).format('YYYY-MM-DD'),
            hit: data.viewCnt,
          }
        })
        .catch(err => {
          console.log(err);
          alert(err.response.data.message);
          this.$router.push({name: 'helpNotice'})
        }).finally(() => {
          this.loading = false
        })
    },
    comma(num) {
      return regExp.comma(num)
    },
  }
}
</script>

<style scoped>
.contentsWrap {
  padding-bottom: 50px;
}
</style>