<template>
  <div class="productMore" v-if="reponseMore.length !== 0">
    <div class="productTileInner">
      <h3 class="titleMedium">신규상품</h3>
      <div class="more-next"><router-link :to="{name: 'categoryList', params: {cate: 'all'}}">더보기</router-link></div>
    </div>
    <ProductList :newProduct="reponseMore" :isLoading="isLoadingMore" :isNext="isNext" @more="moreBtn"/>
  </div>
</template>

<script>
import ProductList from '@/components/Product/ProductList.vue';
import http from '@/api/shopBy';
import regExp from "@/lib/regExp";

export default {
  name: 'ProductMore-030302',
  components: {
    ProductList
  },
  data() {
    return {
      reponseMore: [],
      page: 1,
      pageSize: 4,
      errorMore: null,
      isLoadingMore: false,
      isNext: true,
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const query = `filter.saleStatus=RESERVATION_AND_ONSALE&order.by=RECENT_PRODUCT&order.direction=DESC&pageNumber=${this.page}&pageSize=${this.pageSize}&filter.soldout=true`;
      this.getProduct(query);
    },
    async getProduct(query){
      const apiRes = await http.get(`/products/search?${query}`);
      const list = apiRes.data.items;
      list.map(item => {
        let obj = {
          imageUrl: item.listImageUrls[0],
          productNo: item.productNo,
          productName: item.productName,
          salePrice: regExp.comma(regExp.numberOnly(item.salePrice))
        }
        if(item.isSoldOut) {
          obj.productName += `<span style="color:#999">(품절)</span>`;
        }
        this.reponseMore.push(obj)
      })

      if(this.reponseMore.length < 4 || this.page > 1) {
        this.isNext = false;
      }
    },
    moreBtn(){
      this.page++;
      const query = `pageNumber=${this.page}&pageSize=${this.pageSize}&filter.soldout=true`;
      this.getProduct(query);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
  .productMore{
    padding: 30px 0 60px 0;
  }
  .productTileInner {
    padding-bottom: 15px;
  }

</style>
