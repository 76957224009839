<template>
  <div class="contentsWrap">
    <!-- 뒤로가기 -->
    <div class="backBtnCont">
      <router-link :to="historyBack">
        <img src="@/assets/images/common/ico_back.png" alt="뒤로가기">
      </router-link>
      <h2>{{ pageTitle }}정보 내용</h2>
    </div>
    <!-- //뒤로가기 -->

    <!-- 주문 내용 -->
    <ul class="orderList">
      <li>
        <div class="orderItem noImg">
          <div class="orderItemInfo">
            <p class="orderTit">{{ orderInfo.orderTitle }}</p>
            <p class="orderNo">{{ orderInfo.orderNo }}</p>
            <p class="orderDate">{{ orderInfo.orderDate }}</p>
            <p class="orderState">
              <span>{{ comma(orderInfo.price) }}원</span>
              <span>{{ orderInfo.payType }}</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- //주문 내용 -->

    <!-- 주문 상품(교환정보에서만 노출) -->
    <div v-if="claimType === 'EXCHANGE'">
      <h3 class="headTxt_3">주문 상품</h3>
      <ul class="orderList line">
        <li v-for="(item, index) in orderList" :key="index">
          <div class="orderItem">
            <div class="orderItemImg">
              <router-link
                  :style="{'background': productThumb(item.imageUrl)}"
                  :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                  class="linkArea img"
              >
                <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
              </router-link>
            </div>
            <div class="orderItemInfo">
              <p class="itemName">{{ item.productName }}</p>
              <div v-if="item.optionUsed">
                <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                  {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                </p>
                <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                  {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                </p>
              </div>
              <p>수량 : {{ comma(item.orderCnt) }}개</p>
              <p>가격 : {{ comma(item.price) }}원</p>
              <!--<p>상태 : {{ item.orderStatusTypeLabel }}</p>-->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- //주문 상품 -->

    <!-- 클레임 상품 -->
    <div>
      <h3 class="headTxt_3">{{ pageTitle }} 상품</h3>
      <ul class="orderList line">
        <li v-for="(item, index) in claimList" :key="index">
          <div class="orderItem">
            <div class="orderItemImg">
              <router-link
                  :style="{'background': productThumb(item.imageUrl)}"
                  :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                  class="linkArea img"
              >
                <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
              </router-link>
            </div>
            <div class="orderItemInfo">
              <p class="itemName">{{ item.productName }}</p>
              <div v-if="item.optionUsed">
                <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                  {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                </p>
                <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                  {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                </p>
              </div>
              <p>수량 : {{ comma(item.orderCnt) }}개</p>
              <p>가격 : {{ comma(item.price) }}원</p>
              <p>상태 : {{ item.claimStatusTypeLabel }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- //클레임 상품 -->

    <!-- 클레임 정보(반품, 교환정보에서만 노출) -->
    <div v-if="claimType !== 'CANCEL'">
      <h3 class="headTxt_3">{{ pageTitle }} 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">반품사유</div>
          <div>{{ claimInfo.claimReasonType }}</div>
        </li>
        <li class="row flex">
          <div class="label">첨부파일</div>
          <a :href="claimImageUrls[0]" class="content file" v-if="isClaimImage" target="_blank">
            첨부파일 보기
          </a>
        </li>
        <li class="row">
          <div class="label">상세사유</div>
          <div class="content">{{ claimInfo.claimReasonDetail }}</div>
        </li>
      </ul>
    </div>
    <!-- //클레임 정보 -->

    <!-- 수거 정보(반품, 교환에서만 노출) -->
    <div v-if="claimType !== 'CANCEL' && !exchangeBeforeDelivery">
      <h3 class="headTxt_3">{{ pageTitle }}수거정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">수거방법</div>
          <div class="content">{{ returnInfo.returnWayTypeLabel }}</div>
        </li>
        <li class="row flex" v-if="returnInfo.returnWayType === 'SELLER_COLLECT'">
          <div class="label">반품자명</div>
          <div class="content">{{ returnInfo.name }}</div>
        </li>
        <li class="row flex" v-if="returnInfo.returnWayType === 'SELLER_COLLECT'">
          <div class="label">휴대폰번호</div>
          <div class="content">{{ returnInfo.contact1 }}</div>
        </li>
        <li class="row" v-if="returnInfo.returnWayType === 'SELLER_COLLECT'">
          <div class="label">주소</div>
          <div class="content">
            {{ returnInfo.addressStr }}
          </div>
        </li>
        <li class="row" v-if="returnInfo.returnWayType === 'SELLER_COLLECT'">
          <div class="label">수거시 참고사항</div>
          <div class="content">
            {{ returnInfo.note }}
          </div>
        </li>
        <li class="row flex" v-if="returnInfo.returnWayType === 'BUYER_DIRECT_RETURN'">
          <div class="label">택배사</div>
          <div class="content">{{ returnInfo.deliveryCompanyTypeLabel }}</div>
        </li>
        <li class="row flex" v-if="returnInfo.returnWayType === 'BUYER_DIRECT_RETURN'">
          <div class="label">송장번호</div>
          <div class="content">
            {{ returnInfo.invoiceNo }}
          </div>
        </li>
      </ul>
    </div>
    <!-- //배송 정보 -->

    <!-- 배송 정보(교환에서만 노출) -->
    <div v-if="claimType === 'EXCHANGE' && !exchangeBeforeDelivery">
      <h3 class="headTxt_3">교환배송 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">수령자명</div>
          <div class="content">{{ shippingInfo.name }}</div>
        </li>
        <li class="row flex">
          <div class="label">휴대폰번호</div>
          <div class="content">{{ shippingInfo.contact1 }}</div>
        </li>
        <li class="row">
          <div class="label">주소</div>
          <div class="content">({{ shippingInfo.zipCd }}) {{ shippingInfo.addressStr }}</div>
        </li>
        <li class="row">
          <div class="label">배송시 참고사항</div>
          <div class="content">
            {{ shippingInfo.note }}
          </div>
        </li>
      </ul>
    </div>
    <!-- //배송 정보 -->

    <!-- 환불 정보(반품, 취소정보에서만 노출) -->
    <div class="mgBtm50" v-if="claimType !== 'EXCHANGE'">
      <h3 class="headTxt_3">환불 정보</h3>
      <ul class="infoBox">
        <li class="row flex">
          <div class="label">환불 상품금액</div>
          <div class="content">{{  comma(refundInfo.productAmtInfo.totalAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">환불 배송비</div>
          <div class="content">{{ comma(refundInfo.deliveryAmtInfo.refundDeliveryAmt) }}원</div>
        </li>
        <li class="row flex">
          <div class="label">환불 포인트</div>
          <div class="content">{{ comma(refundInfo.refundSubPayAmt) }} 포인트</div>
        </li>
        <li class="row flex">
          <div class="label">환불 금액</div>
          <div class="content"> {{ comma(refundInfo.refundMainPayAmt) }} 원</div>
        </li>
        <li class="row flex">
          <div class="label">환불 수단</div>
          <div class="content">{{ refundInfo.method }}</div>
        </li>
      </ul>
    </div>
    <!-- //환불 정보 -->

    <!-- 공지사항 -->
    <Notice class="mgTop50"/>
    <!-- //공지사항 -->

    <LoadingCont v-if="loading" />
  </div>
</template>

<script>
import Notice from '@/components/Common/Noticelist.vue'
import http from '@/api/shopBy'
import regExp from "@/lib/regExp";
import dayjs from "dayjs";
import LoadingCont from "@/components/Common/Loading.vue";
import claimCtrl from "@/common/claimReasonType"
export default {
  name: 'detail-1cf3d9',
  components: {
    Notice,
    LoadingCont
  },
  computed: {
    claimNo() {
      return this.$route.params.id
    },
    historyBack() {
      return this.$route.meta && this.$route.meta.historyBack
    },
    isClaimImage() { // 클레임 첨부파일 존재 여부
      return this.claimImageUrls && this.claimImageUrls.length > 0
    }
  },
  data() {
    return {
      loading: true,
      claimType: '',
      pageTitle: '',
      orderNo: this.$route.query.orderNo,
      order: {},
      claim: {},
      orderInfo: {},
      orderList: [],
      claimInfo: {},
      claimList: [],
      payInfo: {},
      refundInfo: {
        productAmtInfo: {},
        deliveryAmtInfo: {}
      },
      returnInfo: {}, // 수거정보
      shippingInfo: {}, // 배송정보
      exchangeBeforeDelivery: false,
      claimImageUrls: [],
    }
  },
  created() {
    this.getOrderInfo()
    this.getClaim()
  },
  methods: {
    getOrderInfo() {
      http.get(`/profile/orders/${this.orderNo}?orderRequestType=CLAIM`).then(({data}) => {
        this.order = data;
        // 주문상품
        this.getOrderProduct();

        //console.log('orderList >>>>>>>', this.orderList);
        // 주문정보
        let orderTit = regExp.truncate(this.orderList[0].productName, 25)
        if(this.orderList.length > 1) {
          orderTit += ` 외 ${this.orderList.length - 1}건`
        }
        this.orderInfo = {
          orderTitle: orderTit,
          orderNo: data.orderNo,
          orderDate: dayjs(data.orderTmdt).format('YYYY년 MM월 DD일 HH:mm'),
          price: data.payInfo.payAmt,
          payType: data.payTypeLabel,
        }

      }).catch(err => {
        alert(err.response.data.message);
        this.$router.push({name: 'csList'})
      })
    },
    getClaim() {
      http.get(`/profile/claims/${this.claimNo}/result`)
        .then(({data}) => {
          console.log('Claim Data >>>>>', data);
          this.claim = data;
          this.claimType = data.claimType;
          this.pageTitle = this.getClaimTitle(this.claimType);

          // 상품내역
          this.claimList = this.makeClaimProductInfo(data);
          //console.log('claimList >>>>>', this.claimList);

          // 환불정보
          this.refundInfo = data.claimPriceInfo;
          this.refundInfo.method = this.refundInfo.refundTypeLabel;

          // 교환,반품 정보
          this.claimInfo.claimReasonType = claimCtrl.getLabel(data.claimReasonType);
          this.claimInfo.claimImageUrls = data.claimImageUrls;
          this.claimInfo.claimReasonDetail = data.claimReasonDetail;
          this.claimImageUrls = data.claimImageUrls;

          this.exchangeBeforeDelivery = data.exchangeBeforeDelivery;
          // 수거정보
          if(!data.exchangeBeforeDelivery) {
            // 출고전 교환이 아닌 경우
            if(data.returnWayType === 'SELLER_COLLECT') { //판매자수거요청
              this.returnInfo = data.returnAddress;
              this.returnInfo.returnWayType = data.returnWayType;
              this.returnInfo.returnWayTypeLabel = '판매자 수거요청';
            } else { // 구매자직접반품
              this.returnInfo = data.returnDelivery;
              this.returnInfo.returnWayType = data.returnWayType;
              this.returnInfo.returnWayTypeLabel = '구매자 직접반품';
            }

            // 교환배송정보
            this.shippingInfo = data.exchangeAddress;
          }

        })
        .catch(err => {
          alert(err.response.data.message)
          this.$router.push({name: 'csList'})
        })
    },
    makeClaimProductInfo(data) {
      return data.claimedOptions.flatMap(claim => {
        return {
          orderNo: claim.orderNo,
          productNo: claim.productNo,
          productName: claim.productName,
          imageUrl: claim.imageUrl,
          optionUsed: claim.optionUsed,
          optionName: claim.optionName,
          optionValue: claim.optionValue,
          optionInputs: claim.inputs,
          orderCnt: claim.orderCnt,
          price: claim.price.buyPrice,
          claimStatusTypeLabel: claim.claimStatusTypeLabel,
          orderYmdt: dayjs(claim.orderStatusDate.registerYmdt).format('YYYY년 MM월 DD일')
        }
      })
    },
    getOrderProduct() {
      const groupByDelivery = this.order.orderOptionsGroupByPartner[0].orderOptionsGroupByDelivery
      this.orderList = groupByDelivery.flatMap(group => this.makeOrderProductInfo(group));
      this.loading = false
    },
    makeOrderProductInfo(group) {
      return group.orderOptions.flatMap(item => {
        return {
          orderNo: group.orderNo,
          productNo: item.productNo,
          orderOptionNo: item.orderOptionNo,
          productName: item.productName,
          imageUrl: item.imageUrl,
          optionUsed: item.optionUsed,
          optionName: item.optionName,
          optionValue: item.optionValue,
          optionInputs: item.inputs,
          orderCnt: item.orderCnt,
          price: item.price.salePrice,
          payTypeLabel: group.payTypeLabel,
          orderStatusType: item.orderStatusType,
          orderStatusTypeLabel: item.orderStatusTypeLabel,
          orderYmdt: dayjs(group.orderYmdt).format('YYYY년 MM월 DD일')
        }
      })
    },
    comma (num) {
      return regExp.comma(num)
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
    getClaimTitle (type) {
      switch (type) {
        case 'EXCHANGE':
          return '교환'
        case 'RETURN':
          return '반품'
        case 'CANCEL':
          return '취소'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .contentsWrap {
    margin-bottom: 30px;
  }
</style>
