<template>
  <div class="layerWrap">
    <div class="in">

      <div class="layerCont">
        <!-- head -->
        <div class="top">
          <a href="javascript:void(0)" class="close" @click="close"><img src="@/assets/images/common/ico_close.png" alt="닫기" /></a>
          구매취소
        </div>
        <!-- //head -->

        <!-- contents -->
        <div class="contents">
          <ul class="orderList line checkbox">
            <li v-for="(item, index) in orderList" :key="index">
              <div class="orderItem">
                <div class="orderItemImg">
                  <router-link
                      :style="{'background': productThumb(item.imageUrl)}"
                      :to="{name: 'categoryDetail', params: {productNo: item.productNo}}"
                      class="linkArea img"
                  >
                    <img src="@/assets/images/list/product_rectangle.png" class="hidden"/>
                  </router-link>
                </div>
                <!-- 상품 옵션(optionUsed) -->
                <div class="orderItemInfo">
                  <p class="itemName">
                    <input type="checkbox" :id="'chk_'+item.orderOptionNo" v-model="item.selected" :disabled="item.claimStatusType" class="form-radio"/>
                    <label :for="'chk_'+item.orderOptionNo">{{ item.productName }}</label>
                  </p>
                  <div v-if="item.optionUsed">
                    <p v-for="(option, idx) in item.optionName.split('|')" :key="`options_${idx}`">
                      {{ option }} : {{ item.optionValue.split('|')[idx] }}<br/>
                    </p>
                    <p v-for="(option, idx) in item.optionInputs" :key="`input_${idx}`">
                      {{ option.inputLabel }} : {{ option.inputValue }}<br/>
                    </p>
                  </div>
                  <p>수량 : {{ comma(item.orderCnt) }}개</p>
                  <p>가격 : {{ comma(item.price) }}원</p>
                  <p v-if="item.claimNo">상태 : {{ item.claimStatusTypeLabel }}</p>
                </div>
              </div>
            </li>
          </ul>

          <div class="optionItem customStyle">
            <label class="titleSmall customStyle">취소사유</label>
            <select class="pick" v-model="form.claimReasonType">
              <option value="">선택하세요</option>
              <option
                  v-for="item in claimReason"
                  :key="item.claimReasonType"
                  :value="item.claimReasonType">
                {{ item.label }}
              </option>
            </select>
            <i class="ic-arrow-down selectDown"></i>
          </div>
          <div>
            <label class="titleSmall customStyle">상세사유</label>
            <input type="text" class="form-control w100" v-model="form.claimReasonDetail" placeholder="취소상세사유를 입력해주세요">
          </div>

        </div>
        <!-- //contents -->

        <!-- bottom -->
        <div class="bottom mgTop30">
          <a href="javascript:void(0)" class="btn-bgGreen-03" @click="submit('all')"><i class="ic-cancel customStyle"></i>전체취소</a>
          <a href="javascript:void(0)" class="btn-bgGreen-04" @click="submit()"><i class="ic-delivery customStyle"></i>부분취소</a>
        </div>
        <!-- //bottom -->
      </div>

    </div>

    <LoadingCont v-if="loading" />

  </div>
</template>

<script>
import http from '@/api/shopBy'
// import dayjs from "dayjs";
import regExp from "@/lib/regExp";
import LoadingCont from "@/components/Common/Loading.vue";
export default {
  name: "CancelLayer-b3b5e5",
  props: ['orderNo'],
  components: {
    LoadingCont
  },
  data() {
    return {
      loading: true,
      order: {},
      orderList: [],
      claimReason: [],
      form: {
        claimType: "CANCEL",
        claimReasonType: "",
        claimReasonDetail: "",
        // bankAccountInfo: null,
        // saveBankAccountInfo: true, // 환불계좌정보 저장 여부
        // responsibleObjectType: null,
        claimedProductOptions: [],
        // refundsImmediately: false
      },
    }
  },
  created() {
    this.getOrderInfo()
    this.getClaimInfo()
  },
  methods: {
    getOrderInfo() {
      http.get(`/profile/orders/${this.orderNo}`)
          .then(({data}) => {
            this.order = data;
            this.getOrderProduct()
          })
    },
    getClaimInfo() {
      http.get(`/profile/orders/${this.orderNo}/claim?claimType=CANCEL`)
          .then(({data}) => {
            this.claimReason = data.claimReasonTypes
          })
    },
    getOrderProduct() {
      const groupByDelivery = this.order.orderOptionsGroupByPartner[0].orderOptionsGroupByDelivery
      this.orderList = groupByDelivery.flatMap(group => this.makeOrderProductInfo(group));
      this.loading = false
    },
    makeOrderProductInfo(group) {
      return group.orderOptions.flatMap(item => {
        return {
          orderNo: group.orderNo,
          productNo: item.productNo,
          orderOptionNo: item.orderOptionNo,
          productName: item.productName,
          imageUrl: item.imageUrl,
          optionUsed: item.optionUsed,
          optionName: item.optionName,
          optionValue: item.optionValue,
          optionInputs: item.inputs,
          orderCnt: item.orderCnt,
          price: item.price.buyPrice,
          claimNo: item.claimNo,
          claimStatusType: item.claimStatusType,
          claimStatusTypeLabel: item.claimStatusTypeLabel,
          selected: false,
        }
      })
    },
    submit(type) {
      this.loading = true
      if(this.form.claimReasonType === '') {
        alert('취소사유를 선택해주세요.')
        this.loading = false
        return
      }

      this.form.claimedProductOptions = []
      if( type === 'all' ){
        // 전체취소
        if( confirm('전체 상품을 취소 하시겠습니까?') ){

          this.orderList.forEach(el=>{
            if( el.claimNo ) return
            el.selected = true
            this.form.claimedProductOptions.push({
              orderProductOptionNo: el.orderOptionNo,
              productCnt: el.orderCnt
            })
          })

          // console.log('전체취소', this.form);
          this.cancel()

        } else {
          this.loading = false
        }
      } else {
        if( confirm('선택하신 상품을 취소하겠습니까?') ){
          // 부분취소
          this.orderList.forEach(el=>{
            if( el.selected ){
              this.form.claimedProductOptions.push({
                orderProductOptionNo: el.orderOptionNo,
                productCnt: el.orderCnt
              })
            }
          })

          if( this.form.claimedProductOptions.length === 0 ){
            alert('취소 상품이 선택되지 않았습니다.')
            this.loading = false
            return;
          }

          // console.log('부분취소', this.form);
          this.cancel()
        } else {
          this.loading = false
        }
      }

    },
    cancel() {
      http.post('/profile/claims/cancel', this.form)
        .then(() => {
          alert('취소요청이 완료되었습니다.')
          this.$emit('close')
        }).catch(err => {
          alert(err.response.data.message)
          //this.$emit('close')
        }).finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
    comma (num) {
      return regExp.comma(num)
    },
    productThumb (img) {
      return !img ? '#eee' : `#eee url('${img}')`;
    },
  }
}
</script>

<style lang="scss" scoped>
.ic-delivery.customStyle{
  font-size: 18px;
  margin-right: 10px;
}
.ic-cancel.customStyle{
  font-size: 18px;
  margin-right: 10px;
}
</style>
