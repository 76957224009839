<template>
  <div class="productList">
   <div class="productMoreInner">
      <div v-for="(item, idx) in newProduct" :key="idx" class="item">
        <router-link :to="{name: 'categoryDetail', params: {productNo: item.productNo}}">
          <img :src="item.imageUrl" />
          <p class="titleSmall" v-html="item.productName"></p>
          <p class="txteSmall">{{ item.salePrice }}</p>
        </router-link>
      </div>
    </div>
    <!--
    <div v-if="error" class="errorInfo">
      <i class="ic-nodata customStyle"></i> 
      <p>상품정보가 준비중입니다.</p>
    </div>
    -->
    <button :class="[{'hide': !isNext}, 'btn-border', 'customStyle']" @click="moreEvent">
      <span v-if="isLoading" class="isLoad"><img src="@/assets/images/common/loading.gif" alt="loading"/></span>
      <span>더보기</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  props: ['newProduct', 'isLoading', 'isNext'],
  methods: {
    moreEvent(){
      this.$emit('more');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
.productList{
  .isLoad {
    width: 20px;
    margin-right: 10px;
  }
  .productMoreInner {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 1 1 40%;
      padding: 0 15px 15px 15px;
      a{
        width: 100%;
        display: inline-block;
      }
      .titleSmall,
      .txteSmall {
        text-align: center;
      }
      img {
        height: 150px;
        object-fit: contain;
      }
      p:last-child {
        padding-top: 6px;
      }
      &:nth-child(odd){
        padding-left: 0;
      }
      &:nth-child(even){
        padding-right: 0;
      }
    }
  }
  .errorInfo {
    text-align: center;
    padding: 60px 0;
    .customStyle {
      color: $gray-e5; 
      font-size: $font-size-64;
    }
    p{font-size: $font-size-14}
  }
  .customStyle {
    width: 100%;
    color: $gray-44;
  }
  .hide {display: none;}
  button[disabled] {
    background-color: $gray-DD;
  }
}
</style>