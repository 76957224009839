import Index from '@/views/Order.vue'
import Form from '@/components/Order/Form.vue'
import Complete from '@/components/Order/Complete.vue'
import List from '@/components/Order/List.vue'
import Detail from '@/components/Order/Detail.vue'
import Cart from '@/components/Order/Cart.vue'
import CsList from '@/components/Order/Cs.vue'
import CsDetail from '@/components/Order/CsDetail.vue'

const order = [
  {
    path: '/order',
    name: 'order',
    component: Index,
    redirect: {
      name: 'orderList'
    },
    children: [
      {
        path: 'list',
        name: 'orderList',
        component: List,
        meta: {
          order: 'list'
        }
      },
      {
        path: 'cart',
        name: 'cartList',
        component: Cart,
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = from.path;
          }
          next()
        }
      },
      {
        path: 'detail/:id',
        name: 'orderDetail',
        component: Detail,
        meta: {
          order: 'list'
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = from.path;
          }
          next()
        }
      },
      {
        path: 'cs',
        name: 'csList',
        component: CsList,
        meta: {
          order: 'cs'
        }
      },
      {
        path: 'cs/:id',
        name: 'csDetail',
        component: CsDetail,
        meta: {
          order: 'cs'
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = from.path;
          }
          next()
        }
      },
      {
        path: 'form/:orderSheetNo',
        name: 'orderForm',
        component: Form,
        meta: {
          order: 'form'
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = from.path;
          }
          next()
        }
      },
      {
        path: 'complete',
        name: 'orderComplete',
        component: Complete,
        meta: {
          order: 'complete'
        }
      }
    ]
  },
]

export default order
