<template>
  <div class="app">
    <div class="web_familySite">
      <nav>
        <li><a href="https://hyundai-motorsfc.com/">전북현대</a></li>
        <li><a href="https://greenschool.hyundai-motorsfc.com/">그린스쿨</a></li>
        <li><a href="https://house.hyundai-motorsfc.com/">후원의집</a></li>
        <li><a href="https://hyundai-motorsfc.com/jbfcmall">초록이네</a></li>
        <li><a href="https://hyundai-motorsfc.com/ethics">인권침해 접수처</a></li>
      </nav>
    </div>
    <div class="appWrapper">
      <div class="appInner">
        <Header/>
        <div class="contenst">
          <router-view />
        </div>
        <Footer/>
      </div>
    </div>
    <div class="web_chorock">
      <img class="logo" src="@/assets/images/common/logo.png" alt="">
      <h3 class="title">일상속에서 전북현대와 함께하는 곳, 초록이네</h3>
      <p class="txt">초록이네는 전북현대모터스에프씨의 공식 MD샵으로<br />
        다양한 전북현대 관련 MD상품을 판매하는 온라인MD샵입니다.</p>
      <img class="chorock" src="@/assets/images/common/web_chorock.png" alt="">
    </div>

    <iframe id="iframe_connect" src="https://hyundai-motorsfc.com/loginBridge.html" frameborder="0" width="1px" height="1px" style="position: absolute; top: -9999px; left: -999px;"></iframe>
    <iframe id="iframe_connect_green" src="https://greenschool.hyundai-motorsfc.com/loginBridge.html" frameborder="0" width="1px" height="1px" style="position: absolute; top: -9999px; left: -999px;"></iframe>

  </div>
</template>

<script>
import Header from '@/views/Header';
import Footer from '@/views/Footer';
import http from "@/api";
import shopByhttp from "@/api/shopBy";
export default {
  name: 'App',
  components:{
    Header,
    Footer
  },
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    }
  },
  created() {

    this.$store.dispatch('getCategory')

    // 전북 홈페이지 로그인 처리
    const loginState = this.isLogin
    const _store = this.$store

    window.onmessage = function(e) {
      if ( !(e.origin === "https://hyundai-motorsfc.com" || e.origin === "https://greenschool.hyundai-motorsfc.com/") ) {
      // if ( !(e.origin === "https://dev-front.hyundai-motorsfc.com" || e.origin === "https://new-greenschool.hyundai-motorsfc.com") ) {
        return
      }

      const data = JSON.parse(e.data)
      const payload = JSON.parse(data.data);
      if (loginState || !payload || !payload.access) {
        return false
      } else {
        // 로그아웃상태, 토큰있음
        const args = { token: payload.access, agent: 'shop' };
        http.post('/user/conversion_token', args)
            .then(() => {
              // const {accessToken} = res.data.data
              // const token = accessToken.token
              const token = payload.access
              shopByhttp.get(`/oauth/openid?provider=ncpstore&openAccessToken=${token}`).then((res) => {
                let shopbyPayload = res.data
                shopbyPayload.state = true
                _store.commit('login', shopbyPayload)

                localStorage.setItem('jbhd_token', JSON.stringify(payload));
              }).catch((err) => {
                console.log(err)
              })
            })
            .catch(err => {
              console.log('ERROR', err)
            })
      }
    };

  },
  mounted() {
    window.onload = function() {
      document.getElementById( "iframe_connect" ).contentWindow.postMessage( "getAccessToken", "*" )
      document.getElementById( "iframe_connect_green" ).contentWindow.postMessage( "getAccessToken", "*" )
    }
  },
}
</script>

<style lang="scss">
  @import '@/assets/styles/constants/breakpoints.scss';
  @import '@/assets/styles/constants/typography.scss';
  @import '@/assets/styles/constants/colors.scss';
  .app {
    min-height: 800px;
    height: 100%;
    position: relative;
    background: $white;
    overflow: hidden;
    background: url(@/assets/images/main/bg_group.png) center center no-repeat;
    background-size: cover;
  }
  .web_familySite {
    display: none;
  }
  .appWrapper {
    max-width: 520px;
    height: 100%;
    margin: 0 auto;

    .appInner::-webkit-scrollbar {
      display: none;
    }

    .appInner {
      height: 100%;
      box-sizing: border-box;
      overflow: hidden auto;
    }

    &::before {
      display: none;
      }
  }
  .web_chorock {
    display: none;
    position: absolute;
    bottom: 5%;
    left: 105px;
    z-index: 1;
    img {width: 100%;}
    .logo{width: 20%;}
    .title {
      font-weight: 700;
      color: $green-03;
      padding: 5% 0 2% 0;
    }
    .txt{
      color: $green-03;
      padding-bottom: 10%;
    }
    .chorock{width: 30%;}
  }
  .contenst {
    padding-top: 70px;
    min-height: 655px;
    background-color: $white;
    .wrapper{
      overflow: hidden;
    }

  }
  @media screen and (min-width: $lgs-breakpoint){
    .web_chorock {
      display: block;
      left: 20%;
      .title{
        font-size: $font-size-28;
        line-height: $line-height-28;
        letter-spacing: $letter-spacing-28;
      }
      .txt{
        font-size: $font-size-18;
        line-height: $line-height-18;
        letter-spacing: $letter-spacing-18;
      }
      .logo{width: 40%;}
      .chorock{width: 50%;}
    }

    .web_familySite {
      display:block;
      height: 70px;
      line-height: 70px;
      padding: 0 60px;
      background-color: $green-03;
      position: relative;
      z-index: 1;
      nav {
        display: flex;
        li {
          padding-left: 20px;
          font-size: $font-size-13;
          color: $white;
          a.router-link-exact-active {
            font-weight: 700;
            border-bottom: 1px solid $white;
          }
        }
        li:first-child{
          padding-left: 0;
        }
        li:last-child{
          padding-right: 0;
        }
      }
    }

    .appWrapper {
      position: absolute;
      width: 100%;
      top: 0;
      right: 20%;
      z-index: 9;
      //&::before{
      //  content: '';
      //  display: inline-block;
      //  width: 100%;
      //  height: 100%;
      //  background: url(@/assets/images/main/bg_promotion.png) top center no-repeat;
      //  background-size: cover;
      //  position: absolute;
      //  left: -425px;
      //  top: 0;
      //  margin-top: 70px;
      //}
    }

    .contenst {
      //width: 425px;
      border-left: 1px solid $gray-DD;
      border-right: 1px solid $gray-DD;
    }
  }
  @media screen and (max-width: $lgs-breakpoint){
    .appWrapper{
      &::before{
        display: none;
      }
    }
  }
</style>
