<template>
  <div id="loadingWrap">
    <img src="@/assets/images/common/loading.gif" alt="loading"/>
  </div>
</template>

<script>
export default {
  name: "Loading-ce988a",
}
</script>

<style lang="scss" scoped>
  #loadingWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    z-index: 999;

    img {
      width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
</style>