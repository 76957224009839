import axios from "axios";
import lib from "@/lib/common";
import router from "@/router";
let platform = lib.getPlatform()

const baseURL = process.env.VUE_APP_ENVIROMENT === 'dev' ? process.env.VUE_APP_SHOP_BY_DEV_API_URL : process.env.VUE_APP_SHOP_BY_API_URL;
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "content-type": "application/json; charset=utf-8",
        "clientid": process.env.VUE_APP_SHOP_BY_CLIENT_ID,
        "platform": platform,
        "version": "1.0"
    },
    timeout: 30000,
    // withCredentials: true,
    crossDomain: true,
    credentials: "include"
});

instance.interceptors.request.use(
  (config) => {
      let accessToken = '';
      if(localStorage.getItem('token') != null) {
          try {
              accessToken = JSON.parse( localStorage.getItem( 'token' ) ).access;
          } catch (e) {
              localStorage.setItem('token', JSON.stringify({access: '', refresh: ''}));
              localStorage.removeItem('jbhd_token');
              accessToken = '';
          }
      }
      const redirectUrl = router.history.current.fullPath
      if(config.url === '/cart') {
        if(accessToken === '') {
          alert("로그인이 필요한 서비스입니다.");
          window.location.href = `/member/signin?redirectUrl=${redirectUrl}`;
          // return Promise.reject('token is null');
        }
      }
      config.headers.accesstoken = accessToken;
      return config;
    },
    (error) => {
        return Promise.reject(error)

    }
)


instance.interceptors.response.use(
    (res) => {
        return res;

    },
    (error) => {
        console.log('ERR', error);
        if(error.response.status === 401) {
            // alert("");
            // document.location.href = "https://thesports.io";
            localStorage.setItem('token', JSON.stringify({access: '', refresh: ''}));
            localStorage.removeItem('jbhd_token');
            window.location.href = "/";
        }
        return Promise.reject(error)
    }
)

export default instance;
