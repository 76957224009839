import {reactive, toRefs} from 'vue';

/**
 * @returns resNotice(Array), isLogingNotice(Boolean), errorNotice(Boolean), encodingNotice(Function)
 * @example
 * setup(){
 *   const {resNotice, isLogingNotice, errorNotice, encodingNotice} = useFatch();
 *   encodingNotice(apis.search, {query: searchText})
 *   return {resNotice, isLogingNotice, errorNotice}
 * }
 */
const useFatchNotice = () => {
  const state = reactive({
    resNotice: [],
    errorNotice: null,
    isLogingNotice: true
  });

  const encodingNotice = async (urlFun, boardInfo, options = {}) => {
    let query = '';
    if(typeof urlFun !== 'function') {
      console.warn('encoding 첫번째 인자로 넣는것은 API URL이 담긴 메소드(함수)만 넣어야 합니다.')
    }
    if(typeof options === 'object') {
      //options(object)값들을 -> Notice문자로 변경합니다.
      query = Object.entries(options).map(el => encodeURIComponent(el[0]) + '=' + encodeURIComponent(el[1])).join('&')
    } else {
      console.warn('encoding 두번째 인자로 넣는것은 object만 넣어야 합니다.')
    }

    try {
      const noticeList = await urlFun(boardInfo, query);
      const newData = noticeList.data.items.map(item => {
        return {
          articleNo: item.articleNo,
          title: item.title
        }
      });
      state.resNotice.push(...newData);
    } catch(error) {
      state.errorNotice = error;
    } finally {
      state.isLogingNotice = false;
    }
  };

  return {...toRefs(state), encodingNotice};
}

export default useFatchNotice;