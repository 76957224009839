<template>
  <div class="gnb" @click="toggleGlobalBG">
    <nav class="gnbMenu">
      <ul class="mainMenuInner">
        <li v-for="main in menuList" :key="main.name" :class="[{isOpen:main.isOpen},'mainMenu']">
          <div @click="setOpen(main.name)" class="mainMenuName">
            <div>{{main.title}}</div>
            <button :class="[{'active':!main.isOpen}, 'plus','customStyle']"></button>
            <button :class="[{'active':main.isOpen}, 'minus','customStyle']"></button>
          </div>
          <ul v-if="!isLogin" :class="[isGrid(main), 'subMenuInner']">
            <li v-for="sub in main.children" :key="sub.name" class="subMenu">
              <a v-if="sub.title !== '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">{{ sub.title }}</a>
              <a v-if="sub.title === '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">로그인</a>
            </li>
          </ul>
          <ul v-if="isLogin" :class="[isGrid(main), 'subMenuInner']">
            <li v-for="sub in main.children" :key="sub.name" class="subMenu">
              <a v-if="sub.title !== '로그인'" href="javascript:void(0)" @click="goMenu(main.name, sub)">{{ sub.title }}</a>
              <a v-if="sub.title === '로그인'" href="javascript:void(0)" @click="logout">로그아웃</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'index-87473e',
  props: ['menu'],
  computed: {
    category () {
      return this.$store.getters['category']
    },
    isLogin () {
      return this.$store.getters['isLogin']
    },
  },
  data(){
    return {
      menuList: []
    }
  },
  watch: {
    category () {
      this.init()
    },
  },
  created(){
    this.init();
  },
  methods: {
    init(){
      if (this.menu) {
        this.menuList = this.menu.map(item => {
          return {
            ...item,
            isOpen: false
          }
        });
      }
    },
    toggleGlobalBG(e) {
      if(e.target === e.currentTarget){
        this.$emit('close')
      }
    },
    setOpen(menuName) {
      const item = this.menuList.find(x => x.name === menuName)
      item.isOpen = !item.isOpen
    },
    isGrid(value){
      return value.grid === 1 ? 'grid-1' : 'grid-2'
    },
    goMenu(name, sub){
      this.setOpen(name)
      this.$emit('close')
      this.$router.push(sub.link)
    },
    logout(){
      document.getElementById( "iframe_connect" ).contentWindow.postMessage( "deleteAccessToken", "*" )
      document.getElementById( "iframe_connect_green" ).contentWindow.postMessage( "deleteAccessToken", "*" )

      this.$store.commit('logout');
      this.$emit('close')
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/constants/colors.scss';
@import '@/assets/styles/constants/typography.scss';
  .gnb {
    width: 100%;
    max-width: 520px;
    height: 100vh;
    color: $white;
    position: absolute;
    top: 70px;
    left: 100%;
    opacity: 0;
    visibility:hidden;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
  }
  .gnb.active {
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    opacity: 1;
    visibility:visible;
  }
  .gnbMenu {
    background-color: $green-03;
    cursor: pointer;
  }
  .mainMenu {
    font-size: $font-size-24;
    font-weight: 800;
    .mainMenuName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;
      button{display: none;}
      button.active{display: block;}
    }
    .subMenuInner {
      display: none;
    }
  }
  .mainMenu.isOpen{
    .subMenuInner {
      display: flex;
    }
  }
</style>